// Libs
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

// Project
import { CardCustom, BoxContent } from 'components'
import { getDataLooker, getElementsDashboard } from 'lookmls/getDataLooker'
import { lookerSDK } from 'lookmls/lookerSDK'
import { filterAlertStore, filterTimeStore } from 'stores'

// Lookml
import lookmlCardTotalAlerts from 'lookmls/alerts_clustered/card_total_alerts_page.json'
import { useLocation } from 'react-router-dom/dist'

export const AlertIndicators = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const [statusCache, setStatusCache] = useState(false)

  const dictConfig = {
    NO_SUBTYPE: 'cardHazardNoSubtype',
    HAZARD_ON_ROAD_CAR_STOPPED: 'cardHazardOnRoadCarStopped',
    HAZARD_ON_ROAD_ICE: 'cardHazardOnRoadIce',
    HAZARD_ON_ROAD_LANE_CLOSED: 'cardHazardOnRoadLaneClosed',
    HAZARD_ON_ROAD_OBJECT: 'cardHazardOnRoadObject',
    HAZARD_ON_ROAD_OIL: 'cardHazardOnRoadOil',
    HAZARD_ON_ROAD_POT_HOLE: 'cardHazardOnRoadPotHole',
    HAZARD_ON_ROAD_ROAD_KILL: 'cardHazardOnRoadRoadKill',
    HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT: 'cardHazardOnRoadTrafficLightFault',
    HAZARD_ON_ROAD: 'cardHazardOnRoad',
    HAZARD_ON_SHOULDER_ANIMALS: 'cardHazardOnShoulderAnimals',
    HAZARD_ON_SHOULDER_CAR_STOPPED: 'cardHazardOnShoulderCarStopped',
    HAZARD_ON_SHOULDER_MISSING_SIGN: 'cardHazardOnShoulderMissingSign',
    HAZARD_ON_SHOULDER: 'cardHazardOnShoulder',
    HAZARD_WEATHER_FLOOD: 'cardHazardWeatherFlood',
    HAZARD_WEATHER_FOG: 'cardHazardWeatherFog',
    HAZARD_WEATHER_FREEZING_RAIN: 'cardHazardWeatherFreezingRain',
    HAZARD_WEATHER_HAIL: 'cardHazardWeatherHail',
    HAZARD_WEATHER_HEAVY_RAIN: 'cardHazardWeatherHeavyRain',
    HAZARD_WEATHER_HEAVY_SNOW: 'cardHazardWeatherHeavySnow',
    HAZARD_WEATHER: 'cardHazardWeather',
    HAZARD_ON_ROAD_CONSTRUCTION: 'cardRoadClosedConstruction',
    HAZARD_TYPE_OF_ALERTS: 'tableTypeOfAlerts'
  }

  const [data, setData] = useState({
    cardTotalAlerts: {},
    cardsTable: {},
    tableTypeOfAlerts: {}
  })

  function getDataLookerFiltered(key, lookml) {
    if (filterTimeStore.filterName) {
      if (lookml?.filters) {
        lookml.filters['alerts_clustered.ts_date'] = filterTimeStore.filterName
      } else {
        lookml.filters = {
          'alerts_clustered.ts_date': filterTimeStore.filterName
        }
      }
    }
    setData((dt) => {
      return { ...dt, [key]: {} }
    })
    setStatusCache(true)
    getDataLooker(lookerSDK, lookml).then((card) => {
      setData((dt) => {
        return { ...dt, [key]: card }
      })
      setStatusCache(true)
    })
  }

  function validateTitle(_data, field = 'alerts_clustered.count') {
    return Object.keys(data?.cardsTable)?.length > 0
      ? _data
        ? _data[0]
          ? _data[0][field]
          : _data[field]
            ? _data[field]
            : '0'
        : '-'
      : null
  }

  const redirectToLiveAlert = (type) => {
    if (type) {
      filterAlertStore.setFilterAlert(type)
      navigate('/alerts/info-one-alert')
    }
  }

  function loadDataCards() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '308',
      filterTimeName: filterTimeStore.filterName
    }).then((list) => {
      list?.value?.forEach((result) => {
        setStatusCache(true)
        setData((dt) => {
          return {
            ...dt,
            cardsTable: {
              ...dt['cardsTable'],
              [dictConfig[result?.['alerts_clustered.subtype']]]: result
            }
          }
        })
      })
    })
  }

  useEffect(() => {
    setData({
      cardTotalAlerts: {},
      cardsTable: {},
      tableTypeOfAlerts: {}
    })
    loadDataCards()
    getDataLookerFiltered('cardTotalAlerts', lookmlCardTotalAlerts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTimeStore.filterName])

  useEffect(() => {
    if ('caches' in window && statusCache) {
      const dataHome = new Response(JSON.stringify(data))
      caches.open(location.pathname).then((cache) => {
        cache.put(location.pathname, dataHome)
      })
    }
    setStatusCache(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCache])

  useEffect(() => {
    const fetchDataFromCache = async () => {
      try {
        const response = await caches.open(location.pathname)
        const cachedData = await response.match(location.pathname)

        if (cachedData) {
          const data = await cachedData.json()
          setData(data)
        }
      } catch (error) {
        console.error('Erro ao recuperar dados do Cache Storage:', error)
      }
    }
    fetchDataFromCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BoxContent
      title="Indicadores históricos de Alertas"
      hasFilterTime
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={4}
        >
          <CardCustom
            title={validateTitle(data?.cardTotalAlerts?.value)}
            filterTime={filterTimeStore.filterName}
            fontSize="large"
            description="Total de Alertas"
            urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
          />
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={0}
          lg={6}
          xl={8}
        />
      </Grid>
      <Grid
        container
        spacing={1}
        mt={0.5}
      >
        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardNoSubtype)}
            description="Alertas não categorizados"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadCarStopped)}
            description="Veículo parado na via"
            filterTime={filterTimeStore.filterName}
            onClickCustom={() =>
              redirectToLiveAlert(
                data?.cardHazardOnRoadCarStopped?.['alerts_clustered.subtype']
              )
            }
            urlImage="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Warning_icon.svg/2306px-Warning_icon.svg.png"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadIce)}
            description="Gelo na via"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/3778/3778958.png"
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadLaneClosed)}
            description="Via fechada"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/3176/3176676.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadObject)}
            description="Objeto na via"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/765/765921.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadOil)}
            description="Óleo na via"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/938/938639.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadPotHole)}
            description="Buraco na via"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/1866/1866512.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoadRoadKill)}
            description="Atropelamento de animais"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/1576/1576488.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(
              data?.cardsTable?.cardHazardOnRoadTrafficLightFault
            )}
            description="Semáforo com defeito"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/6157/6157942.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnRoad)}
            description="Perigo na via"
            filterTime={filterTimeStore.filterName}
            urlImage="https://www.pngkit.com/png/detail/949-9496537_warning-icon-png.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnShoulderAnimals)}
            description="Animal na pista"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn1.iconfinder.com/data/icons/road-sing-flat-2/512/sign__road__yellow__animals_-512.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(
              data?.cardsTable?.cardHazardOnShoulderCarStopped
            )}
            description="Veículo parado no acostamento"
            filterTime={filterTimeStore.filterName}
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5IeotK58JfEzu6ugJKvdyQipMHvEwuVwpRwmr-FMG10CCujfgXTMXtX3OIFnM7YVv3Lw&usqp=CAU"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnShoulderMissingSign)}
            description="Acostamento sem sinalização"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/1172/1172449.png"
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardOnShoulder)}
            description="Perigo no acostamento"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardWeatherFlood)}
            description="Perigo de alagamento"
            filterTime={filterTimeStore.filterName}
            urlImage="https://cdn3.iconfinder.com/data/icons/symbol-3-1/36/231-512.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardWeatherFog)}
            description="Perigo de neblina"
            filterTime={filterTimeStore.filterName}
            urlImage="https://icon-library.com/images/fog-icon-png/fog-icon-png-23.jpg"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(
              data?.cardsTable?.cardHazardWeatherFreezingRain
            )}
            description="Chuva de granizo"
            filterTime={filterTimeStore.filterName}
            urlImage="https://w7.pngwing.com/pngs/481/557/png-transparent-weather-forecasting-computer-icons-rain-and-snow-mixed-ice-snow-white-cloud-weather-forecasting-thumbnail.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardWeatherHail)}
            description="Alerta de granizo"
            filterTime={filterTimeStore.filterName}
            urlImage="https://theorytest.org.uk/wp-content/uploads/2021/01/ice-or-snow-ahead-sign.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardWeatherHeavyRain)}
            description="Alerta de chuva forte"
            filterTime={filterTimeStore.filterName}
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrAVAJJqAPcmVExlmfnM8RhhzV7u9-fCMgSsyRu75c483CNCKBRwhS9IA_qH2Y39q10i0&usqp=CAU"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardHazardWeather)}
            description="Alerta de condições climáticas"
            filterTime={filterTimeStore.filterName}
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn2NUb7GFFTxbMb1m-ZPF3M_O1lxxZlnxtzin9gKcsGspprBz3tfbRqxLGWQjWXQljwT8&usqp=CAU"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <CardCustom
            title={validateTitle(data?.cardsTable?.cardRoadClosedConstruction)}
            description="Via fechada por obras"
            filterTime={filterTimeStore.filterName}
            urlImage="https://isinaliza.vtexassets.com/arquivos/ids/238874/3589-R.jpg?v=637709626917800000"
          />
        </Grid>
      </Grid>
    </BoxContent>
  )
})

AlertIndicators.defaultProps = {
  filterTime: null
}

AlertIndicators.propTypes = {
  filterTime: PropTypes.string,
  setFilterTime: PropTypes.func
}

// Libs
import Cookies from 'js-cookie'
import { action, observable } from 'mobx'

// Project
import { isTokenExpired } from 'utils/rest'
import { getNewToken } from 'utils/rest'

const authStore = observable({
  loading: false,
  dataSdk: null,
  _session: null,
  isExpiredTokenAuthLooker: false,
  lookerToken: null,
  refresh: null,
  signed: false,
  currentPage: null,
  lastTimeUpdate: null,
  user: null,
  googleCredential: null,
  getLastTimeUpdate: action(function () {
    const date = new Date()
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    this.lastTimeUpdate = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
  }),
  isExpiredToken: action(function (token) {
    // console.warn("Verificando se o token está expirado...", token)
    if (
      token?.['user_token']?.['expires_in'] &&
      token?.['token_last_refreshed']
    ) {
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const timeSinceLastRefresh =
        currentTimestamp - parseInt(token['token_last_refreshed'])
      // console.warn('tempo de validade do token em segundos - ', timeSinceLastRefresh)
      if (timeSinceLastRefresh < parseInt(token['user_token']['expires_in'])) {
        // Token não expirado
        // console.warn('Token não expirado!')
        return false
      }
    }
    // Token expirado ou inválido
    // console.warn('Token expirado!')
    return true
  }),
  getUserFromCookieTest: async function () {
    let session = Cookies.get('session')
    let user = Cookies.get('user')
    this.loading = true
    if (session) {
      if (isTokenExpired(session)) {
        this.session = null
        Cookies.remove('session')
        const refresh = Cookies.get('refresh')
        if (refresh) {
          if (isTokenExpired(refresh)) {
            this.refresh = null
            this.signed = false
            Cookies.remove('refresh')
          } else {
            console.warn('Atualizando token...')
            this.refresh = refresh
            await getNewToken()
          }
        }
      }
      this.user = JSON.parse(user)
      this.session = session
      this.signed = true
    } else {
      this.signed = false
    }
    this.loading = false
    return
  },
  getUserFromCookie: action(function () {
    let session = Cookies.get('session')
    let user = Cookies.get('user')
    this.loading = true
    if (session) {
      if (isTokenExpired(session)) {
        this.session = null
        Cookies.remove('session')
        const refresh = Cookies.get('refresh')
        if (refresh) {
          if (isTokenExpired(refresh)) {
            this.refresh = null
            this.signed = false
            Cookies.remove('refresh')
          } else {
            console.warn('Atualizando token...')
            this.refresh = refresh
            getNewToken()
          }
        }
      }
      this.user = JSON.parse(user)
      this.session = session
      this.signed = true
    } else {
      this.signed = false
    }
    this.loading = false
    return
  }),
  getLookerToken: async function () {
    try {
      await this.getUserFromCookieTest()
      let fetchToken = await fetch(process.env.REACT_APP_REST_API + '/auth/api/looker/token/', {
        headers: { Authorization: `Bearer ${this.session}` }
      })
      let response = await fetchToken.json()
      this.lookerToken = response
      Cookies.set("lookerToken", JSON.stringify(response), { expires: 86400 })
      return this.lookerToken
    }
    catch (error) {
      console.error('getLookerToken', error)
    }
  },
  get session() {
    if (this._session) return this._session
    this.getUserFromCookie()
    return this._session
  },
  set session(value) {
    this._session = value
  }
}, {
  getLookerToken: action,
})

export { authStore }

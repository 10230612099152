// Libs
import { observer } from 'mobx-react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

// Template
import { Loading } from '@cloudmatize/cm-core-template/layout/components_custom'
import { useMaterialUIController } from '@cloudmatize/cm-core-template/layout/context'
import theme from '@cloudmatize/cm-core-template/themes/theme'
import themeDark from '@cloudmatize/cm-core-template/themes/theme-dark'

// Project
import routes from 'routes'
import { Unprotected, Protected } from 'components'
import { Login } from 'pages'
import { authStore } from 'stores'
import { SnackbarProvider } from 'notistack'
import { Header } from 'components/Header'
import { NotAllowed } from 'pages/NotAllowed'
import { Breadcrumbs } from 'components/Breadcrumbs'
// eslint-disable-next-line
const { version: appVersion } = require('../package.json')

const App = observer(() => {
  const [controller] = useMaterialUIController()
  const { direction, darkMode } = controller

  const { pathname } = useLocation()

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.type === 'submenu') {
        return getRoutes(route?.children)
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <Protected>
                <SnackbarProvider maxSnack={3}>
                  {route.component}
                </SnackbarProvider>
              </Protected>}
            key={route.route}
          />
        )
      }

      return null
    })
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Suspense fallback={<Loading />}>
        {routes.length === 0 ? (
          <Loading />
        ) : (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {authStore.signed && (
              <>
                <Header />
                <Breadcrumbs />
              </>
            )}
            <Routes>
              {!authStore.loading && getRoutes(routes)}
              <Route
                path="/login"
                element={
                  <Unprotected>
                    <Login />
                  </Unprotected>
                }
              />
              <Route
                path="/login/:credential"
                element={
                  <Unprotected>
                    <Login />
                  </Unprotected>
                }
              />
              <Route
                exact
                key="/not-allowed"
                path="/not-allowed"
                element={
                  <Protected>
                    <NotAllowed />
                  </Protected>
                }
              />
              <Route
                path="*"
                element={<Navigate to="/not-allowed" />}
              />
            </Routes>
          </ThemeProvider>
        )}
      </Suspense>
    </GoogleOAuthProvider>
  )
})

export { App }

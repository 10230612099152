// Libs
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Grid, Card, CardContent } from '@mui/material'

// Template
import {
  MDBox,
  MDTypography,
  MDButton,
  MDInput
} from '@cloudmatize/cm-core-template/layout/components'
import {
  DriveFileRenameOutline,
  ArchitectureOutlined
} from '@mui/icons-material'

// Project
import EditMap from 'pages/maps/MapsComponents/Map/EditableMap'
import EventTable from 'pages/maps/MapsComponents/Table/ControlEventTable'
import { EventsComplete } from 'pages/maps/MapsComponents/AutoComplete/AutoComplete'

import { mapsMonitoredEventStore } from 'pages/maps/stores'
import { monitoredEventsStore } from 'pages/maps/stores/monitoredEventsStore'
import { mapsMonitoredStore } from 'pages/maps/stores'
import { useParams } from 'react-router-dom'
import { areaStore } from 'pages/maps/stores'
import { MessageSnack } from 'components/MessageSnack'
import { Loading } from '@cloudmatize/cm-core-template/layout/components_custom'
import { area, bbox, centroid, polygon } from '@turf/turf'

export const RegisterAreaEvents = observer((props) => {
  const { area_id } = useParams()

  const handlePrincipalEventChange = (value) => {
    monitoredEventsStore.events.principal_event = value
    mapsMonitoredEventStore.selected_events = value
  }

  const handleSubEventChange = (value) => {
    monitoredEventsStore.events.sub_event = value
    mapsMonitoredEventStore.selected_sub_events = value
  }

  // Event register
  const handleNameEventChange = (event) => {
    mapsMonitoredStore.maps_monitored.name = event.target.value
  }

  const handleEventRegister = async () => {
    if (mapsMonitoredStore.maps_monitored.name) {
      if (mapsMonitoredEventStore.selected_sub_events) {
        const responseSaveMap = await mapsMonitoredStore.save() // Salvando o nome do evento registrado e o mapa
        if (
          mapsMonitoredEventStore.selected_events.length > 0 &&
          responseSaveMap
        ) {
          // Salvando as informações do evento relacionados ao mapa
          // const maps_monitored = mapsMonitoredStore.id
          // mapsMonitoredEventStore.save() // Salvando os eventos selecionados
        } else if (!responseSaveMap) {
        } else {
          mapsMonitoredStore.message = {
            content: 'Selecione um alerta para o monitoramento',
            severity: 'warning'
          }
        }
      } else {
        mapsMonitoredStore.message = {
          content: 'Você precisa selecionar um Subgrupo de alertas',
          severity: 'warning'
        }
      }
    } else {
      mapsMonitoredStore.message = {
        content: 'Nome do monitoramento não pode ser vazio',
        severity: 'warning'
      }
    }

    // Recuperar as informações do nome salvo para o evento
    //mapsMonitoredStore.save() // Salvando o nome do evento registrado
    // monitoredEventsStore.events.sub_event = []
    //mapsMonitoredEventStore.save() // Salvando os eventos selecionados
  }

  // Used for getting the sub_event options for box
  const ListSubEvents = observer(() => {
    let temp = mapsMonitoredEventStore.db_sub_events.filter((sub_event) =>
      mapsMonitoredEventStore.selected_events.includes(sub_event?.event?.id)
    )
    return (
      <EventsComplete
        selectOptions={mapsMonitoredEventStore.db_sub_events.filter(
          (sub_event) =>
            mapsMonitoredEventStore.selected_events.includes(
              sub_event?.event?.id
            )
        )}
        handleChangeEvent={handleSubEventChange}
        isSubEvent={true}
        labelText={'Subgrupo de alertas'}
        placeholderText={'Selecione os alertas'}
      />
    )
  })

  // Handle with get information from the map/area
  useEffect(() => {
    monitoredEventsStore.reset()
    const getAreaInfos = async () => {
      try {
        const dataArea = await areaStore.getAreaDetails(area_id) // Getting information from area
        const { name, area, geometric_field } = dataArea
        areaStore.maps.name = name

        const areaInKm2 = area / 1000000 // 1 m² = 0.000001 km²

        // Arredondando o valor para duas casas decimais
        const roundedAreaInKm2 = Number(areaInKm2.toFixed(2))

        areaStore.maps.area = roundedAreaInKm2

        const dataLocations = JSON.parse(JSON.parse(geometric_field))
        const locations = dataLocations?.map((coord) => [
          coord.longitude,
          coord.latitude
        ])

        const points = locations?.map((coord) => [coord[1], coord[0]])

        const poly = polygon([points])

        const centerCoords = centroid(poly).geometry.coordinates
        const navBoxCoords = bbox(poly)

        monitoredEventsStore.maps_infos.centroid = centerCoords
        monitoredEventsStore.maps_infos.box_nav = navBoxCoords

        if (locations !== []) {
          areaStore.maps.geometric_field = locations
        }
      } catch (error) {
        console.error(error)
      }
    }
    getAreaInfos()
  }, [area_id])

  if (areaStore.message) {
    MessageSnack(areaStore.message)
    areaStore.message = null
  }
  if (mapsMonitoredStore.message) {
    MessageSnack(mapsMonitoredStore.message)
    mapsMonitoredStore.message = null
  }

  // Handle setting the map id for getting the map
  useEffect(() => {
    mapsMonitoredStore.maps_monitored.map = area_id
  }, [area_id])

  useEffect(() => {
    mapsMonitoredEventStore.getEvents()
    mapsMonitoredEventStore.getSubEvents()
  }, [])

  if (mapsMonitoredStore.loading) { return (<Loading />) }
  else {
    return (
      <MDBox mx={2} pb={2} mt={-3}>
        <Grid container style={{ marginTop: 50 }} spacing={1}>
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center'
            }}
          >
            <Grid
              container
              spacing={1}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}
              >
                <Card
                  sx={{
                    width: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <MDBox
                      sx={{
                        border: '1px solid black',
                        height: 60,
                        width: 60,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#2196F3'
                      }}
                    >
                      <DriveFileRenameOutline fontSize="large" color="white" />
                    </MDBox>
                    <MDBox
                      sx={{
                        width: '100%',
                        borderTop: '1px solid #ccc',
                        margin: '1px 0'
                      }}
                    />
                    <MDTypography
                      textTransform="uppercase"
                      variant="overline"
                      fontWeight="bold"
                      fontSize={12}
                    >
                      NOME DA ÁREA
                    </MDTypography>
                    {monitoredEventsStore.maps_infos.name && (
                      <>
                        <MDTypography>
                          {monitoredEventsStore.maps_infos.name}
                        </MDTypography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              {/* Size Component */}
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}
              >
                <Card sx={{ width: '100%' }}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <MDBox
                      sx={{
                        border: '1px solid black',
                        height: 60,
                        width: 60,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#2196F3'
                      }}
                    >
                      <ArchitectureOutlined fontSize="large" color="white" />
                    </MDBox>
                    <MDBox
                      sx={{
                        width: '100%',
                        borderTop: '1px solid #ccc',
                        margin: '1px 0'
                      }}
                    />
                    <MDTypography
                      textTransform="uppercase"
                      variant="overline"
                      fontWeight="bold"
                      fontSize={12}
                    >
                      TAMANHO DA ÁREA
                    </MDTypography>
                    {monitoredEventsStore.maps_infos.area && (
                      <>
                        <MDTypography>
                          {monitoredEventsStore.maps_infos.area} km²
                        </MDTypography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              {/*TEXT*/}
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}
              >
                <Card
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignSelf: 'center',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <MDTypography
                      textTransform="uppercase"
                      variant="overline"
                      fontWeight="bold"
                      fontSize={16}
                    >
                      vincular monitoramento
                    </MDTypography>
                  </CardContent>
                </Card>
              </Grid>

              {/*Input nome do alerta*/}
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}
              >
                <Card
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignSelf: 'center',
                      width: '100%'
                    }}
                  >
                    <>
                      <MDInput
                        label="Identificação do monitoramento"
                        placeholder="Digite aqui..."
                        onChange={handleNameEventChange}
                      ></MDInput>

                      <EventsComplete
                        selectOptions={
                          monitoredEventsStore.trigger_options.TRIGGER
                        }
                        defaultSelected={
                          monitoredEventsStore.trigger_options.TRIGGER
                        }
                        isSubEvent={false}
                        isRead={true}
                        labelText={'Meio de comunicação'}
                        placeholderText={'Selecione o meio...'}
                      />
                    </>
                  </CardContent>
                </Card>
              </Grid>

              {/*Eventos*/}
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}
              >
                <Card
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <CardContent>
                    <EventsComplete
                      selectOptions={mapsMonitoredEventStore.db_events}
                      handleChangeEvent={handlePrincipalEventChange}
                      isSubEvent={false}
                      labelText={'Grupo de alertas'}
                      placeholderText={'Selecione o grupo'}
                    />
                  </CardContent>
                </Card>
              </Grid>
              {/* Subtype Events container */}
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}
              >
                <Card sx={{ width: '100%', height: 226 }}>
                  <CardContent>
                    <ListSubEvents />
                    {/* {mapsMonitoredEventStore.selected_events.length > 0 ? (
                    <ListSubEvents />
                  ) : null} */}
                  </CardContent>
                </Card>
              </Grid>

              {/* Botão Adicionar */}
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {/* Button Adicionar */}
                <Card sx={{ width: '100%', height: '100%' }}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      flexGrow: 1
                    }}
                  >
                    <React.Fragment>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ height: 10, width: '50%' }}
                        onClick={handleEventRegister}
                      >
                        <MDTypography
                          textTransform="uppercase"
                          variant="overline"
                          fontWeight="bold"
                          fontSize={12}
                          color="white"
                        >
                          Adicionar monitoramento
                        </MDTypography>
                      </MDButton>
                    </React.Fragment>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{
              flexDirection: 'row'
            }}
          >
            <Grid
              container
              spacing={1}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {/* Map */}
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Card
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '5px auto'
                    }}
                  >
                    <MDBox
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        height: 635,
                        width: '100%',
                        border: '1px solid black',
                        flexDirection: 'column',
                        // margin: '5px auto',
                        position: 'relative',
                        overflow: 'hidden',
                        boxShadow:
                          '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);'
                      }}
                      id="edit-map"
                    >
                      {areaStore.maps.geometric_field && monitoredEventsStore.maps_infos.centroid && monitoredEventsStore.maps_infos.box_nav && (
                        <EditMap
                          polygonCoordinates={
                            areaStore.maps.geometric_field
                          }
                          centroid={monitoredEventsStore.maps_infos.centroid}
                          navBox={monitoredEventsStore.maps_infos.box_nav}
                          isEditable={true}
                        />
                      )}

                    </MDBox>
                  </CardContent>
                </Card>
              </Grid>
              {/*TEXT*/}
              {/* <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center'
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <CardContent
                  sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignSelf: 'center',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <MDTypography
                    textTransform="uppercase"
                    variant="overline"
                    fontWeight="bold"
                    fontSize={16}
                  >
                    vincular monitoramento
                  </MDTypography>
                </CardContent>
              </Card>
            </Grid> */}

              {/*Input nome do alerta*/}
              {/* <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center'
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <CardContent
                  sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignSelf: 'center',
                    width: '100%'
                  }}
                >
                  <>
                    <MDInput
                      label="Identificação do monitoramento"
                      placeholder="Digite aqui..."
                      onChange={handleNameEventChange}
                    ></MDInput>

                    <EventsComplete
                      selectOptions={
                        monitoredEventsStore.trigger_options.TRIGGER
                      }
                      defaultSelected={
                        monitoredEventsStore.trigger_options.TRIGGER
                      }
                      isSubEvent={false}
                      isRead={true}
                      labelText={'Meio de comunicação'}
                      placeholderText={'Selecione o meio...'}
                    />
                  </>
                </CardContent>
              </Card>
            </Grid> */}

              {/*Eventos*/}
              {/* <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center'
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <CardContent>
                  <EventsComplete
                    selectOptions={monitoredEventsStore.event_options.general}
                    handleChangeEvent={handlePrincipalEventChange}
                    isSubEvent={false}
                    labelText={'Grupo de alertas'}
                    placeholderText={'Selecione o grupo'}
                  />
                </CardContent>
              </Card>
            </Grid> */}
              {/* Subtype Events container */}
              {/* <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center'
              }}
            >
              <Card sx={{ width: '100%', height: '100%' }}>
                <CardContent>
                  {mapsMonitoredEventStore.selected_events.length > 0 ? (
                    <ListSubEvents />
                  ) : null}
                </CardContent>
              </Card>
            </Grid> */}

              {/* Botão Adicionar */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Card sx={{ width: '100%', height: '100%' }}>
              <CardContent>
                <EventTable areaId={area_id} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    )
  }
})
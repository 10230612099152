import { useContext, useEffect } from 'react'
import { Paper } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import { AuthContext } from 'contexts/auth'

// Tempalte
import { MDBox, MDButton, MDTypography } from '@cloudmatize/cm-core-template/layout/components'
import { Loading } from '@cloudmatize/cm-core-template/layout/components_custom'
import { decode } from 'html-entities';

export const Login = () => {
    const { credential } = useParams()
    const history = useNavigate()
    const { signIn, signInWithCredential } = useContext(AuthContext)
    const imageUrl = "./background.jpg"

    async function handleSubmit(googleCredentials) {
        await signIn(googleCredentials)
        history('/login')
    }

    async function handleSubmitCredential(credential) {
        await signInWithCredential(credential)
        history('/')
    }

    const goBack = () => {
        window.location.replace('https://scsbi.cloud.looker.com/')
    }


    useEffect(() => {
        if (credential) {
            handleSubmitCredential(credential)
        }
        // eslint-disable-next-line
    }, [credential])

    return credential ? (<Loading />) :
        (
            <>
                <MDBox style={{
                    background: '#000000',
                    // eslint-disable-next-line
                    background: `url("${imageUrl}") center center / cover no-repeat`, height: '100vh', backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat !important',
                    backgroundSize: 'cover',
                    width: '100vw'
                }}>
                    <Paper className="login" style={{ background: '#0a0a0a', }} elevation={3}>
                        <div style={{
                            top: '50%', left: '75%',
                            position: 'absolute',
                            minWidth: '350px',
                            maxWidth: '350px',
                            transform: 'translate(-75%, -50%)',
                            minHeight: '200px',
                            textAlign: 'center',
                            backgroundColor: '#fafafaaa',
                            padding: '1em',
                            borderRadius: '5px'
                        }}>
                            <img src="./logo.png" alt="CloudMatize" width="200" height="100" style={{ height: '100px' }} />
                            <div></div>
                            <MDTypography variant="body2" sx={{ mt: 2 }}>Acessar com o</MDTypography>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '100%', alignContent: 'center', gap: '20px', padding: '20px' }}>
                                <div style={{
                                    display: 'flex', justifyContent: 'center'
                                }}>
                                    <div></div>
                                    <MDButton onClick={goBack} fullWidth><img src="https://www.svgrepo.com/show/354012/looker-icon.svg" width={40} /> Looker</MDButton>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </MDBox>
            </>

            // <MDBox style={{
            //     background: '#000000',
            //     // eslint-disable-next-line
            //     background: `url("${imageUrl}") center center / cover no-repeat`, height: '100vh', backgroundPosition: 'center',
            //     backgroundRepeat: 'no-repeat !important',
            //     backgroundSize: 'cover',
            //     width: '100vw'
            // }}>
            //     <Paper className="login" style={{ background: '#0a0a0a', }} elevation={3}>
            //         <div style={{
            //             top: '50%', left: '75%',
            //             position: 'absolute',
            //             minWidth: '350px',
            //             maxWidth: '350px',
            //             transform: 'translate(-75%, -50%)',
            //             minHeight: '200px',
            //             textAlign: 'center',
            //             backgroundColor: '#fafafaaa',
            //             padding: '1em',
            //             borderRadius: '5px'
            //         }}>
            //             <img src="./logo.png" alt="CloudMatize" width="200" height="100" style={{ height: '100px' }} />
            //             <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '100%', alignContent: 'center', gap: '20px', padding: '20px' }}>
            //                 <div style={{
            //                     display: 'flex', justifyContent: 'center'
            //                 }}>
            //                     <div></div>
            //                     <GoogleLogin
            //                         onSuccess={credentialResponse => {
            //                             handleSubmit(credentialResponse)
            //                         }}
            //                         onError={() => {
            //                             console.error('Login Failed')
            //                         }}
            //                     />
            //                 </div>
            //             </div>
            //         </div>
            //     </Paper>
            // </MDBox>
        )
}
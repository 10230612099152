// Project
import InterceptorFetch from 'application/rest/interceptor'

const BACKEND_HOST = `${process.env.REACT_APP_REST_API}`
const accessTokenKey = 'accessToken'
const refreshTokenKey = 'refreshToken'

class Rest {
  constructor(resource) {
    this.resource = resource
    this._public = false
    this.api = 'base'
    this._headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
  }

  getPath = () => {
    if (this.detail !== undefined) {
      if (this.method !== undefined) {
        return `${this.api}/${this.resource}/${this.detail}/${this.method}/`
      } else {
        return `${this.api}/${this.resource}/${this.detail}/`
      }
    } else {
      if (this.method !== undefined) {
        return `${this.api}/${this.resource}/${this.method}/`
      } else {
        return `${this.api}/${this.resource}/`
      }
    }
  }

  set public(value) {
    this._public = value
  }

  get public() {
    return this._public
  }

  get api() {
    return `${BACKEND_HOST}/${this._api}`
  }

  set api(value) {
    this._api = value
  }

  get token() {
    return window.localStorage.getItem(accessTokenKey)
  }

  set token(newAccessToken) {
    return window.localStorage.setItem(accessTokenKey, newAccessToken)
  }

  set refreshToken(refreshToken) {
    return window.localStorage.setItem(refreshTokenKey, refreshToken)
  }

  get refreshToken() {
    return window.localStorage.getItem(refreshTokenKey)
  }

  get authorization() {
    return `Bearer ${this.token}`
  }

  set method(value) {
    this._method = value
  }

  get method() {
    return this._method
  }

  set query(value) {
    this._query = value
  }

  get query() {
    return this._query
  }

  set detail(value) {
    this._detail = value
  }

  get detail() {
    return this._detail
  }

  get headers() {
    return this._headers
  }

  set headers(value) {
    Object.keys(value).forEach((i) => {
      if (!(i === 'Authorization' && this.public)) {
        this._headers.append(i, value[i])
      }
    })
  }

  list = InterceptorFetch(() => {
    let url = new URL(this.getPath()),
      params = this.query
    if (params !== undefined) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      )
    }
    this.headers = {
      Authorization: this.authorization
    }
    const result = fetch(url, {
      method: 'GET',
      headers: this.headers
    }).catch(function (error) {
      console.error(error.message)
    })
    return { response: result, obj: this }
  })

  post = InterceptorFetch((body) => {
    this.headers = {
      Authorization: this.authorization
    }
    const result = fetch(this.getPath(), {
      method: 'POST',
      headers: this.public ? this._headers : this.headers,
      body: JSON.stringify(body)
    }).catch(function (error) {
      console.error(error.message)
    })
    return { response: result, obj: this }
  })

  putFormData = InterceptorFetch((formData) => {
    let url = new URL(this.getPath()),
      params = this.query
    if (params !== undefined) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      )
    }
    this.headers = {
      Authorization: this.authorization
    }
    this.headers.delete('Content-Type')
    const result = fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: formData
    }).catch(function (error) {
      console.error(error.message)
    })
    return { response: result, obj: this }
  })

  put = InterceptorFetch((body) => {
    let url = new URL(this.getPath()),
      params = this.query
    if (params !== undefined) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      )
    }
    this.headers = {
      Authorization: this.authorization
    }
    const result = fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(body)
    }).catch(function (error) {
      console.error(error.message)
    })
    return { response: result, obj: this }
  })

  delete = InterceptorFetch((body) => {
    this.headers = {
      Authorization: this.authorization
    }
    const result = fetch(this.getPath(), {
      method: 'DELETE',
      headers: this.headers,
      body: JSON.stringify(body)
    }).catch(function (error) {
      console.error(error.message)
    })
    return { response: result, obj: this }
  })

  get = InterceptorFetch((id) => {
    this.detail = id
    this.headers = {
      Authorization: this.authorization
    }
    const result = fetch(this.getPath(), {
      method: 'GET',
      headers: this.headers
    })
    return {
      response: result,
      obj: this
    }
  })

  refresh = async () => {
    const result = await fetch(this.getPath(), {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        refresh: this.refreshToken
      })
    })
    return { response: result, obj: this }
  }

  getLookerToken = async (token) => {
    const result = await fetch(this.getPath(), {
      method: 'GET',
      headers: {
        Authorization: token
      },
      body: JSON.stringify({
        refresh: this.refreshToken
      })
    })
    return { response: result, obj: this }
  }
}

export default Rest

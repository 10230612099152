// Libs
import * as React from 'react'
import { useState, useCallback } from 'react'
import Map, { Source, Layer } from 'react-map-gl'
import { MDBox } from '@cloudmatize/cm-core-template/layout/components'
import 'mapbox-gl/dist/mapbox-gl.css'
import DrawControl from './draw-control.ts'

export default function EditMap({
  polygonCoordinates,
  centroid,
  navBox,
  isEditable
}) {
  const [, setFeatures] = useState({})

  // Map Draw
  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        newFeatures[f.id] = f
      }
      return newFeatures
    })
  }, [])

  const onDelete = useCallback((e) => {
    // Cleaning the geometric field and area of the map
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        delete newFeatures[f.id]
      }
      return newFeatures
    })
  }, [])

  // const valores = polygonCoordinates

  // console.log(valores)
  // const extractedCoordinates = valores?.map((proxyCoordinates) => {
  //   return proxyCoordinates.map((coordinate) => coordinate)
  // })
  // console.log('Coordinates: ')
  // console.log(extractedCoordinates)

  return (
    <>
      {isEditable ? (
        <>
          <MDBox
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              overflow: 'hidden'
            }}
          >
            <Map
              //id="map-container"
              mapboxAccessToken={process.env.REACT_APP_TOKEN_MAP_BOX}
              initialViewState={{
                longitude: centroid[1],
                latitude: centroid[0],
                // longitude: -46.5489,
                // latitude: -23.6226,
                zoom: 14,
                minZoom: 14,
                maxZoom: 14,
                maxFitBounds: [navBox[0], navBox[1], navBox[2], navBox[3]]
              }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              style={{ width: '100%', height: '100%' }}
            // onClick={handleMapClick}
            >
              <Source
                type="geojson"
                data={{
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [polygonCoordinates]
                  }
                }}
              >
                <Layer
                  id="polygon"
                  type="fill"
                  paint={{
                    'fill-color': '#088',
                    'fill-opacity': 0.5
                  }}
                />
              </Source>

              {/* <DrawControl
                position="top-left"
                controls={{
                  polygon: false,
                  trash: false,
                  line_string: false,
                  point: false,
                  combine_features: false,
                  uncombine_features: false
                }}
                onCreate={onUpdate}
                onUpdate={onUpdate}
                onDelete={onDelete}
                //initialPolygon={extractedCoordinates}
              /> */}
            </Map>
          </MDBox>
        </>
      ) : (
        <>
          <MDBox
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              overflow: 'hidden'
            }}
          >
            <Map
              //id="map-container"
              mapboxAccessToken={process.env.REACT_APP_TOKEN_MAP_BOX}
              initialViewState={{
                longitude: centroid[1],
                latitude: centroid[0],
                zoom: 14,
                minZoom: 14,
                maxZoom: 14,
                maxFitBounds: [navBox[0], navBox[1], navBox[2], navBox[3]]
              }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              style={{ width: '100%', height: '100%' }}
            >
              <Source
                type="geojson"
                data={{
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [polygonCoordinates]
                  }
                }}
              >
                <Layer
                  id="polygon"
                  type="fill"
                  paint={{
                    'fill-color': '#088',
                    'fill-opacity': 0.5
                  }}
                />
              </Source>
            </Map>
          </MDBox>
        </>
      )}
    </>
  )
}

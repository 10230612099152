// Libs
import React from 'react'
import PropTypes from 'prop-types'
import { Card, Skeleton } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

// Template
import { MDBox, MDTypography } from '@cloudmatize/cm-core-template/layout/components'

// Project
import { LiveBox } from 'components/LiveBox'

export const CardCustom = observer(({
  title,
  description,
  labelProgressLeft,
  labelProgressRight,
  filterTime,
  icon,
  urlImage,
  type,
  labelTime,
  fontSize,
  redirectUrl,
  redirectComponent,
  onClickCustom,
  live
}) => {
  let navigate = null
  if (redirectComponent) {
    navigate = useNavigate()
  }
  const defaultFontSize = {
    small: {
      title: '1.5rem',
      description: '0.7rem'
    },
    medium: {
      title: '1.8rem',
      description: '1rem'
    },
    large: {
      title: '2.4rem',
      description: '1.2rem'
    }
  }

  const filterTimeLabel = {
    today: 'Hoje',
    yesterday: 'Ontem'
  }

  return (
    <LiveBox live={!!title && live}>
      <Card
        onClick={() => (onClickCustom && onClickCustom()) ||
          (redirectComponent && navigate(redirectComponent)) ||
          (redirectUrl && window.location.replace(redirectUrl))
        }
        sx={{
          minHeight: { xs: '90px', sm: '100px', md: '100px', lg: '110px', xl: '110px' },
          border: '1px solid #efefef',
          borderRadius: '10px',
          width: '100%',
          boxShadow:
            '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);',
          animation: 'circle 2s linear infinite',
          cursor: (onClickCustom || redirectComponent || redirectUrl) && 'pointer',
        }}
      >
        {title !== 0 && !title
          ? (
            <MDBox>
              <MDBox display="flex" justifyContent="center">
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{
                    height: 36,
                    width: '40%',
                    mt: 3,
                  }} />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{
                    height: 18,
                    width: '70%',
                    mt: 1.2
                  }} />
              </MDBox>
            </MDBox>
          )
          : (
            <MDBox>
              {(labelTime || filterTime === 'today' || filterTime === 'yesterday') && (
                <MDBox
                  display="flex"
                  justifyContent="end"
                >
                  <MDTypography
                    variant="h6"
                    fontSize="1rem"
                    color="success"
                    textAlign="right"
                    sx={{ px: 3, mb: filterTime && -3, backgroundColor: '#f0fff0', borderTopRightRadius: "10px" }}
                  >
                    {labelTime || filterTimeLabel[filterTime]}
                  </MDTypography>
                </MDBox>
              )}
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={labelTime ? -1.5 : 1.5}
              >
                <MDBox width="90%">
                  <MDBox display="flex" justifyContent={labelProgressLeft || labelProgressRight ? "space-between" : "center"} alignItems="end">
                    {labelProgressLeft && labelProgressRight ? labelProgressLeft : null}
                    {
                      ((labelProgressLeft && labelProgressRight) || (!labelProgressLeft && !labelProgressRight)) &&
                      <MDTypography
                        variant="h4"
                        fontSize={defaultFontSize[fontSize].title}
                        align="center"
                        color={type}
                        my={0.5}
                        mx={1}
                      >
                        {title}
                      </MDTypography>
                    }
                    {labelProgressLeft && labelProgressRight ? labelProgressRight : null}
                  </MDBox>
                  {
                    description && <MDTypography
                      variant="body1"
                      fontSize={defaultFontSize[fontSize].description}
                      align="center"
                      color={type}
                    >
                      {description}
                    </MDTypography>
                  }
                </MDBox>
                {icon && (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    ml={2}
                  >
                    {icon}
                  </MDBox>
                )}
                {urlImage && (
                  <MDBox
                    position="absolute"
                    display="flex"
                    width="100%"
                    justifyContent="right"
                    alignItems="center"
                    px={2}
                    sx={{ opacity: 0.25 }}
                  >
                    <img
                      src={urlImage}
                      alt="logo"
                      style={{ maxHeight: 35, maxWidth: 35 }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          )}
      </Card >
    </LiveBox>
  )
})

CardCustom.defaultProps = {
  title: null,
  description: null,
  labelProgressLeft: null,
  labelProgressRight: null,
  filterTime: null,
  icon: null,
  urlImage: null,
  type: 'dark',
  labelTime: null,
  fontSize: 'medium',
  redirectUrl: null,
  redirectComponent: null,
  live: false
}

CardCustom.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  labelProgressLeft: PropTypes.node,
  labelProgressRight: PropTypes.node,
  filterTime: PropTypes.string,
  icon: PropTypes.element,
  urlImage: PropTypes.string,
  type: PropTypes.string,
  labelTime: PropTypes.string,
  fontSize: PropTypes.string,
  redirectUrl: PropTypes.string,
  redirectComponent: PropTypes.string,
  onClickCustom: PropTypes.func,
  live: PropTypes.bool
}

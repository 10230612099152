// import { userStore } from 'stores/user'
import Rest from './rest'

const callRequest = async (fn, args) => {
  try {
    const value = fn(...args)
    const response = await value.response
    // Clona a resposta para evitar chamar o método json da response,
    // que só pode ser chamado uma única vez
    const responseCopy = response.clone()
    const json = await responseCopy.json()
    return { response, json, rest: value.obj }
  } catch (e) {
    console.error('Falha no recurso remoto, tente novamente!', e)
    throw e
  }
}

const refreshToken = async () => {
  const rest = new Rest('token')
  rest.api = 'auth/api'
  rest.method = 'refresh'

  const { response } = await rest.refresh()
  if (response.ok) {
    const json = await response.json()
    rest.token = json.access
  }
  return response.ok
}

const InterceptorFetch =
  (f) =>
    async (...args) => {
      let { response, json, rest } = await callRequest(f, args)
      // Trata o caso do token de acesso ter expirado
      // Pega um novo token de acesso através do token refresh
      // Refaz a requisição

      if (!response.ok && response.status === 401) {
        const { code = null } = json
        if (code === 'token_not_valid') {
          const successRefresh = await refreshToken()
          if (successRefresh) {
            // remove antiga authorization
            rest.headers.delete('Authorization')(
              ({ response, json } = await callRequest(f, args))
            )
          }
        }
      }

      if (response.ok) {
        if (window.location.pathname !== '/') {
          //userStore.remove_401()
          //userStore.remove_403()
        }

        if (json.hasOwnProperty('detail')) {
          console.log(json.detail)
        }
        return response
      } else {
        if (response.status === 401 || response.status === 403) {
          if (response.status === 401) {
            // const message = 'Você não tem permissão para acessar o sistema!'
            //userStore.set_401(message)
            //userStore.doLogout()
          }
          if (response.status === 403) {
            // const message = 'Você não tem permissão para realizar essa ação!'
            //userStore.set_403(message)
            window.location.replace('/not-allowed')
          }
        } else {
          //userStore.remove_401()
          //userStore.remove_403()

          if (json.hasOwnProperty('detail')) {
            console.log(json.detail)
          }
        }
        throw json
      }
    }

export default InterceptorFetch

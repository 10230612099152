// Libs
import React from 'react'
import { observer } from 'mobx-react'

// Project
import { BoxContent } from 'components'

export const WeatherToday = observer(() => {
    return (
        <BoxContent hasIframe>
            <iframe
                width="100%"
                title='Alerta de Alagamentos'
                src={`https://scsbi.cloud.looker.com/embed/dashboards/30?allow_login_screen=true`}
                frameBorder="0"
                allowFullScreen
                style={{ height: '100ch' }}
            />
        </BoxContent>
    )
})

// Libs
import React from 'react'
import { observer } from 'mobx-react'

// Template
import { MDBox, MDTypography } from '@cloudmatize/cm-core-template/layout/components'

export const IframeCustom = observer(({
  title,
  src,
  style,
  width = '100%',
  height = '700px',
  color = 'primary',
  sx = {}
}) => {
  return (
    <MDBox sx={{ backgroundColor: '#ffffff', borderRadius: '10px', ...sx }}>
      {title && (
        <MDTypography
          variant="h5"
          color={color}
          textAlign="center"
          py={1}
        >
          {title}
        </MDTypography>
      )}
      <iframe
        title='iframe_custom'
        width={width}
        height={height}
        src={src}
        frameBorder="0"
        allowFullScreen
        style={{ ...style }}
      />
    </MDBox>
  )
})

import { Box, Icon, ListItemButton, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom/dist'

export function MenuItem({ text, icon, link, toggleMenu }) {
  const navigate = useNavigate()
  const location = useLocation()

  const activeButtonStyle = {
    bgcolor: '#E0E7F0',
    color: '#7B809A',
    borderRadius: '5px'
  }

  const buttonStyle = {
    color: '#7B809A',
    borderRadius: '5px'
  }

  function navigateRoute() {
    navigate(link)
    toggleMenu()
  }

  return (
    <ListItemButton
      sx={location.pathname === link ? activeButtonStyle : buttonStyle}
      onClick={() => navigateRoute()}
    >
      <Box
        display="flex"
        alignItems="center"
        gap="2rem"
      >
        <Icon sx={{ color: '#7B809A' }}>{icon}</Icon>
        <Typography
          fontWeight="bold"
          color="#7B809A"
          fontSize="1.1rem"
        >
          {text}
        </Typography>
      </Box>
    </ListItemButton>
  )
}

// Libs
import React from 'react'

// Project
import { BoxContent } from 'components'

export const InfractionsAndFines = () => {
  return (
    <BoxContent hasIframe>
      <iframe
        width="100%"
        title='Infrações e Multas'
        src="https://scsbi.cloud.looker.com/embed/dashboards/14?allow_login_screen=true"
        frameBorder="0"
        allowFullScreen
        style={{ height: '80ch' }}
      />
    </BoxContent>
  )
}

// Libs
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Icons
import EditIcon from '@mui/icons-material/Edit'
import AddRoadIcon from '@mui/icons-material/AddRoad';
// Template
import { DataTable } from '@cloudmatize/cm-core-template/layout/demos'
import {
  MDBox,
  MDButton
} from '@cloudmatize/cm-core-template/layout/components'

// Project
import { areaStore } from 'pages/maps/stores'
import { MessageSnack } from 'components/MessageSnack'

const Tabela = observer((props) => {
  const navigate = useNavigate() // Navegar entre páginas
  // Funções para a pesquisa de áreas da tabela

  useEffect(() => {
    areaStore.reload()
  }, [])

  // Function related with click on edit area
  const handleAreaEdictRedirect = (areaId) => {
    if (areaId) navigate(`/monitored-areas/editor-areas/${areaId}`)
  }

  const handleRouteEdictRedirect = (areaId) => {
    if (areaId) navigate(`/monitored-areas/register-events/${areaId}`)
  }


  const handleAreaRegisterRedirect = () => {
    navigate(`/monitored-areas/register-area`)
  }

  if (areaStore.message) {
    MessageSnack(areaStore.message)
    areaStore.message = null
  }

  return (
    <MDBox mt={-8}>
      <MDBox display="flex" justifyContent="flex-end">
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            handleAreaRegisterRedirect()
          }}
          sx={{ mb: 4, minWidth: 250 }}
        >
          Adicionar área
        </MDButton>
      </MDBox>
      <DataTable
        dictPag={areaStore.list}
        funcReload={areaStore.reload}
        table={{
          columns: [
            {
              Header: 'Identificação da área',
              accessor: 'name',
              width: '70%'
            },
            {
              Header: 'Área(m²)',
              accessor: 'area',
              width: '20%'
            },
            { Header: 'Ações', accessor: 'action', width: '10%' }
          ],
          rows: areaStore.list?.results?.map((areaInt, index) => {
            return {
              key: index,
              name: areaInt.name,
              area: areaInt.area?.toFixed(2),
              action: (
                <MDBox sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  <MDBox key={index}>
                    <Tooltip title="Editar área">
                      <IconButton
                        onClick={() => {
                          handleAreaEdictRedirect(areaInt.id)
                        }}
                      >
                        <EditIcon
                          fontSize="small"
                          sx={{ opacity: 0.75 }}
                          color="action"
                        />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <MDBox key={index + 1}>
                    <Tooltip title="Editar eventos">
                      <IconButton
                        onClick={() => {
                          handleRouteEdictRedirect(areaInt.id)
                        }}
                      >
                        <AddRoadIcon
                          fontSize="small"
                          sx={{ opacity: 0.75 }}
                          color="info"
                        />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              )
            }
          })
        }}
      />
    </MDBox>
  )
})

export default Tabela

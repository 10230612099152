// Libs
import { useEffect } from "react"
import { observer } from "mobx-react"

// Project
import { BoxContent } from "components/BoxContent"
import { MDBox } from "@cloudmatize/cm-core-template/layout/components"

export const WazePainel = observer(() => {
    function funcToExecuteInSomeSeconds() {
        const iframe = document.getElementById('iframe-waze-painel')
        // eslint-disable-next-line no-self-assign
        iframe.src = iframe?.src
    }

    useEffect(() => {
        funcToExecuteInSomeSeconds()
        const interval = setInterval(() => {
            funcToExecuteInSomeSeconds()
        }, 60000) // 60 segundos
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <BoxContent hasIframe>
            <MDBox display="flex" justifyContent="center">
                <iframe
                    id="iframe-waze-painel"
                    title='Painel de monitoramento - Waze'
                    src="https://embed.waze.com/pt-BR/iframe?zoom=15&lat=-23.6275&lon=-46.5675&ct=livemap"
                    width="95%"
                    style={{ height: '75ch', borderRadius: '10px' }}
                    frameBorder="0"
                    allowfullscreen />
            </MDBox>
        </BoxContent>
    )
})
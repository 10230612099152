import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography
} from '@mui/material'
import { MenuItem } from 'components/DrawerMenu/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom/dist'
import { useState } from 'react'

export function SubMenuItem({ subMenuData, icon, text, toggleMenu }) {
  const location = useLocation()
  const isExpanded = subMenuData.some(
    (menuItem) => menuItem.route === location.pathname
  )
  const [expanded, setExpanded] = useState(isExpanded)

  return (
    <Accordion
      sx={{
        '&:before': {
          display: 'none'
        },
        boxShadow: 'none'
      }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap="2rem"
        >
          <Icon sx={{ color: '#7B809A' }}>{icon}</Icon>
          <Typography
            fontWeight="bold"
            color="#7B809A"
            fontSize="1.1rem"
          >
            {text}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
        >
          {subMenuData.map((menuItem) => {
            if (menuItem.type === 'collapse') {
              return (
                <MenuItem
                  key={menuItem.route}
                  text={menuItem.label}
                  icon={menuItem.icon}
                  link={menuItem.route}
                  toggleMenu={toggleMenu}
                />
              )
            }
            return <></>
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

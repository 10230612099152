// Libs
import React, { useContext, useEffect, useState } from 'react'
import { CardMedia, Grid, Icon, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

// Images
import homeImage from 'assets/images/home.jpg'
import imgLogoSCS from 'assets/images/logo.png'

// Template
import { MDBox, MDButton, MDTypography } from '@cloudmatize/cm-core-template/layout/components'

// Project
import { AuthContext } from 'contexts/auth'
import UserMenu from 'components/UserMenu'
import MenuIcon from '@mui/icons-material/Menu'
import routes from 'routes'

export const MenuCustom = observer(() => {
  const navigate = useNavigate()
  const { user, signOut } = useContext(AuthContext)
  const [menu, setMenu] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)

  const [currentTab, setCurrentTab] = useState(0)
  const [currentSubTab, setCurrentSubTab] = useState(0)
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const [textBreadcrumb, setTextBreadcrumb] = useState(null)

  const [anchorEl, setAnchorEl] = useState(routes.map((_) => null))
  const open = routes.map((_, idx) => Boolean(anchorEl?.[idx]))
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const handleClickMenu = (event, index) => {
    setAnchorEl(anchorEl.map((route, idx) => idx === index && event.currentTarget))
  }
  const handleCloseMenu = () => {
    setAnchorEl(anchorEl.map(() => null))
  }
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  useEffect(() => {
    if (screenSize.width >= 600) {
      setMobileMenu(false)
    }
  }, [screenSize])

  const showMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  useEffect(() => {
    const currentPath = window.location.pathname;
    const pathWithoutUUID = regexExp.test(currentPath.split('/')[currentPath.split('/').length - 1])
      ? currentPath.replace(/\/[a-f\d-]{36}$/, '')
      : currentPath;

    const currentRoute = routes.find(route => route?.route === pathWithoutUUID);
    let textBreadcrumb = currentRoute?.label || '';

    const subRoute = routes
      .map(route => route?.children)
      .flat()
      .find(subroute => subroute?.route === pathWithoutUUID);
    if (subRoute) {
      textBreadcrumb += ` / ${subRoute.label}`;
    }

    setTextBreadcrumb(textBreadcrumb);

    routes.forEach((route, index) => {
      if (['collapse', 'submenu'].includes(route?.type)) {
        const isCurrentTab = pathWithoutUUID.includes(route?.route);
        const subRouteIndex = route.children?.findIndex(subroute => subroute.route === pathWithoutUUID);
        if (isCurrentTab || subRouteIndex !== -1) {
          setCurrentTab(index);
          if (subRouteIndex !== -1) {
            setCurrentSubTab(subRouteIndex);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  return (
    <MDBox sx={{
      background: '#f6f8ff'
    }}>
      {screenSize.width >= 600 &&
        <UserMenu user={user} menu={menu} setMenu={setMenu} signOut={signOut} />
      }
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="150px"
        mb={1}
      >
        <MDBox sx={{
          position: "absolute",
          left: 0,
          marginLeft: 6,
          backgroundColor: "#e2e2e4b9",
          borderRadius: "10px",
          zIndex: 9,
          mt: screenSize.width < 600 && mobileMenu ? 8 : 1.5,
          pb: 0
        }}>
          <CardMedia
            component="img"
            image={imgLogoSCS}
            alt="SCS"
            sx={{
              height: screenSize.width >= 600 ? "85px" : "50px",
              width: screenSize.width >= 600 ? "200px" : "140px",
            }}
          />
        </MDBox>
        <CardMedia
          component="img"
          height="150px"
          image={homeImage}
          sx={{
            borderRadius: '10px',
            position: 'absolute',
            width: '99.5%',
            mt: 1,
            zIndex: 0
          }}
        />
        {
          routes[currentTab]?.route !== '/' && textBreadcrumb &&
          <MDBox
            sx={{
              position: 'absolute',
              display: 'flex',
              width: '90%',
              justifyContent: 'space-between',
              alignItems: 'center',
              left: 0,
              marginLeft: 6,
              top: "135px",
            }}>
            <MDBox sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
              border: "1px solid #ffffff",
              borderRadius: "8px",
              minWidth: "252px"
            }}>
              <MDTypography
                variant="body1"
                color="white"
                fontSize="1rem"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center", px: 3 }}
              >
                <IconButton
                  size="small"
                  color="white"
                  onClick={() => {
                    setCurrentTab(0)
                    navigate("/")
                  }}>
                  <Icon fontSize={'small'} sx={{ mx: 0.5 }}>{routes.filter((_route) => _route.route === "/")?.[0]?.icon}</Icon>
                </IconButton>
                {textBreadcrumb}
              </MDTypography>
            </MDBox>
            <MDBox sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #ffffff",
              backgroundImage: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
              borderRadius: "8px",
              minWidth: "50px",
              minHeight: "28px",
              color: "#ffffff",
            }}>
              <IconButton
                size="small"
                color="white"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <Icon fontSize="small" color="white">arrow_back</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        }
        {screenSize.width >= 600 ? (
          <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "start", height: "180px" }} mt={5} >
            <Grid container sx={{ backgroundColor: "#ffffff", borderRadius: "10px", zIndex: 10 }}>
              {
                routes.map((route, index) => (
                  ['collapse', 'submenu'].includes(route?.type) &&
                  <Grid item key={"grid-item-menu-" + index}>
                    <Tooltip title={screenSize.width < 1024 ? route?.label : null} placement="left-end">
                      <MDBox key={"menu-" + index} display="flex" alignItems="center" >
                        <MDButton
                          key={index}
                          variant="gradient"
                          color={index === currentTab ? "info" : "white"}
                          onClick={(e) => {
                            !route?.children && setCurrentTab(index)
                            !route?.children && navigate(route?.route)
                            handleClickMenu(e, index)
                          }}
                          sx={{
                            cursor: 'pointer',
                            borderRadius: "10px",
                          }}
                        >
                          <Icon fontSize={screenSize.width >= 1024 ? 'large' : 'medium'} sx={{ mx: 0.5 }}>{route?.icon}</Icon>
                          {screenSize.width >= 1024 ? route?.label : null}
                        </MDButton>
                        {route?.children && (
                          <Menu
                            anchorEl={anchorEl?.[index]}
                            open={open?.[index]}
                            onClose={(e) => handleCloseMenu(e, index)}
                            onMouseDown={(e) => handleCloseMenu(e, index)}
                          >
                            {
                              route?.children?.map((subroute, subindex) => (
                                subroute?.type === 'collapse' &&
                                <MDBox key={"mdbox-submenu-" + index + subindex} display="flex" alignItems="center">
                                  <MenuItem
                                    key={"submenu-" + index + subindex}
                                    onClick={(e) => {
                                      handleCloseMenu(e, index)
                                      setCurrentTab(index)
                                      setCurrentSubTab(subindex)
                                      navigate(subroute?.route)
                                    }}
                                    sx={{
                                      cursor: 'pointer',
                                      backgroundColor: index === currentTab && subindex === currentSubTab && "#689dff24",
                                      width: "100%"
                                    }}
                                  >
                                    <Icon fontSize='small' sx={{ mr: 1 }}>{subroute?.icon}</Icon>
                                    {subroute?.label}
                                  </MenuItem>
                                </MDBox>
                              ))}

                          </Menu >
                        )}
                      </MDBox>
                    </Tooltip>
                  </Grid>
                ))
              }
            </Grid>
          </MDBox>
        ) : (
          <MDBox style={{
            position: 'absolute',
            background: '#fafafa',
            zIndex: '2',
            /* width: 20px; */
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '5px',
            top: '2.5%',
            borderRadius: '50%',
            right: '2.5%'
          }}>
            <MenuIcon onClick={showMobileMenu} />
          </MDBox>
        )}
        {mobileMenu ? (
          <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "start", height: "180px" }} >
            <Grid container sx={{ backgroundColor: "#ffffff", borderRadius: "10px", zIndex: 10, border: '4px solid #fafafa' }}>
              {
                routes.map((route, index) => (
                  ['collapse', 'submenu'].includes(route?.type) &&
                  <Grid item key={"grid-item-menu-" + index}>
                    <Tooltip title={screenSize.width < 1024 ? route?.label : null} placement="left-end">
                      <MDBox key={"menu-" + index} display="flex" alignItems="center" >
                        <MDButton
                          key={index}
                          variant="gradient"
                          color={index === currentTab ? "info" : "white"}
                          // onMouseOver={(e) => handleClickMenu(e, index)}
                          onClick={(e) => {
                            !route?.children && setCurrentTab(index)
                            !route?.children && navigate(route?.route)
                            handleClickMenu(e, index)
                          }}
                          sx={{
                            cursor: 'pointer',
                            borderRadius: "10px",
                          }}
                        >
                          <Icon fontSize='large'>{route?.icon}</Icon>
                          {screenSize.width >= 1024 ? route?.label : null}
                        </MDButton>
                        {route?.children && (
                          <Menu
                            anchorEl={anchorEl?.[index]}
                            open={open?.[index]}
                            onClose={(e) => handleCloseMenu(e, index)}
                            onMouseDown={(e) => handleCloseMenu(e, index)}
                          >
                            {
                              route?.children?.map((subroute, subindex) => (
                                subroute?.type === 'collapse' &&
                                <MDBox key={"mdbox-submenu-" + index + subindex} display="flex" alignItems="center">
                                  <MenuItem
                                    key={"submenu-" + index + subindex}
                                    onClick={(e) => {
                                      handleCloseMenu(e, index)
                                      setCurrentTab(index)
                                      setCurrentSubTab(subindex)
                                      navigate(subroute?.route)
                                    }}
                                    sx={{
                                      cursor: 'pointer',
                                      backgroundColor: index === currentTab && subindex === currentSubTab && "#689dff24",
                                      width: "100%"
                                    }}
                                  >
                                    <Icon fontSize='small' sx={{ mr: 1 }}>{subroute?.icon}</Icon>
                                    {subroute?.label}
                                  </MenuItem>
                                </MDBox>
                              ))}

                          </Menu >
                        )}
                      </MDBox>
                    </Tooltip>
                  </Grid>
                ))
              }
              <Grid item >
                <Tooltip title="Logout" placement="left-end">
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => signOut()}
                    sx={{
                      borderRadius: "10px",
                      opacity: 0.6
                    }}
                    fullWidth
                  >
                    <Icon fontSize='small' >logout</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Fechar menu" placement="left-end">
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={(e) => setMobileMenu(false)}
                    fullWidth
                    sx={{
                      borderRadius: "10px",
                      opacity: 0.6
                    }}
                  >
                    <Icon fontSize='small'>close</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            </Grid>
          </MDBox>
        ) : null}
      </MDBox>
    </MDBox >
  )
})

// Libs
import { Grid } from "@mui/material"
import { observer } from "mobx-react"

// Icons
import LogoutIcon from '@mui/icons-material/Logout';

// Template
import { MDBox, MDButton, MDTypography } from "@cloudmatize/cm-core-template/layout/components"
import { authStore } from "stores"

const UserMenu = observer(({ user, menu, setMenu, signOut }) => {
    const showMenu = () => {
        setMenu(!menu)
    }
    return (
        <MDBox>
            {authStore?.user?.picture && (
                <img src={authStore?.user?.picture} alt={authStore.user?.email} style={{
                    borderRadius: '50%',
                    position: 'absolute',
                    float: 'right',
                    top: '2%',
                    width: '50px',
                    right: '2%',
                    padding: '1px',
                    border: '3px solid #ffffff',
                    zIndex: '1'
                }} onClick={showMenu} />)
            }
            {
                menu && (
                    <Grid container style={{
                        position: 'absolute',
                        float: 'right',
                        top: '8%',
                        right: '2%',
                        padding: '1px',
                        overflow: 'hidden',
                        width: '300px',
                        border: '3px solid #ffffff',
                        background: '#F9F6EE',
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.3em',
                        zIndex: '1'
                    }}>
                        <Grid item>
                            <MDTypography variant="body2" textAlign="center" sx={{ color: `rgb(123, 128, 154)`, fontWeight: '500' }}>{authStore.user.name}</MDTypography>
                            <MDTypography variant="body2" textAlign="center" sx={{ fontSize: '14px', color: '#bebebe' }}>{authStore.user.email}</MDTypography>
                        </Grid>
                        <Grid item>
                            <MDBox width="50%" margin="0 auto">
                                <MDButton
                                    variant="outlined"
                                    color="warning"
                                    fullWidth
                                    onClick={signOut}
                                >
                                    <LogoutIcon sx={{ mr: 1 }} />
                                    Sair
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                )
            }
        </MDBox>
    )
})

export default UserMenu
// Libs
import React from 'react'
import { observer } from 'mobx-react'

// Project
import { BoxContent } from 'components'
import { filterTimeStore } from 'stores/filters'

export const AlertHoles = observer(() => {
  return (
    <BoxContent hasIframe hasFilterTime>
      <iframe
        width="100%"
        title='Alerta de Buracos'
        src={`https://scsbi.cloud.looker.com/embed/dashboards/19?Período=${filterTimeStore.filterName}&allow_login_screen=true`}
        frameBorder="0"
        allowFullScreen
        style={{ height: '100ch' }}
      />
    </BoxContent>
  )
})

// Libs
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

// Project
import { wazeStore } from 'stores/filters'

export const WazePlotStreet = observer((streetName) => {
  const navigate = useNavigate()

  function funcToExecuteInSomeSeconds() {
    const iframe = document.getElementById('iframe-waze-painel')
    // eslint-disable-next-line no-self-assign
    iframe.src = iframe?.src
  }

  useEffect(() => {
    funcToExecuteInSomeSeconds()
    const interval = setInterval(() => {
      funcToExecuteInSomeSeconds()
    }, 60000) // 60 segundos
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!streetName.street_name) navigate('/waze-painel')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    wazeStore.getLocationWaze(
      streetName.street_name + ' ' + process.env.REACT_APP_CITY_LOCATION
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetName.street_name])

  return (
    <>
      <iframe
        id="iframe-waze-painel"
        title="Painel de monitoramento - Waze"
        src={`https://embed.waze.com/pt-BR/iframe?zoom=18&lat=${
          wazeStore?.location?.[0]?.location?.lat ||
          process.env.REACT_APP_CITY_LOCATION_LAT
        }&lon=${
          wazeStore?.location?.[0]?.location?.lon ||
          process.env.REACT_APP_CITY_LOCATION_LON
        }&pin=1`}
        style={{ height: '100%', borderRadius: '10px', width: '100%' }}
        frameBorder="0"
        allowfullscreen
      />
    </>
  )
})

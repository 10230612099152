import { useSnackbar } from "notistack";

export const MessageSnack = (message) => {
    const { enqueueSnackbar } = useSnackbar();
    var variant = message.length > 1 && message[1] !== undefined ? message[1] : null;
    if (typeof message === "string") {
        enqueueSnackbar(message, {
            variant: variant,
            autoHideDuration: 7000
        });
    } else {
        if (variant) {
            enqueueSnackbar(message.content, {
                variant: variant,
                autoHideDuration: 7000
            });
        } else {
            var _variant = message.severity;
            enqueueSnackbar(message.content, {
                variant: _variant,
                autoHideDuration: 7000
            });
        }
    }
}
import { Looker40SDK } from '@looker/sdk'
import { AuthToken, AuthSession, BrowserTransport, DefaultSettings } from '@looker/sdk-rtl'
import { authStore } from 'stores'

class SDKSession extends AuthSession {
  // This is a placeholder for the fetchToken function.
  // It is modified to make it useful later.
  activeToken = new AuthToken()
  tokenLastRefreshed = 0
  isRequestingToken = false

  constructor(settings, transport) {
    super(settings, transport || new BrowserTransport(settings))
  }

  async fetchToken() {
    this.isRequestingToken = true
    let lookerToken = await authStore.getLookerToken()
    this.isRequestingToken = false
    return lookerToken
  }

  isExpiredToken(token) {
    if (token?.['expires_in']) {
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const timeSinceLastRefresh = currentTimestamp - this.tokenLastRefreshed
      if (timeSinceLastRefresh < token['expires_in'] * 0.10) {
        // Token não expirado
        // console.warn('Token não expirado!')
        return false
      }
    }
    // Token expirado ou inválido
    // console.warn('Token expirado!')
    return true
  }

  // This function checks to see if the user is already authenticated
  isAuthenticated() {
    const token = this.activeToken
    if (this.isExpiredToken(token)) {
      return false
    }
    return true
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  randomNumber = () => Math.floor(Math.random() * 10) + 1
  // This function gets the current token or fetches a new one if necessary
  async getToken() {
    if (!this.isAuthenticated()) {
      if (this.isRequestingToken) {
        // console.warn('Aguardando token...')
        await this.sleep(250 + this.randomNumber())
        return this.getToken()
      }
      const token = await this.fetchToken()
      // console.warn('Token obtido: ', token)
      this.activeToken.setToken(token.user_token)
      this.tokenLastRefreshed = parseFloat(token.token_last_refreshed)
    }
    return this.activeToken
  }

  // This function authenticates a user, which involves getting a new token
  // It returns a modified object with a new authorization header.
  async authenticate(props) {
    const token = await this.getToken()
    if (token && token.access_token) {
      props.mode = 'cors'
      delete props.credentials
      props.headers = {
        ...props.headers,
        Authorization: `Bearer ${this.activeToken.access_token}`
      }
    }
    return props
  }
}

// This creates a new session with the 'real' address used above
const session = new SDKSession({
  ...DefaultSettings,
  base_url: process.env.REACT_APP_LOOKER_API_HOST
})

// This exports the SDK with the authenticated session
export const lookerSDK = new Looker40SDK(session)
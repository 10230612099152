// Libs
import React from 'react'

// Project
import { BoxContent } from 'components'
import { filterTimeStore } from 'stores/filters'

export const IrregularitiesHistory = () => {
  return (
    <BoxContent hasIframe hasFilterTime>
      <iframe
        width="100%"
        title='Irregularidades'
        src={`https://scsbi.cloud.looker.com/embed/dashboards/11?allow_login_screen=true&Período=${filterTimeStore.filterName}`}
        frameBorder="0"
        allowFullScreen
        style={{ height: '70ch' }}
      />
    </BoxContent>
  )
}
// Libs
import { observable, action } from 'mobx'

export const wazeModalStore = observable({
  data: {
    street_name: '',
    location: []
  },
  reset: action(function () {
    this.data = {
      street_name: '',
      location: []
    }
  })
})

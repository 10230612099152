// Libs
import React from 'react'
import { Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

// Template
import { MDBox, MDTypography } from '@cloudmatize/cm-core-template/layout/components'

// Project
import { filterTimeStore } from 'stores/filters'

export const FilterTime = observer(({ optionsRangeTime }) => {
  const [tabValue, setTabValue] = React.useState(filterTimeStore.filterName ?? optionsRangeTime[optionsRangeTime.length - 1]?.en)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    filterTimeStore.setFilterTime(newValue)
  }

  return (
    <MDBox
      display="flex"
      sx={{
        pb: {
          xs: 4,
          sm: 3.5,
          md: 3.5,
          lg: 3,
          xl: 0
        },
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{
          borderRadius: '10px',
          border: '1px solid #cacacac7',
          backgroundColor: '#ffffff',
          alignItems: 'center',
          py: 0.2,
          my: 1,
        }}
      >
        {
          optionsRangeTime?.map((item, index) => (
            <Tab
              key={'tab-' + index}
              value={item?.en}
              label={
                <MDTypography
                  variant="body1"
                  fontSize={{ xs: "0.70rem", sm: "0.8rem", md: "0.9rem", lg: "0.95rem", xl: "1rem" }}
                  color={tabValue === item?.en ? "white" : "inherit"}>
                  {item?.pt}
                </MDTypography>
              }
              sx={{
                backgroundImage: tabValue === item?.en && 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                alignItems: 'center',
                borderRadius: '10px',
                px: 2,
                py: 0,
              }}
            />
          ))
        }
      </Tabs>
    </MDBox>
  )
})

FilterTime.defaultProps = {
  optionsRangeTime: [
    // { en: 'today', pt: 'Hoje' },
    { en: 'yesterday', pt: 'Ontem' },
    { en: '7 day', pt: 'Últ 7 dias' },
    { en: '30 day', pt: '30 dias' },
    { en: '90 day', pt: '90 dias' },
    { en: 'this year to second', pt: 'Este ano' }
  ]
}

FilterTime.propTypes = {
  optionsRangeTime: PropTypes.array
}

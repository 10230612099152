import { createContext, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { doSignIn, parseToken, doSignInWithCredential } from '../utils/rest'
import { authStore } from 'stores'
import { Rest } from 'application/rest'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'

export const AuthContext = createContext()

const AuthProvider = observer(({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [signed, setSigned] = useState(false)

  const signOut = () => {
    authStore.session = null
    authStore.user = null
    authStore.signed = false
    Cookies.remove('user')
    Cookies.remove('refresh')
    Cookies.remove('lookerToken')
    Cookies.remove('session')

    caches
      .keys()
      .then((cacheNames) => {
        return Promise.all(
          cacheNames.map((cacheName) => {
            if (cacheName.includes('mapbox')) {
              return null
            }
            return caches.delete(cacheName)
          })
        )
      })
      .catch((error) => {
        console.error('Erro ao limpar o cache:', error)
      })
  }

  useEffect(() => {
    console.log('useEffect authStore.isExpiredTokenAuthLooker')
    if (authStore.isExpiredTokenAuthLooker === true) {
      authStore.getUserFromCookie()
      authStore.isExpiredTokenAuthLooker = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.isExpiredTokenAuthLooker])

  useEffect(() => {
    authStore.getUserFromCookie()
  }, [])

  const signIn = async (googleCredentials) => {
    try {
      const token = await doSignIn(googleCredentials)
      if (token.access_token && token.refresh_token) {
        const decoded = parseToken(googleCredentials.credential)
        authStore.user = decoded
        authStore.session = token.access_token
        authStore.refresh_token = token.refresh_token
        authStore.signed = true
        Cookies.set('user', JSON.stringify(decoded), { expires: 86400 })
        Cookies.set('session', token.access_token, { expires: 60 })
        Cookies.set('refresh', token.refresh_token, { expires: 86400 })
        const rest = new Rest()
        rest.token = token.access_token
        rest.refreshToken = token.refresh_token
        await authStore.getLookerToken()
        return true
      } else {
        // setSigned(false)
        authStore.signed = false
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const signInWithCredential = async (credential) => {
    try {
      const token = await doSignInWithCredential(credential)
      if (token.access_token && token.refresh_token) {
        const decoded = parseToken(token.access_token)
        authStore.user = decoded
        authStore.session = token.access_token
        authStore.refresh_token = token.refresh_token
        authStore.signed = true
        Cookies.set('user', JSON.stringify(decoded), { expires: 86400 })
        Cookies.set('session', token.access_token, { expires: 60 })
        Cookies.set('refresh', token.refresh_token, { expires: 86400 })
        const rest = new Rest()
        rest.token = token.access_token
        rest.refreshToken = token.refresh_token
        await authStore.getLookerToken()
        return true
      } else {
        // setSigned(false)
        authStore.signed = false
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        user,
        setUser,
        signOut,
        loading,
        signIn,
        signInWithCredential,
        message,
        setMessage,
        setSigned
      }}
    >
      {children}
    </AuthContext.Provider>
  )
})

export default AuthProvider

// Libs
import { observer } from "mobx-react"
import { Navigate } from "react-router-dom"

// Project
import { authStore } from "stores"

export const Protected = observer(({ children }) => {
    if (!authStore.signed) {
        return <Navigate to="/login" replace />
    }
    return children
})

// Libs
import { Rest } from 'application/rest'
import { action, observable } from 'mobx'

const doPostLocation = async (data) => {
    const rest = new Rest('public/get-location-waze')
    rest.api = 'auth/api'
    const response = await rest.post(data)
    return await response.json()
}

export const filterTimeStore = observable({
    filterName: "this year to second",
    setFilterTime: action(function (filterName) {
        this.filterName = filterName
    })
})

export const filterAlertStore = observable({
    filterName: null,
    setFilterAlert: action(function (filterName) {
        this.filterName = filterName
    })
})

export const wazeStore = observable({
    loading: false,
    location: null,
    getLocationWaze: async function (location) {
        try {
            this.loading = true
            const data = {
                location: location
            }
            this.location = await doPostLocation(data)
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false
        }
    },
}, {
    getLocationWaze: action
})


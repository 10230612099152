// Icons
import HomeIcon from '@mui/icons-material/Home'
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert'
import CarCrashIcon from '@mui/icons-material/CarCrash'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import SpeedIcon from '@mui/icons-material/Speed'
import HistoryIcon from '@mui/icons-material/History'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ErrorIcon from '@mui/icons-material/Error'
import FloodIcon from '@mui/icons-material/Flood'
import MapIcon from '@mui/icons-material/Map'
import ListIcon from '@mui/icons-material/List'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import ThunderstormIcon from '@mui/icons-material/Thunderstorm'

// Project
import { HomePage, NotAllowed } from 'pages'
import { InfractionsAndFines } from 'pages/detransp_multas'
import { FrotaVeiculos } from 'pages/ibge_frota_veiculos'
import {
  MonitoredAreas,
  EditingSection,
  RegisterArea,
  RegisterAreaEvents
} from 'pages/maps'
import {
  AlertIndicators,
  AlertHistory,
  AlertHoles,
  AlertFloods,
  AccidentHistory,
  InfosOneAlert,
  InfosAccidentToday
} from 'pages/alerts'
import { JamsHistory, IrregularitiesHistory, Slowness } from 'pages/jams'
import { WeatherToday } from 'pages/weather'
import { WazePainel } from 'pages/waze'

let _routes = [
  {
    type: 'collapse',
    label: 'Principais indicadores',
    route: '/',
    icon: <HomeIcon />,
    component: <HomePage />
  },
  {
    type: 'submenu',
    label: 'Alertas',
    icon: <TaxiAlertIcon />,
    children: [
      {
        type: 'collapse',
        label: 'Tempo real',
        route: '/alerts/info-one-alert',
        icon: <TaxiAlertIcon />,
        component: <InfosOneAlert />
      },
      {
        type: 'collapse',
        label: 'Informações históricas',
        route: '/alerts/history',
        icon: <HistoryIcon />,
        component: <AlertHistory />
      },
      {
        type: 'collapse',
        label: 'Indicadores',
        route: '/alerts/indicators',
        icon: <SpeedIcon />,
        component: <AlertIndicators />
      },
      {
        type: 'collapse',
        label: 'Buracos',
        route: '/alerts/holes',
        icon: <ErrorIcon />,
        component: <AlertHoles />
      },
      {
        type: 'collapse',
        label: 'Alagamentos',
        route: '/alerts/floods',
        icon: <FloodIcon />,
        component: <AlertFloods />
      }
    ]
  },
  {
    type: 'submenu',
    label: 'Acidentes',
    icon: <CarCrashIcon />,
    children: [
      {
        type: 'collapse',
        label: 'Tempo real',
        route: '/accidents/infos-accidents-today',
        icon: <TaxiAlertIcon />,
        component: <InfosAccidentToday />
      },
      {
        type: 'collapse',
        label: 'Informações históricas',
        route: '/accidents/history',
        icon: <TaxiAlertIcon />,
        component: <AccidentHistory />
      }
    ]
  },
  {
    type: 'submenu',
    label: 'Tráfego',
    icon: <DepartureBoardIcon />,
    children: [
      {
        type: 'hidden',
        label: 'Informações históricas',
        route: '/jams/history',
        icon: <HistoryIcon />,
        component: <JamsHistory />
      },
      {
        type: 'hidden',
        label: 'Irregularidades',
        route: '/jams/irregularities',
        icon: <RemoveRedEyeIcon />,
        component: <IrregularitiesHistory />
      },
      {
        type: 'collapse',
        label: 'Índice de lentidão',
        route: '/jams/slowness',
        icon: <HomeIcon />,
        component: <Slowness />
      }
    ]
  },
  {
    type: 'collapse',
    label: 'Dados climáticos',
    route: '/weather-today',
    icon: <ThunderstormIcon />,
    component: <WeatherToday />
  },
  {
    type: 'hidden',
    label: 'Sem acesso',
    route: '/not-allowed',
    icon: <ErrorIcon />,
    component: <NotAllowed />
  },
  {
    type: 'collapse',
    label: 'Mapa ao vivo Waze',
    route: '/waze-painel',
    icon: 'maps',
    component: <WazePainel />
  }
  // {
  //   type: 'hidden',
  //   label: 'Mapa ao vivo Waze',
  //   route: '/waze-view-street/:street_name',
  //   icon: 'maps',
  //   component: <WazePlotStreet />
  // },
  // {
  //   type: 'hidden',
  //   label: 'Mapa ao vivo Waze - Rotas Monitoradas',
  //   route: '/waze-view-location/:location',
  //   icon: 'maps',
  //   component: <WazePlotLocation />
  // }
]

if (process.env.REACT_APP_MENU_MONITORED_AREAS) {
  _routes = [
    ..._routes,
    {
      type: 'submenu',
      label: 'Áreas monitoradas',
      route: '/monitored-areas',
      icon: <MapIcon />,
      children: [
        {
          type: 'collapse',
          label: 'Áreas registradas',
          route: '/monitored-areas/list',
          icon: <ListIcon />,
          component: <MonitoredAreas />
        },
        {
          type: 'hidden',
          label: 'Editar área',
          route: '/monitored-areas/editor-areas/:area_id',
          component: <EditingSection />
        },
        {
          type: 'collapse',
          label: 'Registrar área',
          icon: <AddLocationAltIcon />,
          route: '/monitored-areas/register-area',
          component: <RegisterArea />
        },
        {
          type: 'hidden',
          label: 'Registrar eventos',
          icon: <AddLocationAltIcon />,
          route: '/monitored-areas/register-events/:area_id',
          component: <RegisterAreaEvents />
        }
      ]
    }
  ]
}

if (process.env.REACT_APP_MENU_FLEET) {
  _routes = [
    ..._routes,
    {
      type: 'collapse',
      label: 'Frota de veículos',
      route: '/fleet',
      icon: <FormatListBulletedIcon />,
      component: <FrotaVeiculos />
    }
  ]
}

if (process.env.REACT_APP_MENU_INFRACTIONS) {
  _routes = [
    ..._routes,
    {
      type: 'collapse',
      label: 'Infrações e multas',
      route: '/infractions',
      icon: <RequestQuoteIcon />,
      component: <InfractionsAndFines />
    }
  ]
}

// if (process.env.REACT_APP_MENU_WAZE_PAINEL) {
//   _routes = [
//     ..._routes,
//     {
//       type: 'collapse',
//       label: 'Painel Waze',
//       route: '/waze-painel',
//       icon: "home",
//       component: <WazePainel />
//     },
//   ]
// }

export default _routes

import { IconButton, Paper } from '@mui/material'
import { Box } from '@mui/system'
import imgLogoSCS from 'assets/images/logo.png'
import { DrawerMenu } from 'components/DrawerMenu/DrawerMenu'
import UserMenu from 'components/UserMenu'
import { AuthContext } from 'contexts/auth'
import { useContext, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'

export function Header() {
  const [statusMenu, setStatusMenu] = useState(false)
  const { user, signOut } = useContext(AuthContext)
  const [menu, setMenu] = useState(false)

  function toggleMenu() {
    setStatusMenu(!statusMenu)
  }

  return (
    <Paper sx={{ borderRadius: 0 }}>
      <DrawerMenu
        status={statusMenu}
        toggleMenu={toggleMenu}
      />
      <Box
        width="100%"
        height="80px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="2rem"
      >
        <IconButton
          onClick={() => toggleMenu()}
          aria-label="open menu"
          size="large"
          sx={{ '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }}
        >
          <MenuIcon
            sx={{ color: '#7B809A' }}
            fontSize="inherit"
          />
        </IconButton>
        <Box>
          <img
            src={imgLogoSCS}
            width="172"
            height="73"
            alt="Logo"
          ></img>
        </Box>
        <Box>
          <UserMenu
            user={user}
            menu={menu}
            setMenu={setMenu}
            signOut={signOut}
          />
        </Box>
      </Box>
    </Paper>
  )
}

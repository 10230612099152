// Libs
import React, { useEffect, useState } from 'react'
import { Grid, } from '@mui/material'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

// Template
import { MDBox, MDTypography } from '@cloudmatize/cm-core-template/layout/components'

// Project
import { CardCustom, FilterTime } from 'components'
import { getDataLooker } from 'lookmls/getDataLooker'
import { lookerSDK } from 'lookmls/lookerSDK'

// Lookml
import lookmlCardTotalAlerts from 'lookmls/alerts_clustered/card_total_alerts_page.json'
import lookmlCardHazardNoSubtype from 'lookmls/alerts_clustered/card_hazard_no_subtype.json'
import lookmlCardHazardOnRoadCarStopped from 'lookmls/alerts_clustered/card_hazard_on_road_car_stopped.json'
import lookmlCardHazardOnRoadIce from 'lookmls/alerts_clustered/card_hazard_on_road_ice.json'
import lookmlCardHazardOnRoadLaneClosed from 'lookmls/alerts_clustered/card_hazard_on_road_lane_closed.json'
import lookmlCardHazardOnRoadObject from 'lookmls/alerts_clustered/card_hazard_on_road_object.json'
import lookmlCardHazardOnRoadOil from 'lookmls/alerts_clustered/card_hazard_on_road_oil.json'
import lookmlCardHazardOnRoadPotHole from 'lookmls/alerts_clustered/card_hazard_on_road_pot_hole.json'
import lookmlCardHazardOnRoadRoadKill from 'lookmls/alerts_clustered/card_hazard_on_road_road_kill.json'
import lookmlCardHazardOnRoadTrafficLightFault from 'lookmls/alerts_clustered/card_hazard_on_road_traffic_light_fault.json'
import lookmlCardHazardOnRoad from 'lookmls/alerts_clustered/card_hazard_on_road.json'
import lookmlCardHazardOnShoulderAnimals from 'lookmls/alerts_clustered/card_hazard_on_shoulder_animals.json'
import lookmlCardHazardOnShoulderCarStopped from 'lookmls/alerts_clustered/card_hazard_on_shoulder_car_stopped.json'
import lookmlCardHazardOnShoulderMissingSign from 'lookmls/alerts_clustered/card_hazard_on_shoulder_missing_sign.json'
import lookmlCardHazardOnShoulder from 'lookmls/alerts_clustered/card_hazard_on_shoulder.json'
import lookmlCardHazardWeatherFlood from 'lookmls/alerts_clustered/card_hazard_weather_flood.json'
import lookmlCardHazardWeatherFog from 'lookmls/alerts_clustered/card_hazard_weather_fog.json'
import lookmlCardHazardWeatherFreezingRain from 'lookmls/alerts_clustered/card_hazard_weather_freezing_rain.json'
import lookmlCardHazardWeatherHail from 'lookmls/alerts_clustered/card_hazard_weather_hail.json'
import lookmlCardHazardWeatherHeavyRain from 'lookmls/alerts_clustered/card_hazard_weather_heavy_rain.json'
import lookmlCardHazardWeatherHeavySnow from 'lookmls/alerts_clustered/card_hazard_weather_heavy_snow.json'
import lookmlCardHazardWeather from 'lookmls/alerts_clustered/card_hazard_weather.json'
import lookmlCardRoadClosedConstruction from 'lookmls/alerts_clustered/card_road_closed_construction.json'
import lookmlTypeOfAlerts from 'lookmls/alerts_clustered/type_of_alerts.json'
import { filterTimeStore } from 'stores/filters'

export const AlertLiveIndicators = observer(() => {
  const [data, setData] = useState({
    cardTotalAlerts: {},
    cardHazardNoSubtype: {},
    cardHazardOnRoadCarStopped: {},
    cardHazardOnRoadIce: {},
    cardHazardOnRoadLaneClosed: {},
    cardHazardOnRoadObject: {},
    cardHazardOnRoadOil: {},
    cardHazardOnRoadPotHole: {},
    cardHazardOnRoadRoadKill: {},
    cardHazardOnRoadTrafficLightFault: {},
    cardHazardOnRoad: {},
    cardHazardOnShoulderAnimals: {},
    cardHazardOnShoulderCarStopped: {},
    cardHazardOnShoulderMissingSign: {},
    cardHazardOnShoulder: {},
    cardHazardWeatherFlood: {},
    cardHazardWeatherFog: {},
    cardHazardWeatherFreezingRain: {},
    cardHazardWeatherHail: {},
    cardHazardWeatherHeavyRain: {},
    cardHazardWeatherHeavySnow: {},
    cardHazardWeather: {},
    cardRoadClosedConstruction: {},
    tableTypeOfAlerts: {},
  })

  function getDataLookerFiltered(key, lookml) {
    if (filterTimeStore.filterName) {
      if (lookml?.filters) {
        lookml.filters['alerts_clustered.ts_date'] = filterTimeStore.filterName
      } else {
        lookml.filters = { 'alerts_clustered.ts_date': filterTimeStore.filterName }
      }
    }
    setData((dt) => {
      return { ...dt, [key]: {} }
    })
    getDataLooker(lookerSDK, lookml).then((card) =>
      setData((dt) => {
        return { ...dt, [key]: card }
      })
    )
  }

  useEffect(() => {
    getDataLookerFiltered('cardTotalAlerts', lookmlCardTotalAlerts)
    getDataLookerFiltered('cardHazardNoSubtype', lookmlCardHazardNoSubtype)
    getDataLookerFiltered('cardHazardOnRoadCarStopped', lookmlCardHazardOnRoadCarStopped)
    getDataLookerFiltered('cardHazardOnRoadIce', lookmlCardHazardOnRoadIce)
    getDataLookerFiltered('cardHazardOnRoadLaneClosed', lookmlCardHazardOnRoadLaneClosed)
    getDataLookerFiltered('cardHazardOnRoadObject', lookmlCardHazardOnRoadObject)
    getDataLookerFiltered('cardHazardOnRoadOil', lookmlCardHazardOnRoadOil)
    getDataLookerFiltered('cardHazardOnRoadPotHole', lookmlCardHazardOnRoadPotHole)
    getDataLookerFiltered('cardHazardOnRoadRoadKill', lookmlCardHazardOnRoadRoadKill)
    getDataLookerFiltered('cardHazardOnRoadTrafficLightFault', lookmlCardHazardOnRoadTrafficLightFault)
    getDataLookerFiltered('cardHazardOnRoad', lookmlCardHazardOnRoad)
    getDataLookerFiltered('cardHazardOnShoulderAnimals', lookmlCardHazardOnShoulderAnimals)
    getDataLookerFiltered('cardHazardOnShoulderCarStopped', lookmlCardHazardOnShoulderCarStopped)
    getDataLookerFiltered('cardHazardOnShoulderMissingSign', lookmlCardHazardOnShoulderMissingSign)
    getDataLookerFiltered('cardHazardOnShoulder', lookmlCardHazardOnShoulder)
    getDataLookerFiltered('cardHazardWeatherFlood', lookmlCardHazardWeatherFlood)
    getDataLookerFiltered('cardHazardWeatherFog', lookmlCardHazardWeatherFog)
    getDataLookerFiltered('cardHazardWeatherFreezingRain', lookmlCardHazardWeatherFreezingRain)
    getDataLookerFiltered('cardHazardWeatherHail', lookmlCardHazardWeatherHail)
    getDataLookerFiltered('cardHazardWeatherHeavyRain', lookmlCardHazardWeatherHeavyRain)
    getDataLookerFiltered('cardHazardWeatherHeavySnow', lookmlCardHazardWeatherHeavySnow)
    getDataLookerFiltered('cardHazardWeather', lookmlCardHazardWeather)
    getDataLookerFiltered('cardRoadClosedConstruction', lookmlCardRoadClosedConstruction)
    getDataLookerFiltered('tableTypeOfAlerts', lookmlTypeOfAlerts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTimeStore.filterName])

  function validateTitle(data, field) {
    return data ? (data[0] ? data[0][field] : '-') : undefined
  }

  return (
    <MDBox sx={{ mx: { xs: 1, md: 3, lg: 2 } }}>
      <MDBox mb={-5} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="body1" fontSize="1.5rem">Indicadores históricos de Alertas</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" alignItems="center">
        <FilterTime />
      </MDBox>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <CardCustom
            title={validateTitle(
              data?.cardTotalAlerts?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            fontSize="large"
            description="Total de Alertas"
            urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
          />
        </Grid>
        <Grid item xs={0} sm={0} md={0} lg={6} xl={8} />
      </Grid>
      <Grid
        container
        spacing={1}
        mt={0.5}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardNoSubtype?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Alertas não categorizados"
            urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadCarStopped?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Veículo parado na via"
            urlImage="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Warning_icon.svg/2306px-Warning_icon.svg.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadIce?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Gelo na via"
            urlImage="https://cdn-icons-png.flaticon.com/512/3778/3778958.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadLaneClosed?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Via fechada"
            urlImage="https://cdn-icons-png.flaticon.com/512/3176/3176676.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadObject?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Objeto na via"
            urlImage="https://cdn-icons-png.flaticon.com/512/765/765921.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadOil?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Óleo na via"
            urlImage="https://cdn-icons-png.flaticon.com/512/938/938639.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadPotHole?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Buraco na via"
            urlImage="https://cdn-icons-png.flaticon.com/512/1866/1866512.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadRoadKill?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Atropelamento de animais"
            urlImage="https://cdn-icons-png.flaticon.com/512/1576/1576488.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoadTrafficLightFault?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Semáforo com defeito"
            urlImage="https://cdn-icons-png.flaticon.com/512/6157/6157942.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnRoad?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Perigo na via"
            urlImage="https://www.pngkit.com/png/detail/949-9496537_warning-icon-png.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnShoulderAnimals?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Animal na pista"
            urlImage="https://cdn1.iconfinder.com/data/icons/road-sing-flat-2/512/sign__road__yellow__animals_-512.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnShoulderCarStopped?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Veículo parado no acostamento"
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5IeotK58JfEzu6ugJKvdyQipMHvEwuVwpRwmr-FMG10CCujfgXTMXtX3OIFnM7YVv3Lw&usqp=CAU"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnShoulderMissingSign?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Ausência de sinalização no acostamento"
            urlImage="https://cdn-icons-png.flaticon.com/512/1172/1172449.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardOnShoulder?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Perigo no acostamento"
            urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeatherFlood?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Perigo de alagamento"
            urlImage="https://cdn3.iconfinder.com/data/icons/symbol-3-1/36/231-512.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeatherFog?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Perigo de neblina"
            urlImage="https://icon-library.com/images/fog-icon-png/fog-icon-png-23.jpg"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeatherFreezingRain?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Chuva de granizo"
            urlImage="https://w7.pngwing.com/pngs/481/557/png-transparent-weather-forecasting-computer-icons-rain-and-snow-mixed-ice-snow-white-cloud-weather-forecasting-thumbnail.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeatherHail?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Alerta de granizo"
            urlImage="https://theorytest.org.uk/wp-content/uploads/2021/01/ice-or-snow-ahead-sign.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeatherHeavyRain?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Alerta de chuva forte"
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrAVAJJqAPcmVExlmfnM8RhhzV7u9-fCMgSsyRu75c483CNCKBRwhS9IA_qH2Y39q10i0&usqp=CAU"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeatherHeavySnow?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Alerta de neve"
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxi1RYNGoIfyPRNtNHRyow1LX5ghPHy6AjKA&usqp=CAU"
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardHazardWeather?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Alerta de condições climáticas"
            urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn2NUb7GFFTxbMb1m-ZPF3M_O1lxxZlnxtzin9gKcsGspprBz3tfbRqxLGWQjWXQljwT8&usqp=CAU"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CardCustom
            title={validateTitle(
              data?.cardRoadClosedConstruction?.value,
              'alerts_clustered.count'
            )}
            filterTime={filterTimeStore.filterName}
            description="Via fechada por obras"
            urlImage="https://isinaliza.vtexassets.com/arquivos/ids/238874/3589-R.jpg?v=637709626917800000"
          />
        </Grid>
      </Grid>
    </MDBox>
  )
})

AlertLiveIndicators.defaultProps = {
  filterTime: null
}

AlertLiveIndicators.propTypes = {
  filterTime: PropTypes.string,
  setFilterTime: PropTypes.func
}

// Libs
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card
} from '@mui/material'

// Icons
import SaveIcon from '@mui/icons-material/Save'

// Template
import {
  MDInput,
  MDBox,
  MDButton,
  MDTypography,
  MDSnackbar
} from '@cloudmatize/cm-core-template/layout/components'

// Project
import Mapa from 'pages/maps/MapsComponents/Map/Map'
import { areaStore } from 'pages/maps/stores'
import { BoxContent } from 'components'
import { Loading } from '@cloudmatize/cm-core-template/layout/components_custom'
import { MessageSnack } from 'components/MessageSnack'

export const RegisterArea = observer((props) => {
  const [hasFormError] = useState(false)

  const [drawType, setDrawType] = useState('draw_area')
  const navigate = useNavigate()

  const handleNameChange = (event) => {
    areaStore.maps.name = event.target.value
  }

  if (areaStore.message) {
    MessageSnack(areaStore.message)
    areaStore.message = null
  }


  const handleAreaSave = async () => {
    let notError = true
    let errorFields = {
      'name': 'O monitoramento precisa de um nome',
      'area': 'Selecione a área',
      'geometric_field': 'Seleciona a área',
    }
    let requiredFalse = ['id', 'description', 'last_edited_user']
    Object.keys(areaStore.maps).map((field) => {
      if (!requiredFalse.includes(field)) {
        if (['', undefined, null].includes(areaStore.maps[field])) {
          notError = false
          areaStore.message = {
            content: errorFields[field] || `Existem campos à serem preenchidos`,
            severity: 'warning'
          }
          return notError
        }

      }

    })

    if (notError) {
      const response = await areaStore.save()
      if (response) {
        navigate(`/monitored-areas/register-events/${areaStore.id}`)
      }
    }
  }

  useEffect(() => {
    areaStore.reset()
  }, [])

  const handleTypeDrawSelected = (event) => {
    setDrawType(event.target.value)
  }

  return (
    <BoxContent>
      {areaStore.loading ? (
        <Loading />
      ) : (
        <>
          <Card sx={{ width: '100%' }}>
            <Grid container px={3} py={1} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDTypography variant="h6">Área</MDTypography>
                <MDInput
                  label="Nome da área"
                  placeholder="Nome da área"
                  onChange={handleNameChange}
                  value={areaStore.maps.name}
                  error={hasFormError}
                  readOnly={false}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDBox>
                  <MDTypography variant="h6">Tipo de desenho</MDTypography>
                  <FormControl>
                    <RadioGroup
                      row
                      onChange={handleTypeDrawSelected}
                      defaultValue="draw_area"
                    >
                      <FormControlLabel
                        value="draw_area"
                        control={<Radio />}
                        label="Região"
                      />
                      {/* <FormControlLabel
                    value="draw_routes"
                    control={<Radio />}
                    label="Rota"
                  /> */}
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleAreaSave}
                  sx={{ maxWidth: 250 }}
                  fullWidth
                >
                  <SaveIcon sx={{ mr: 1 }} />
                  Salvar
                </MDButton>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ width: '100%', p: 1, mb: 5, mt: 2 }}>
            <MDBox
              style={{
                height: 670,
                width: '100%',
                border: '1px solid #f0f0f0',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow:
                  '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);'
              }}
              id="map"
            >
              <Mapa draw_type={drawType} />
            </MDBox>
          </Card>
        </>
      )}



    </BoxContent >
  )
})

import {
  Box,
  Breadcrumbs as BreadcrumbsMui,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom/dist'
import { emphasize, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import { useEffect, useState } from 'react'
import routes from 'routes'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = '#fff'
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: '#344767',
    fontWeight: 'normal',
    fontSize: '1rem',
    '&:hover, &:focus': {
      cursor: 'pointer',
      backgroundColor: emphasize(backgroundColor, 0.06)
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12)
    },
    '& > span': {
      overflow: 'initial'
    }
  }
})

export function Breadcrumbs() {
  const location = useLocation()
  const theme = useTheme()
  const smScreenUp = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const [textBreadcrumb, setTextBreadcrumb] = useState([])

  useEffect(() => {
    let _textBreadcrumb =
      routes.filter((route) => route?.route === window.location.pathname)?.[0]
        ?.label ||
      routes.filter((route) =>
        route?.children?.find(
          (subroute) => subroute.route === window.location.pathname
        )
      )?.[0]?.label
    let _subroute = routes.filter((route) =>
      route?.children?.find(
        (subroute) => subroute?.route === window.location.pathname
      )
    )
    _subroute = _subroute?.[0]?.children?.find(
      (subroute) => subroute?.route === window.location.pathname
    )
    _textBreadcrumb = _subroute?.label
      ? `${_textBreadcrumb} / ${_subroute?.label}`
      : ` / ${_textBreadcrumb}`
    setTextBreadcrumb(_textBreadcrumb.split(' / '))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  if (location.pathname !== '/') {
    return (
      <Box
        ml={smScreenUp ? '3rem' : '1rem'}
        my="1rem"
      >
        <BreadcrumbsMui aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            label="Home"
            icon={<HomeIcon fontSize="small" />}
            onClick={() => navigate('/')}
          />
          {textBreadcrumb?.map((routeName) => {
            if (routeName && routeName !== "undefined" && routeName !== '') {
              return (
                <Typography
                  fontSize="1rem"
                  color="#344767"
                >
                  {routeName}
                </Typography>
              )
            }
            return null
          })}
        </BreadcrumbsMui>
      </Box>
    )
  }
  return null
}

import { MDBox } from '@cloudmatize/cm-core-template/layout/components'
import { observer } from 'mobx-react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const colorChange = keyframes`
  0%, 100% {
    border-color: #ff000035
  }
  25%, 75% {
    border-color: #ffffff00
  }
`

const Style = styled.div`
  width: 100%;
  border: 3px solid;
  border-color: red; /* Define a cor inicial da borda como vermelha */
  border-radius: 10px;
  animation: ${colorChange} 2.5s linear infinite; /* Aplica a animação às cores do border */
`

export const LiveBox = observer(({ live, children }) => {
  return (
    <MDBox>
      {
        live ?
          <Style>
            {children}
          </Style>
          :
          children
      }
    </MDBox>
  )
})

LiveBox.defaultProps = {
  live: true,
}

LiveBox.propTypes = {
  live: PropTypes.bool,
  children: PropTypes.node
}
// Libs
import { Rest } from 'application/rest'
import { observable, action } from 'mobx'

const doCreate = async (data) => {
  const rest = new Rest('maps_events')
  rest.api = 'auth/api'
  const response = await rest.post(data)
  return await response.json()
}
const doUpdate = async (id, data) => {
  const rest = new Rest('maps_events')
  rest.api = 'auth/api'
  rest.detail = id
  const response = await rest.put(data)
  return await response.json()
}
export const doGet = async (id) => {
  const rest = new Rest(`maps_events/${id}`)
  rest.api = 'auth/api'
  const response = await rest.get()
  return await response.json()
}

const doList = async (page = 1, page_size, filter = null) => {
  const rest = new Rest('maps_events')
  rest.api = 'auth/api'
  rest.query = {
    page,
    page_size
  }
  if (filter) {
    rest.query['filter'] = filter
  }
  const response = await rest.list()
  return await response.json()
}

const doDelete = async (id) => {
  const rest = new Rest(`maps_events/${id}`)
  rest.api = 'auth/api'
  const response = await rest.delete()
  return await response.json()
}

export const monitoredEventsStore = observable({
  _id: null,
  loading: false,
  events: {
    area: null,
    event_name: '',
    principal_event: [],
    sub_events: [],
    accident_events: [],
    jam_events: [],
    weather_events: [],
    hazard_on_road_events: [],
    trigger_event: []
  },
  maps_infos: {
    name: '',
    area: null,
    geometric_field: '',
    centroid: [],
    box_nav: []
  },
  event_test: {
    clear_fields: false
  },
  event_options: {
    general: [
      {
        type: 'Acidentes de veículos',
        subtype: [
          {
            type: 'Acidentes grave'
          },
          {
            type: 'Acidentes leve'
          }
        ]
      },
      {
        type: 'Congestionamento',
        subtype: [
          {
            type: 'Congestionamento leve'
          },
          {
            type: 'Congestionamento Moderado'
          },
          {
            type: 'Congestionamento Intenso'
          }
        ]
      },
      {
        type: 'Ordem Climática',
        subtype: [
          {
            type: 'Chuva'
          },
          {
            type: 'Alagamento'
          },
          {
            type: 'Neblina'
          },
          {
            type: 'Granizo'
          }
        ]
      },
      {
        type: 'Perigos na via',
        subtype: [
          {
            type: 'Carro Parado'
          },
          {
            type: 'Objetos na pista'
          },
          {
            type: 'Buracos na pista'
          },
          {
            type: 'Sem sinalização'
          },
          {
            type: 'Construção na pista'
          },
          {
            type: 'Óleo'
          },
          {
            type: 'Animais na pista'
          }
        ]
      }
    ]
  },
  trigger_options: {
    TRIGGER: [
      {
        type: 'email'
      }
    ]
  },

  list: {
    num_pages: null,
    page: null,
    page_size: null,
    total_size: null,
    results: [],
    filter: null,
    loading: false
  },
  reset: action(function () {
    this.maps_infos = {
      name: '',
      area: null,
      geometric_field: [],
      id: null,
      centroid: [],
      box_nav: []
    }
    this._id = null
    this.loading = false
    this.message = null
    this.error = null
  }),
  set id(id) {
    this.loading = true
    if (id) this._id = id
    const that = this
    doGet(id)
      .then((response) => {
        that.maps = response
      })
      .catch((error) => {
        console.error(error)
        that.message = {
          content:
            error.detail?.toString() || 'Houve um erro ao carregar o poligono',
          severity: 'error'
        }
      })
      .finally(() => {
        that.loading = false
      })
  },
  get id() {
    return this._id
  },
  reload: action(async function (page = 1) {
    let that = monitoredEventsStore
    try {
      that.list.loading = true
      that.list = await doList(page)
    } catch (error) {
      console.error(error)
    } finally {
      that.list.loading = false
    }
  }),
  save: action(async function () {
    this.loading = true
    const create = this.id === null
    try {
      let response
      this.events.principal_event = this.events.principal_event
        ? JSON.stringify(this.events.principal_event)
        : []
      this.events.accident_events = this.events.accident_events
        ? JSON.stringify(this.events.accident_events)
        : []
      this.events.jam_events = this.events.jam_events
        ? JSON.stringify(this.events.jam_events)
        : []
      this.events.weather_events = this.events.weather_events
        ? JSON.stringify(this.events.weather_events)
        : []
      this.events.hazard_on_road_events = this.events.hazard_on_road_events
        ? JSON.stringify(this.events.hazard_on_road_events)
        : []

      if (this.id) {
        response = await doUpdate(this.id, this.events)
      } else {
        response = await doCreate(this.events)
        this.id = response.id
      }
      this.events = response
      console.log(response)
      this.message = {
        content: create
          ? 'Área registrada com sucesso'
          : 'Área atualizada com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content:
          error.detail?.toString() || create
            ? 'Houve algum erro ao registrar área monitorada'
            : 'Houve algum erro ao atualizar área monitorada',
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  }),
  delete: action(async function () {
    this.loading = true
    try {
      await doDelete(this.id)
      this.message = {
        content: 'Área excluída com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content:
          error.detail?.toString() || 'Houve algum erro ao excluir a área',
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  })
})

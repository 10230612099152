/* eslint-disable */
import { Rest } from "application/rest";
import Cookies from "js-cookie";
import { authStore } from "stores";

const isTokenExpired = (token) => {
    const decoded = parseToken(token)
    return decoded.exp < Math.floor(Date.now() / 1000)
}

const parseToken = (token) => {
    return JSON.parse(atob(token.split('.')[1]))
}

const getNewToken = async () => {
    try {

        const rest = new Rest('refresh')
        rest.api = 'auth/api'
        const response = await rest.post({ refresh_token: authStore.refresh })

        if (response.access_token) {
            let user = Cookies.get("user")
            authStore.user = JSON.parse(user)
            authStore.session = response.access_token
            authStore.refresh = response.refresh_token
            Cookies.set("session", response.access_token, { expires: 1 })
            Cookies.set("refresh", response.refresh_token, { expires: 7 })
            const rest = new Rest()
            rest.token = response.access_token
            rest.refreshToken = response.refresh_token
            await authStore.getLookerToken()
            return response.access_token
        }
        return null
    } catch (error) {
        console.error(error)
        return null
    }
}


const doSignIn = async (googleCredentials) => {
    try {
        const rest = new Rest('token')
        rest.api = 'auth/api'
        rest.public = true
        const response = await rest.post(googleCredentials)
        return await response.json()
    } catch (error) {
        console.error(error)
    }
}

const doSignInWithCredential = async (credential) => {
    try {
        const rest = new Rest('lookertoken')
        rest.api = 'auth/api'
        rest.public = true
        const response = await rest.post({ credential: credential })
        return await response.json()
    } catch (error) {
        console.error(error)
    }
}



export { doSignIn, getNewToken, isTokenExpired, parseToken, doSignInWithCredential }

export async function getDataLooker(coreSDK, body = {}) {
  const result = coreSDK.run_inline_query({
    result_format: 'json',
    body: body
  })
  return result
}

export async function getDataLook(coreSDK, lookID = {}) {
  let result = {}

  result = await coreSDK.look(lookID)
  // console.log("result1", result)
  if (result?.['ok'] === false) return {}
  result = coreSDK.run_query({
    query_id: result?.['value']?.['query_id'],
    result_format: 'json'
  })
  // console.log("result2", result)
  return result

}

export async function listElementsDashboard(coreSDK, dashboardID) {
  // Retorna os elementos do dashboard com o id informado
  let elements = await coreSDK.dashboard_dashboard_elements(dashboardID)
  console.log("elements", elements)
  let table = []
  elements?.['value']?.forEach((element) =>
    element?.['query']?.['id'] &&  // Elimina os elementos markdown e text
    table.push({
      dashboard_id: element?.['dashboard_id'],
      element_id: element?.['id'],
      query_id: element?.['query']?.['id'],
      title: element?.['title'],
    })
  )
  console.table(table)
}

export async function getElementsDashboard({ coreSDK, elementID, filterTimeName = null, filterTime = { "view.ts_date": "this year to second" } }) {
  let element = await coreSDK.dashboard_element(elementID)
  // console.log("element", elementID, element)
  let query = element?.['value']?.['query']

  delete query?.['id']
  delete query?.['slug']
  delete query?.['client_id']

  // console.log("before", query)
  if (filterTimeName) {
    if (query?.filters) {
      query.filters['alerts_clustered.ts_date'] = filterTimeName
    } else {
      query.filters = { 'alerts_clustered.ts_date': "this year to second" }
    }
  }
  // console.log("after", query)

  let result = await coreSDK.run_inline_query({
    result_format: 'json',
    body: query
  })
  // console.log("result", elementID, result)
  return result
}
// Libs
import { Rest } from 'application/rest'
import { observable, action } from 'mobx'
import { mapsMonitoredEventStore } from 'pages/maps/stores'

const doCreate = async (data) => {
  const rest = new Rest('maps-monitored')
  rest.api = 'auth/api'
  const response = await rest.post(data)
  return await response.json()
}
const doUpdate = async (id, data) => {
  const rest = new Rest('maps-monitored')
  rest.api = 'auth/api'
  rest.detail = id
  const response = await rest.put(data)
  return await response.json()
}
export const doGet = async (id) => {
  const rest = new Rest(`maps-monitored/${id}`)
  rest.api = 'auth/api'
  const response = await rest.get()
  return await response.json()
}

const doList = async (id = null, page = 1, page_size, filter = null) => {
  const rest = new Rest(`maps-monitored`)
  rest.api = 'auth/api'
  rest.query = {
    page,
    page_size
  }
  if (filter) {
    rest.query['filter'] = filter
  }
  const response = await rest.list()
  return await response.json()
}

const doDelete = async (id) => {
  const rest = new Rest(`maps-monitored/${id}`)
  rest.api = 'auth/api'
  const response = await rest.delete()
  return await response.json()
}

export const mapsMonitoredStore = observable({
  _id: null,
  loading: false,
  maps_monitored: {
    name: '',
    map: null
  },
  list: {
    num_pages: null,
    page: null,
    page_size: null,
    total_size: null,
    results: [],
    filter: null,
    loading: false
  },
  reset: action(function () {
    this.name = ''
    this.map = null
    this.list = {
      num_pages: null,
      page: null,
      page_size: null,
      total_size: null,
      results: [],
      filter: null,
      loading: false
    }
  }),
  set id(id) {
    this.loading = true
    if (id) this._id = id
    const that = this
    doGet(id)
      .then((response) => {
        that.maps = response
      })
      .catch((error) => {
        console.error(error)
        that.message = {
          content:
            error.detail?.toString() || 'Houve um erro ao carregar o poligono',
          severity: 'error'
        }
      })
      .finally(() => {
        that.loading = false
      })
  },
  get id() {
    return this._id
  },
  reload: action(async function (page = 1, id = null) {
    let that = mapsMonitoredStore
    try {
      that.list.loading = true
      that.list = await doList(id, page)
    } catch (error) {
      console.error(error)
    } finally {
      that.list.loading = false
    }
  }),
  save: action(async function () {
    this.loading = true
    const create = this.id === null
    let errorMessage = create
      ? 'Houve algum erro ao registrar área monitorada'
      : 'Houve algum erro ao atualizar área monitorada'
    try {
      let response
      let data = {
        name: this.maps_monitored.name,
        map: this.maps_monitored.map,
        events: mapsMonitoredEventStore.selected_events,
        sub_events: mapsMonitoredEventStore.selected_sub_events
      }
      if (this.id) {
        response = await doUpdate(this.id, data)
      } else {
        response = await doCreate(data)
        this.id = response.id
      }
      this.maps_monitored = response
      this.message = {
        content: create
          ? 'Área registrada com sucesso'
          : 'Área atualizada com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content: error.detail ? error.detail : errorMessage,
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  }),
  delete: action(async function (id = null) {
    this.loading = true
    try {
      await doDelete(id ? id : this.id)
      this.message = {
        content: 'Área excluída com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content:
          error.detail?.toString() || 'Houve algum erro ao excluir a área',
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  })
})

import * as turf from '@turf/turf'

export const routeCentroidCalculator = (route_coordinates, route_length) => {
  if (route_coordinates === undefined || route_length === undefined) return null
  else {
    let route_medium_point = []
    // eslint-disable-next-line array-callback-return
    route_coordinates?.map((element) => {
      const coordinatesMatches = element?.match(/-?\d+\.\d+\s-?\d+\.\d+/g)
      const lineCoordinates = coordinatesMatches?.map((coordStr) => {
        const [lng, lat] = coordStr.split(' ').map(Number)
        return [lng, lat]
      })

      let medium_point = 0

      if (lineCoordinates.length > 5) medium_point = route_length / 2
      else {
        medium_point =
          turf.length(turf.lineString(lineCoordinates), {
            units: 'kilometers'
          }) / 2
      }

      let point = null

      try {
        point = turf.along(turf.lineString(lineCoordinates), medium_point, {
          units: 'kilometers'
        })
      } catch (e) {
        console.warn(e)
      }
      if (point === null) route_medium_point.push(lineCoordinates[0])

      route_medium_point.push(point.geometry.coordinates)
    })

    return route_medium_point
  }
}

// Libs
import React from 'react'

// Project
import { BoxContent } from 'components'

export const Slowness = () => {
    return (
        <BoxContent hasIframe>
            <iframe
                width="100%"
                title='Índices de lentidão'
                src={`https://scsbi.cloud.looker.com/embed/dashboards/25?allow_login_screen=true`}
                frameBorder="0"
                allowFullScreen
                style={{ height: '110ch' }}
            />
        </BoxContent>
    )
}

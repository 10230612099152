// Libs
import { useEffect } from 'react'
import { observer } from 'mobx-react'


export const WazePlotLocation = observer(({ location }) => {
  const coordinates = location.split(',')
  const lat = coordinates[1]
  const lon = coordinates[0]

  function funcToExecuteInSomeSeconds() {
    const iframe = document.getElementById('iframe-waze-painel')
    // eslint-disable-next-line no-self-assign
    iframe.src = iframe?.src
  }

  useEffect(() => {
    funcToExecuteInSomeSeconds()
    const interval = setInterval(() => {
      funcToExecuteInSomeSeconds()
    }, 60000) // 60 segundos
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <>
      {
        <iframe
          id="iframe-waze-painel"
          title="Painel de monitoramento - Waze"
          src={`https://embed.waze.com/pt-BR/iframe?zoom=18&lat=${Number(lat) || process.env.REACT_APP_CITY_LOCATION_LAT
            }&lon=${Number(lon) || process.env.REACT_APP_CITY_LOCATION_LON
            }&pin=1`}
          style={{ height: '100%', borderRadius: '10px', width: '100%' }}
          frameBorder="0"
          allowfullscreen
        />
      }
    </>
  )
})

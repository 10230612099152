// Libs
import React from 'react'
import { useState } from 'react'
import { observer } from 'mobx-react'
import {
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
  Button,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { BoxContent } from 'components'
import { area, bbox, centroid, polygon } from '@turf/turf'
// Icons
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Template
import {
  MDBox,
  MDButton,
  MDTypography,
  MDInput,
} from '@cloudmatize/cm-core-template/layout/components'

// Project
import EditMap from 'pages/maps/MapsComponents/Map/EditableMap'
import { areaStore } from 'pages/maps/stores'
import { monitoredEventsStore } from 'pages/maps/stores'
import Mapa from 'pages/maps/MapsComponents/Map/Map'
import { Loading } from '@cloudmatize/cm-core-template/layout/components_custom'
import { MessageSnack } from 'components/MessageSnack'
export const EditingSection = observer((props) => {
  const { area_id } = useParams()
  const navigate = useNavigate()
  const handleDeleteArea = async () => {
    await areaStore.delete(area_id)
    navigate('/monitored-areas/list')
  }
  const handleUpdateArea = async () => {
    await areaStore.save(area_id).then(() => {
      navigate('/monitored-areas/list')
    })
  }
  const handleNameChange = (event) => {
    areaStore.maps.name = event.target.value
  }

  if (areaStore.message) {
    MessageSnack(areaStore.message)
    areaStore.message = null
  }

  // Handle with get information from the map/area
  React.useEffect(() => {
    areaStore.reset()
    const getAreaInfos = async () => {
      try {
        areaStore.id = area_id
        const dataArea = await areaStore.getAreaDetails(area_id) // Getting information from area
        const { name, area, geometric_field } = dataArea

        const areaInKm2 = area / 1000000 // 1 m² = 0.000001 km²

        // Arredondando o valor para duas casas decimais
        const roundedAreaInKm2 = Number(areaInKm2.toFixed(2))

        areaStore.maps.area = roundedAreaInKm2

        const dataLocations = JSON.parse(JSON.parse(geometric_field))
        const locations = dataLocations?.map((coord) => [
          coord.longitude,
          coord.latitude
        ])

        const points = locations?.map((coord) => [coord[1], coord[0]])

        const poly = polygon([points])

        const centerCoords = centroid(poly).geometry.coordinates
        const navBoxCoords = bbox(poly)

        monitoredEventsStore.maps_infos.centroid = centerCoords
        monitoredEventsStore.maps_infos.box_nav = navBoxCoords

        if (locations !== []) {
          areaStore.maps.geometric_field = locations
        }
      } catch (error) {
        console.error(error)
      }
    }
    getAreaInfos()
  }, [area_id])

  return (
    <BoxContent>
      {areaStore.loading ? (
        <Loading />
      ) : (
        <>
          <Card sx={{ width: '100%' }}>
            <Grid container px={3} py={1} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDTypography variant="h6">Área</MDTypography>
                <MDInput
                  label="Nome da área"
                  placeholder="Nome da área"
                  onChange={handleNameChange}
                  value={areaStore.maps.name}
                  readOnly={false}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDBox>
                  <MDTypography variant="h6">Tipo de desenho</MDTypography>
                  <FormControl>
                    <RadioGroup
                      row
                      //onChange={handleTypeDrawSelected}
                      defaultValue="draw_area"
                    >
                      <FormControlLabel
                        value="draw_area"
                        control={<Radio />}
                        label="Região"
                      />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                flexDirection="row"
                rowSpacing={1}
              >
                <FormControl fullWidth sx={{ maxWidth: 140 }}>
                  <InputLabel id="demo-simple-select-label">Menu</InputLabel>
                  <Select labelId="demo-simple-select-label" value={10} label="Menu">
                    <MenuItem value={10}><Button disabled>Menu</Button></MenuItem>
                    <MenuItem value={30}>
                      <Button
                        variant="text"
                        color="error"
                        onClick={handleDeleteArea}
                        sx={{ maxWidth: 250, color: '#ff0000' }}
                        fullWidth
                      >
                        <DeleteIcon sx={{ mr: 1 }} />
                        Deletar
                      </Button>
                    </MenuItem>
                    <MenuItem value={20}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleUpdateArea}
                        sx={{ maxWidth: 250 }}
                        fullWidth
                      >
                        <SaveIcon sx={{ mr: 1 }} />
                        Salvar
                      </Button>
                    </MenuItem>
                  </Select>
                </FormControl>


              </Grid>
            </Grid>
          </Card>
          <Card sx={{ width: '100%', p: 1, mb: 5, mt: 2 }}>
            <MDBox
              style={{
                height: 670,
                width: '100%',
                border: '1px solid #f0f0f0',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow:
                  '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);'
              }}
              id="map"
            >
              {monitoredEventsStore.maps_infos.centroid && areaStore.maps.geometric_field && monitoredEventsStore.maps_infos.box_nav && (
                <EditMap
                  isEditable={true}
                  polygonCoordinates={areaStore.maps.geometric_field}
                  centroid={monitoredEventsStore.maps_infos.centroid}
                  navBox={monitoredEventsStore.maps_infos.box_nav}
                />
              )}

            </MDBox>
          </Card>
        </>
      )}

    </BoxContent>
  )
})

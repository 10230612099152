// Libs
import { Rest } from 'application/rest'
import { observable, action } from 'mobx'

const doCreate = async (data) => {
  const rest = new Rest('maps-monitored-event')
  rest.api = 'auth/api'
  const response = await rest.post(data)
  return await response.json()
}

const doUpdate = async (id, data) => {
  const rest = new Rest('maps-monitored-event')
  rest.api = 'auth/api'
  rest.detail = id
  const response = await rest.put(data)
  return await response.json()
}

export const doGet = async (id) => {
  const rest = new Rest(`maps-monitored-event/${id}`)
  rest.api = 'auth/api'
  const response = await rest.get()
  return await response.json()
}

export const doGetEvents = async (id) => {
  const rest = new Rest(id ? `maps-monitored-event/list-events/${id}` : `maps-monitored-event/list-events`)
  rest.api = 'auth/api'
  const response = await rest.get()
  return await response.json()
}

export const doGetSubEvents = async (id) => {
  const rest = new Rest(id ? `maps-monitored-event/list-sub-events/${id}` : `maps-monitored-event/list-sub-events`)
  rest.api = 'auth/api'
  const response = await rest.get()
  return await response.json()
}

const doList = async (page = 1, page_size, filter = null) => {
  const rest = new Rest('maps-monitored-event')
  rest.api = 'auth/api'
  rest.query = {
    page,
    page_size
  }
  if (filter) {
    rest.query['filter'] = filter
  }
  const response = await rest.list()
  return await response.json()
}

const doDelete = async (id) => {
  const rest = new Rest(`maps-monitored-event/${id}`)
  rest.api = 'auth/api'
  const response = await rest.delete()
  return await response.json()
}

export const mapsMonitoredEventStore = observable({
  _id: null,
  loading: false,
  maps_monitored_event: {
    maps_monitored: null,
    event: null,
    sub_event: null
  },
  db_events: [],
  db_sub_events: [],
  selected_events: [],
  selected_sub_events: [],
  list: {
    num_pages: null,
    page: null,
    page_size: null,
    total_size: null,
    results: [],
    filter: null,
    loading: false
  },
  reset: action(function () {
    this.maps_monitored = null
    this.event = null
    this.sub_event = null
  }),
  set id(id) {
    this.loading = true
    if (id) this._id = id
    const that = this
    doGet(id)
      .then((response) => {
        that.maps = response
      })
      .catch((error) => {
        console.error(error)
        that.message = {
          content:
            error.detail?.toString() || 'Houve um erro ao carregar o poligono',
          severity: 'error'
        }
      })
      .finally(() => {
        that.loading = false
      })
  },
  get id() {
    return this._id
  },
  reload: async function (page = 1) {
    let that = mapsMonitoredEventStore
    try {
      that.list.loading = true
      that.list = await doList(page)
    } catch (error) {
      console.error(error)
    } finally {
      that.list.loading = false
    }
  },
  save: action(async function () {
    this.loading = true
    const create = this.id === null
    try {
      let response
      if (this.id) {
        response = await doUpdate(this.id, this.maps_monitored_event)
      } else {
        response = await doCreate(this.maps_monitored_event)
        this.id = response.id
      }
      this.maps_monitored_event = response
      this.message = {
        content: create
          ? 'Área registrada com sucesso'
          : 'Área atualizada com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content:
          error.detail?.toString() || create
            ? 'Houve algum erro ao registrar área monitorada'
            : 'Houve algum erro ao atualizar área monitorada',
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  }),
  delete: action(
    async function () {
      this.loading = true
      try {
        await doDelete(this.id)
        this.message = {
          content: 'Área excluída com sucesso',
          severity: 'success'
        }
        this.error = null
        return true
      } catch (error) {
        console.error(error)
        this.error = error
        this.message = {
          content:
            error.detail?.toString() || 'Houve algum erro ao excluir a área',
          severity: 'error'
        }
        return false
      } finally {
        this.loading = false
      }
    },
  ),
  getEvents: async function (id) {
    this.loading = true
    if (id) this._id = id
    const that = this
    doGetEvents(id)
      .then((response) => {
        that.db_events = response
      })
      .catch((error) => {
        console.error(error)
        that.message = {
          content:
            error.detail?.toString() || 'Houve um erro ao carregar os eventos',
          severity: 'error'
        }
      })
      .finally(() => {
        that.loading = false
      })
  },
  getSubEvents: async function (id) {
    this.loading = true
    if (id) this._id = id
    const that = this
    doGetSubEvents(id)
      .then((response) => {
        that.db_sub_events = response
      })
      .catch((error) => {
        console.error(error)
        that.message = {
          content:
            error.detail?.toString() || 'Houve um erro ao carregar os eventos',
          severity: 'error'
        }
      })
      .finally(() => {
        that.loading = false
      })
  },
},
  {
    reload: action,
    getEvents: action,
    getSubEvents: action,
  }
)

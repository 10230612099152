import { Box, Drawer } from '@mui/material'
import { MenuItem } from 'components/DrawerMenu/MenuItem'
import routes from 'routes'
import { SubMenuItem } from 'components/DrawerMenu/SubMenu'

export function DrawerMenu({ status, toggleMenu }) {
  return (
    <Drawer
      anchor="left"
      open={status}
      onClose={() => toggleMenu()}
      PaperProps={{
        sx: { borderRadius: 0, margin: 0, height: '100%', width: '25rem' }
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        gap="0.5rem"
        px="2rem"
        py="2rem"
      >
        {routes.map((route) => {
          if (route.type === 'collapse') {
            return (
              <MenuItem
                key={route.route}
                text={route.label}
                icon={route.icon}
                link={route.route}
                toggleMenu={toggleMenu}
              />
            )
          } else if (route.type === 'submenu') {
            return (
              <SubMenuItem
                key={route.label}
                subMenuData={route.children}
                text={route.label}
                icon={route.icon}
                toggleMenu={toggleMenu}
              />
            )
          }
          return <></>
        })}
      </Box>
    </Drawer>
  )
}

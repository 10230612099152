// Libs
import { Card, Icon } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'

// Template
import {
  MDAlert,
  MDBox,
  MDButton,
  MDTypography
} from '@cloudmatize/cm-core-template/layout/components'
import { PageLayout } from '@cloudmatize/cm-core-template/layout/components_custom'

// Project
import { authStore } from 'stores'

export const NotAllowed = observer(() => {
  const navigate = useNavigate()

  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients }
          }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <MDBox sx={{ maxWidth: '45ch', margin: '0 auto' }}>
        <Card sx={{ m: 2, p: 2, backgroundColor: '#e4e4e455', mt: '36vh' }}>
          <MDBox>
            <MDAlert color="error">
              <Icon fontSize="small">error</Icon>&nbsp; Você não tem acesso para
              realizar ação!
            </MDAlert>
          </MDBox>
          <MDBox>
            <MDTypography variant="h4" textAlign="center" color="white">
              {authStore.signed ? "Clique no botão abaixo para voltar ao início" : "Clique no botão abaixo para realizar o login"}
            </MDTypography>
            <MDBox px={16} pt={1}>
              <MDButton
                onClick={() => authStore.signed ? navigate('/') : navigate('/login')}
                fullWidth
                sx={{ backgroundColor: '#eeeeee90' }}
              >
                Menu principal
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </PageLayout>
  )
})

// Libs
import React, { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { configure } from 'mobx'
// Template
import { MaterialUIControllerProvider } from '@cloudmatize/cm-core-template/layout/context'

// Project
import { App } from 'App'
import AuthProvider from 'contexts/auth'

configure({
  enforceActions: 'never'
})


const renderApp = () =>
  ReactDOM.render(
    <AuthProvider>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <StrictMode>
            <App />
          </StrictMode>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </AuthProvider>,
    document.getElementById('root')
  ) || undefined

renderApp()

// Libs
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import { Grid } from "@mui/material"

// Template
import { MDBox, MDTypography } from "@cloudmatize/cm-core-template/layout/components"
import { FilterTime } from "components"

export const BoxContent = observer(({ title, description, hasIframe, hasFilterTime, children }) => {
    return (
        <MDBox sx={{ mx: hasIframe ? { xs: 0 } : { xs: 1, md: 3, lg: 7 } }}>
            <MDBox pb={3.5}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={3.5}>
                        {
                            title &&
                            <MDTypography variant="body1" fontSize="1.55rem" color="dark" mt={1}>
                                {title}
                            </MDTypography>
                        }
                        {
                            description &&
                            <MDTypography variant="body1" fontSize="1.2rem">
                                {description}
                            </MDTypography>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} mb={hasIframe ? -8 : 0} >
                        <MDBox display="flex" position="relative" justifyContent="center" alignItems="center">
                            {
                                hasFilterTime &&
                                <FilterTime />
                            }
                        </MDBox>
                    </Grid>
                    <Grid item xs={0} md={0} lg={3.5} />
                </Grid>
            </MDBox>
            <MDBox sx={{ zIndex: -1, mt: hasIframe ? -4 : -2 }}>
                {children}
            </MDBox>
        </MDBox>
    )
})

BoxContent.defaultProps = {
    hasIframe: false,
    hasFilterTime: false
}

BoxContent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    hasIframe: PropTypes.bool,
    hasFilterTime: PropTypes.bool,
    children: PropTypes.node
}

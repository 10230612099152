// Libs
import React from 'react'

// Project
import { BoxContent } from 'components'
import { filterTimeStore } from 'stores/filters'

export const JamsHistory = () => {
  return (
    <BoxContent hasIframe hasFilterTime>
      <iframe
        width="100%"
        title='Histórico de Tráfego'
        src={`https://scsbi.cloud.looker.com/embed/dashboards/12?allow_login_screen=true&Período=${filterTimeStore.filterName}`}
        frameBorder="0"
        allowFullScreen
        style={{ height: '75ch' }}
      />
    </BoxContent>
  )
}
// Libs
import React, { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CardMedia, Grid, Skeleton, Tab, Tooltip } from '@mui/material'
import styled, { keyframes } from 'styled-components'
import { observer } from 'mobx-react'
import moment from 'moment'
import GaugeChart from 'react-gauge-chart'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DataProvider } from '@looker/components-data'
import { Query, Visualization } from '@looker/visualizations'
import { useLocation } from 'react-router-dom/dist'

// Icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import OpacityIcon from '@mui/icons-material/Opacity'

// Template
import {
  MDBox,
  MDTypography
} from '@cloudmatize/cm-core-template/layout/components'

// Assets
import imgWazeAngry from 'assets/images/waze/angry.png' // Bravo
import imgWazeBoredom from 'assets/images/waze/boredom.png' // Tedio
import imgWazeTired from 'assets/images/waze/tired.png' // Cansado
import imgWazeFast from 'assets/images/waze/fast.png' // Rápido
import imgWazeClock from 'assets/images/waze/clock.png' // Relógio
import imgWazeVeryFast from 'assets/images/waze/very_fast.png' // Muito rápido

// Project
import { CardCustom, LiveBox } from 'components'
import { lookerSDK } from 'lookmls/lookerSDK'
import { getDataLooker, getElementsDashboard } from 'lookmls/getDataLooker'
import { lookerStore, filterAlertStore } from 'stores'
import { WazeModalMap } from 'pages/waze/WazeModalMap'
import { wazeModalStore } from 'pages/waze/stores/wazeModalStore'

// LookML
import lookmlCardTotalAlertsRecent from 'lookmls/alerts/card_total_alerts.json'
import lookmlCardTotalAlerts7days from 'lookmls/alerts/card_total_alerts_7_days.json'
import lookmlCardTotalAlerts30days from 'lookmls/alerts/card_total_alerts_30_days.json'
import lookmlCardTotalAccidentsRecenty from 'lookmls/alerts/card_total_accidents.json'
import lookmlCardTotalAccidents7days from 'lookmls/alerts/card_total_accidents_7_days.json'
import lookmlCardTotalAccidents30days from 'lookmls/alerts/card_total_accidents_30_days.json'
import lookmlCardMultasInfracoes from 'lookmls/detransp_multas/card_multas_infracoes.json'
import lookmlCardFrotaVeiculos from 'lookmls/ibge_frota_veiculos/card_frota_veiculos.json'
import lookmlCardExtensionArea from 'lookmls/jams_clustered/card_extension_area.json'
import lookmlTableJamsLenghtLast15Min from 'lookmls/jams_clustered/table_jams_lenght_last_15_min.json'
import lookmlCardExtensionBloquedRoads from 'lookmls/jams_clustered/card_extension_bloqued_roads.json'
import lookmlCardTotalActiveWazers from 'lookmls/jams/card_total_active_wazers.json'
import lookmlTableMostCongestedRoads from 'lookmls/jams/table_most_congested_roads.json'
import lookmlCardSlowRateNow from 'lookmls/jams/slow_rate_now.json'
import lookmlTableWeatherToday from 'lookmls/weather/table_weather_today.json'
import { listElementsDashboard } from 'lookmls/getDataLooker'
import { routeCentroidCalculator } from 'utils/routeCentroidCalculator'

const fadeInOutAnimation = keyframes`
    0% {
    opacity: 0;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.2);
    }
    50% {
    opacity: 1;
    box-shadow: 0 0 8px 8px rgba(255, 0, 0, 0.5);
    }
    100% {
    opacity: 0;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.2);
    }
`

const RedDot = styled.div`
  width: 6px;
  height: 6px;
  background-color: red;
  border: 1px solid;
  border-style: double;
  border-color: red;
  border-radius: 50%;
  animation: ${fadeInOutAnimation} 2s infinite;
`

export const HomePage = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const [statusCache, setStatusCache] = useState(false)

  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'))
  const [tabJamSelected, setTabJamSelected] = useState(0)

  // Modal
  const [open, setOpen] = useState(false)

  const handleOpen = (valor) => {
    console.log(typeof valor)
    if (typeof valor === 'string') {
      wazeModalStore.data.name = valor
      wazeModalStore.data.location = []
    } else if (typeof valor === 'object') {
      wazeModalStore.data.location = valor
      wazeModalStore.data.name = ''
    }

    setOpen(true)
  }
  const handleClose = () => {
    wazeModalStore.reset()
    setOpen(false)
  }

  // const [responseINPE, setResponseINPE] = useState(null)
  const handleTabJamChange = (event, newValue) => {
    setTabJamSelected(newValue)
  }

  const [data, setData] = useState({
    cardExtensionArea: {},
    cardFrotaVeiculos: {},
    cardMultasInfracoes: {},
    cardTotalAlertsRecent: null,
    cardTotalAlerts7days: {},
    cardTotalAlerts30days: {},
    cardTotalAccidentsRecent: null,
    cardTotalAccidents7days: {},
    cardTotalAccidents30days: {},
    cardTotalRecentsAlertsInPeriod: {},
    cardTotalRecentsAccidentsInPeriod: {},
    tableJamsLenghtLast15Min: {},
    cardExtensionBloquedRoads: null,
    cardTotalActiveWazers: {},
    tableMostCongestedRoads: {},
    tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek: {},
    busiestTrafficScheduleToday: {},
    cardSlowRateNow: {},
    tableAlertsToday: {
      cardHazardOnRoadCarStopped: null,
      cardHazardOnRoadObject: null,
      cardHazardOnRoadTrafficLightFault: null,
      cardHazardOnShoulderCarStopped: null
    },
    tableAlerts7days: {
      cardHazardOnRoadObject7days: {},
      cardHazardOnShoulderCarStopped7days: {},
      cardHazardOnRoadCarStopped7days: {},
      cardHazardOnRoadTrafficLightFault7days: {}
    },
    tableAlerts30days: {
      cardHazardOnRoadObject30days: {},
      cardHazardOnShoulderCarStopped30days: {},
      cardHazardOnRoadCarStopped30days: {},
      cardHazardOnRoadTrafficLightFault30days: {}
    },
    tableRoadsMonitoredRoutes: {},
    tableWeatherToday: {},
    tableTrafficViewIrregularities: {}
  })

  const dictTableAlertsToday = {
    HAZARD_ON_ROAD_CAR_STOPPED: 'cardHazardOnRoadCarStopped',
    HAZARD_ON_ROAD_OBJECT: 'cardHazardOnRoadObject',
    HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT: 'cardHazardOnRoadTrafficLightFault',
    HAZARD_ON_SHOULDER_CAR_STOPPED: 'cardHazardOnShoulderCarStopped'
  }

  const dictTableAlerts7days = {
    HAZARD_ON_ROAD_CAR_STOPPED: 'cardHazardOnRoadCarStopped7days',
    HAZARD_ON_ROAD_OBJECT: 'cardHazardOnRoadObject7days',
    HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT:
      'cardHazardOnRoadTrafficLightFault7days',
    HAZARD_ON_SHOULDER_CAR_STOPPED: 'cardHazardOnShoulderCarStopped7days'
  }

  const dictTableAlerts30days = {
    HAZARD_ON_ROAD_CAR_STOPPED: 'cardHazardOnRoadCarStopped30days',
    HAZARD_ON_ROAD_OBJECT: 'cardHazardOnRoadObject30days',
    HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT:
      'cardHazardOnRoadTrafficLightFault30days',
    HAZARD_ON_SHOULDER_CAR_STOPPED: 'cardHazardOnShoulderCarStopped30days'
  }

  function configSetData(key, card) {
    setData((dt) => {
      return { ...dt, [key]: card }
    })
  }

  function loadDataCardsTotal() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '394'
    }).then((list) => {
      list?.value?.forEach((result) => {
        setData((dt) => {
          return {
            ...dt,
            tableAlertsToday: {
              ...dt['tableAlertsToday'],
              [dictTableAlertsToday[result?.['alerts.subtype']]]: result
            }
          }
        })
      })
    })
  }

  function loadDataCards7days() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '395'
    })
      .then((list) => {
        list?.value?.forEach((result) => {
          setData((dt) => {
            return {
              ...dt,
              tableAlerts7days: {
                ...dt['tableAlerts7days'],
                [dictTableAlerts7days[result?.['alerts_clustered.subtype']]]:
                  result
              }
            }
          })
        })
      })
      .then(() => {})
  }

  function loadDataCards30days() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '396'
    }).then((list) => {
      list?.value?.forEach((result) => {
        setData((dt) => {
          return {
            ...dt,
            tableAlerts30days: {
              ...dt['tableAlerts30days'],
              [dictTableAlerts30days[result?.['alerts_clustered.subtype']]]:
                result
            }
          }
        })
      })
    })
  }

  function loadDataTableMonitoredRoutes() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '408'
    })
      .then((list) => {
        list?.value?.forEach((result) => {
          setData((dt) => {
            return {
              ...dt,
              tableRoadsMonitoredRoutes: {
                ...dt['tableRoadsMonitoredRoutes'],
                [result?.['waze_monitoredroutes_now.id']]: result
              }
            }
          })
        })
      })
      .then(() => {})
  }
  useEffect(() => {
    const valores = Object.values(data?.tableRoadsMonitoredRoutes)

    const routes_infos = valores?.map((item) => {
      return {
        coordinates:
          data?.tableRoadsMonitoredRoutes[
            item?.['waze_monitoredroutes_now.id']
          ]?.['waze_monitoredroutes_now.geo'],
        length:
          data?.tableRoadsMonitoredRoutes[
            item?.['waze_monitoredroutes_now.id']
          ]?.['waze_monitoredroutes_now.length_km']
      }
    })

    let coordinates_array = []
    let medium_points = []
    routes_infos?.forEach((route, index) => {
      coordinates_array.push(JSON.stringify(route.coordinates))
      medium_points = routeCentroidCalculator(coordinates_array, route.length)
    })

    // eslint-disable-next-line array-callback-return
    valores?.map((item, index) => {
      item['medium_point'] = medium_points[index]
    })
  }, [data?.tableRoadsMonitoredRoutes])

  function loadDataTableTrafficViewIrregularities() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '451'
    }).then((list) => {
      list?.value?.forEach((result) => {
        setData((dt) => {
          return {
            ...dt,
            tableTrafficViewIrregularities: {
              ...dt['tableTrafficViewIrregularities'],
              [result?.['trafficview_irregularities.name']]: result
            }
          }
        })
      })
    })
  }

  const ConvertedDataTableTrafficViewIrregularities = () => {
    const list = [data?.tableTrafficViewIrregularities]
    let avgSpeedNow = 0
    let avgSpeedHistoric = 0
    let lengthKmNow = 0
    let timeMinNow = 0
    let historicTimeMin = 0
    let jamlevel = 0
    list?.forEach((item, index) => {
      Object?.keys(item).forEach((key) => {
        const value = item[key]

        avgSpeedNow = (
          (value['trafficview_irregularities.length'] /
            value['trafficview_irregularities.time']) *
          3.6
        ).toFixed(2)
        avgSpeedHistoric = (
          (value['trafficview_irregularities.length'] /
            value['trafficview_irregularities.historic_time']) *
          3.6
        ).toFixed(2)
        lengthKmNow = (
          value['trafficview_irregularities.length'] / 1000
        ).toFixed(2)
        timeMinNow = (value['trafficview_irregularities.time'] / 60).toFixed(2)
        historicTimeMin = (
          value['trafficview_irregularities.historic_time'] / 60
        ).toFixed(2)

        jamlevel = value['trafficview_irregularities.level']
      })
    })
    return (
      <>{`Percurso de ${lengthKmNow} km de extensão  -
    Congestionamento - ${labelTraffic[jamlevel]} -
    Velocidade agora de ${avgSpeedNow} km/h, 
    velocidade histórica ${avgSpeedHistoric} km/h -
    Tempo de percurso agora ${Math.round(timeMinNow)} min(s) e 
    tempo de percurso histórico ${Math.round(historicTimeMin)} min(s)`}</>
    )
  }

  function loadDataTableBusiestTrafficScheduleToday() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '439'
    }).then((list) => {
      list?.value?.forEach((result) => {
        setData((dt) => {
          return {
            ...dt,
            busiestTrafficScheduleToday: result
          }
        })
      })
    })
  }

  function loadDataTableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek() {
    getElementsDashboard({
      coreSDK: lookerSDK,
      elementID: '422'
    }).then((list) => {
      list?.value?.forEach((result) => {
        setData((dt) => {
          return {
            ...dt,
            tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek:
              result
          }
        })
      })
    })
  }

  function getDataLookerBySDK(key, lookml) {
    getDataLooker(lookerSDK, lookml).then((card) => {
      if (key === 'cardTotalActiveWazers') {
      }
      configSetData(key, card)
    })
  }

  const redirectToLiveAlert = (type) => {
    if (type) {
      filterAlertStore.setFilterAlert(type)
    }
    navigate('/alerts/info-one-alert')
  }

  const redirectToLiveAccidents = (type) => {
    navigate('/accidents/infos-accidents-today')
  }

  const redirectToWeatherToday = () => {
    navigate('/weather-today')
  }

  function funcToExecuteIn60seconds() {
    getDataLookerBySDK(
      'tableJamsLenghtLast15Min',
      lookmlTableJamsLenghtLast15Min
    )
    getDataLookerBySDK(
      'cardExtensionBloquedRoads',
      lookmlCardExtensionBloquedRoads
    )
    getDataLookerBySDK('cardTotalActiveWazers', lookmlCardTotalActiveWazers)
    getDataLookerBySDK('tableMostCongestedRoads', lookmlTableMostCongestedRoads)
    getDataLookerBySDK('cardSlowRateNow', lookmlCardSlowRateNow)
    getDataLookerBySDK('cardTotalAlertsRecent', lookmlCardTotalAlertsRecent)
    getDataLookerBySDK('cardTotalAlerts7days', lookmlCardTotalAlerts7days)
    getDataLookerBySDK('cardTotalAlerts30days', lookmlCardTotalAlerts30days)
    getDataLookerBySDK(
      'cardTotalAccidentsRecent',
      lookmlCardTotalAccidentsRecenty
    )
    getDataLookerBySDK('cardTotalAccidents7days', lookmlCardTotalAccidents7days)
    getDataLookerBySDK(
      'cardTotalAccidents30days',
      lookmlCardTotalAccidents30days
    )
    getDataLookerBySDK('tableWeatherToday', lookmlTableWeatherToday)
    loadDataCardsTotal()
    loadDataCards7days()
    loadDataCards30days()
    loadDataTableMonitoredRoutes()
    loadDataTableBusiestTrafficScheduleToday()
    lookerStore.getLastTimeUpdate()
    listElementsDashboard(lookerSDK, 9)
    // setStatusCache(true)
    loadDataTableTrafficViewIrregularities()
  }

  function funcToExecuteIn300seconds() {
    const iframe = document.getElementById('iframe26')
    // eslint-disable-next-line no-self-assign
    iframe.src = iframe?.src
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('HH:mm'))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    // fetch('http://servicos.cptec.inpe.br/XML/cidade/4772/previsao.xml').then((response) => response.text()).then((data) => console.log(data))
    funcToExecuteIn60seconds()
    const interval = setInterval(
      () => {
        funcToExecuteIn60seconds()
      },
      process.env.REACT_APP_DEV ? 10000 : 60000
    )
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    funcToExecuteIn300seconds()
    const interval = setInterval(() => {
      funcToExecuteIn300seconds()
    }, 300000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    loadDataTableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek()
    getDataLookerBySDK('cardExtensionArea', lookmlCardExtensionArea)
    getDataLookerBySDK('cardFrotaVeiculos', lookmlCardFrotaVeiculos)
    getDataLookerBySDK('cardMultasInfracoes', lookmlCardMultasInfracoes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ('caches' in window && statusCache) {
      const dataHome = new Response(JSON.stringify(data))
      caches.open(location.pathname).then((cache) => {
        cache.put(location.pathname, dataHome)
      })
    }
    setStatusCache(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCache])

  useEffect(() => {
    const fetchDataFromCache = async () => {
      try {
        const response = await caches.open(location.pathname)
        const cachedData = await response.match(location.pathname)
        if (cachedData) {
          const dataCached = await cachedData.json()
          setData(dataCached)
        }
      } catch (error) {
        console.error('Erro ao recuperar dados do Cache Storage:', error)
      }
    }
    fetchDataFromCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const colorsTraffic = {
    0: '#1b831b',
    1: '#159c15',
    2: '#6d970a',
    3: '#ac5300',
    4: '#cf1a1a'
  }

  const labelTraffic = {
    0: 'Livre',
    1: 'Leve',
    2: 'Moderado',
    3: 'Intenso',
    4: 'Muito intenso'
  }

  const labelDayWeek = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado'
  }

  function getLabelWithArrow(value, oldValue, label, positionArrow) {
    if (value === null || oldValue === null) {
      return null
    }

    return (
      <MDBox width="100%" margin="0 auto">
        {typeof value === 'number' && typeof oldValue === 'number' && (
          <MDBox>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              {positionArrow === 'left' ? (
                ((1 - value / oldValue) * 100).toFixed(1) < 0 ? (
                  <ArrowUpwardIcon sx={{ color: '#cf1a1a' }} />
                ) : (
                  <ArrowDownwardIcon sx={{ color: '#159c15' }} />
                )
              ) : null}
              <MDTypography
                variant="h6"
                fontSize="0.7rem"
                align="center"
                sx={{
                  color:
                    ((1 - value / oldValue) * 100).toFixed(1) === 0
                      ? '#344767'
                      : ((1 - value / oldValue) * 100).toFixed(1) < 0
                      ? '#cf1a1a'
                      : '#159c15'
                }}
              >
                {Math.abs((1 - value / oldValue) * 100).toFixed(1)}%
              </MDTypography>
              {positionArrow === 'right' ? (
                ((1 - value / oldValue) * 100).toFixed(1) < 0 ? (
                  <ArrowUpwardIcon sx={{ color: '#cf1a1a' }} />
                ) : (
                  <ArrowDownwardIcon sx={{ color: '#159c15' }} />
                )
              ) : null}
            </MDBox>
            <MDBox>
              {label && (
                <MDTypography
                  variant="h6"
                  fontSize="0.7rem"
                  align="center"
                  sx={{
                    color:
                      ((1 - value / oldValue) * 100).toFixed(1) === 0
                        ? '#344767'
                        : ((1 - value / oldValue) * 100).toFixed(1) < 0
                        ? '#cf1a1a'
                        : '#159c15'
                  }}
                >
                  {label}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    )
  }

  function validateTitle(_data, field = 'alerts_clustered.count') {
    if (_data === null) {
      return 0
    }

    let value = _data
      ? _data[0]
        ? _data[0][field]
        : _data[field]
        ? _data[field]
        : 0
      : 0

    if (Object.keys(_data ? _data : {})?.length === 0) {
      return 0
    }
    return value
  }

  function getValueInData(data, field) {
    let value = data
      ? data[0]
        ? data[0][field]
        : data[field]
        ? data[field]
        : null
      : null
    return value
  }

  function calculeProgressAlert({
    currentValue = null,
    oldValue = null,
    label = null,
    positionArrow = 'left'
  }) {
    if (
      !(currentValue && oldValue) ||
      currentValue === null ||
      oldValue === null
    ) {
      return null
    }
    if (typeof currentValue === 'string') {
      currentValue = 0
    }

    return getLabelWithArrow(currentValue, oldValue, label, positionArrow)
  }

  const Speedometer = memo(GaugeChart)

  return (
    <>
      <MDBox sx={{ mx: { xs: 1, md: 3, lg: 2, cursor: 'default' } }}>
        <MDBox
          sx={{
            mt: 3,
            mb: 1,
            backgroundColor: '#ffffffa0',
            borderRadius: '10px',
            width: '100%',
            boxShadow:
              '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);'
          }}
        >
          <Grid container alignItems="end">
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={1.5}
              xl={1.5}
              display="flex"
              justifyContent="center"
            >
              {!!data?.cardSlowRateNow?.value &&
              Object.keys(
                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
              )?.length > 0 ? (
                <MDBox sx={{ maxWidth: '180px' }}>
                  <Speedometer
                    id="gauge-chart4"
                    animate={false}
                    hideText={true}
                    nrOfLevels={12}
                    arcPadding={0.05}
                    cornerRadius={5}
                    percent={
                      parseFloat(
                        (
                          data?.cardSlowRateNow?.value?.[0]?.[
                            'jams_today.lengthKmSum'
                          ] /
                          data
                            ?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek?.[
                            'jams_comparative_90d_today.total_length_km_90'
                          ]
                        )?.toFixed(5)
                      ) ?? 0
                    }
                  />
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    textAlign="center"
                    mt={-2}
                  >
                    {`${
                      data?.cardSlowRateNow?.value?.[0]?.[
                        'jams_today.lengthKmSum'
                      ]?.toFixed(2) || '-'
                    } km`}
                  </MDTypography>
                  <MDTypography
                    variant="h6"
                    fontSize="1.2rem"
                    textAlign="center"
                    mt={-1}
                  >
                    Lentidão atual
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox p={1}>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      height: 95,
                      width: 160
                    }}
                  />
                </MDBox>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={1.5}
              xl={1.5}
              display="flex"
              justifyContent="center"
            >
              <MDBox>
                {validateTitle(
                  data?.cardTotalActiveWazers?.value,
                  'waze_wazerscount_now.sum_wazers_count'
                ) ? (
                  <MDTypography
                    variant="h4"
                    fontSize="1.8rem"
                    textAlign="center"
                  >
                    {validateTitle(
                      data?.cardTotalActiveWazers?.value,
                      'waze_wazerscount_now.sum_wazers_count'
                    )?.toFixed(0)}
                  </MDTypography>
                ) : (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        borderRadius: '10px',
                        height: 50,
                        width: 90,
                        mx: 2
                      }}
                    />
                  </MDBox>
                )}
                {data?.cardTotalActiveWazers?.value ? (
                  <MDTypography
                    variant="body1"
                    fontWeight="bold"
                    fontSize="1.1rem"
                    textAlign="center"
                  >
                    Usuários ativos
                  </MDTypography>
                ) : (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    my={0.5}
                  >
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        borderRadius: '10px',
                        height: 28,
                        width: 120
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              xl={6}
              display="flex"
              justifyContent="center"
            >
              <MDBox>
                {data?.tableJamsLenghtLast15Min?.value?.length > 0 ? (
                  <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {[1, 2, 3, 4]?.map((index) => (
                      <Grid
                        item
                        key={'jam-mdbox-' + index}
                        sx={{
                          boxShadow:
                            '0 3px 5px rgba(154,160,185,.3), 0 3px 5px rgba(166,173,201,.3);',
                          backgroundColor: colorsTraffic[index] + '15',
                          borderTopLeftRadius: index === 1 && '10px',
                          borderBottomLeftRadius: index === 1 && '10px',
                          borderTopRightRadius: index === 4 && '10px',
                          borderBottomRightRadius: index === 4 && '10px',
                          px: 1.5,
                          my: 0.5,
                          cursor: 'default'
                        }}
                      >
                        <MDBox>
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={-1}
                          >
                            <MDTypography
                              variant="body1"
                              fontWeight="bold"
                              textAlign="center"
                              mr={0.5}
                              sx={{
                                color: colorsTraffic[index],
                                fontSize: {
                                  xs: '1.2rem',
                                  md: '1.3rem',
                                  lg: '1.6rem'
                                }
                              }}
                            >
                              {data?.tableJamsLenghtLast15Min?.value
                                ?.find(
                                  (item) => item?.['jams_today.level'] === index
                                )
                                ?.['jams_today.sumLengthKm']?.toFixed(2) ||
                                '0.00'}
                            </MDTypography>
                            <MDTypography
                              variant="body1"
                              fontWeight="bold"
                              textAlign="center"
                              sx={{
                                color: colorsTraffic[index],
                                fontSize: {
                                  xs: '1rem',
                                  md: '1.1rem',
                                  lg: '1.5rem'
                                }
                              }}
                            >
                              km
                            </MDTypography>
                          </MDBox>
                          <MDTypography
                            variant="body1"
                            fontSize="1rem"
                            textAlign="center"
                            sx={{
                              color: colorsTraffic[index]
                            }}
                          >
                            {labelTraffic[index]}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width="70%"
                      sx={{
                        borderRadius: '10px',
                        height: 50
                      }}
                    />
                  </MDBox>
                )}
                {data?.tableJamsLenghtLast15Min?.value?.length > 0 ? (
                  <MDTypography
                    variant="h6"
                    fontSize="1.2rem"
                    textAlign="center"
                  >
                    Lentidão por nível
                  </MDTypography>
                ) : (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        borderRadius: '10px',
                        height: 28,
                        width: 200
                      }}
                    />
                  </MDBox>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={1.5} xl={1.5}>
              {data?.tableWeatherToday?.value?.length > 0 ? (
                <MDBox
                  onClick={() => redirectToWeatherToday()}
                  sx={{ cursor: 'pointer' }}
                >
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={
                        data?.tableWeatherToday?.value?.[0]?.[
                          'weather_today__forecast__forecastday__hour.condition__icon'
                        ]
                      }
                      alt="icon-weather"
                      width="40px"
                      height="40px"
                    />
                    <MDTypography variant="body1" fontSize="1.8rem">
                      {data?.tableWeatherToday?.value?.[0]?.[
                        'weather_today__forecast__forecastday__hour.temp_c'
                      ]?.toFixed(0) || '-'}
                      °C
                    </MDTypography>
                  </MDBox>
                  <Tooltip
                    title="Clima atual e probabilidade de chuva"
                    placement="top"
                    arrow
                  >
                    <MDTypography
                      variant="body1"
                      fontSize="0.9rem"
                      textAlign="center"
                    >
                      {
                        data?.tableWeatherToday?.value?.[0]?.[
                          'weather_today__forecast__forecastday__hour.condition__text'
                        ]
                      }{' '}
                      <br />
                      <OpacityIcon color="info" sx={{ pt: 0.25 }} />
                      {`${data?.tableWeatherToday?.value?.[0]?.['weather_today.current__precip_mm']}mm 
                                        ${data?.tableWeatherToday?.value?.[0]?.['weather_today__forecast__forecastday__hour.average_chance_of_rain']}%`}
                      <OpacityIcon color="info" sx={{ pt: 0.25 }} />
                    </MDTypography>
                  </Tooltip>
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="center" mb={1}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      borderRadius: '10px',
                      height: 100,
                      width: '90%'
                    }}
                  />
                </MDBox>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2.5} xl={1.5}>
              <MDBox pr={3}>
                <MDBox>
                  <MDTypography
                    variant="body1"
                    fontSize="1rem"
                    textAlign="right"
                  >
                    HORÁRIO ATUAL
                  </MDTypography>
                  <MDTypography
                    variant="body1"
                    fontSize="2rem"
                    textAlign="right"
                  >
                    {currentTime}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="end" alignItems="center">
                  <RedDot />
                  <MDTypography
                    variant="body1"
                    fontSize="0.8rem"
                    textAlign="right"
                    ml={1.5}
                  >
                    TRÁFEGO EM TEMPO REAL
                  </MDTypography>
                </MDBox>
                <MDTypography
                  variant="body2"
                  fontSize="0.7rem"
                  textAlign="right"
                  mt={0.5}
                >
                  {`Atualizado em: ${lookerStore.lastTimeUpdate} `}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex">
          <Grid container spacing={1.25}>
            <Grid item xs={12} md={12} lg={6}>
              <Grid container spacing={1.25} mt={0}>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CardCustom
                    title={validateTitle(
                      data?.cardTotalAlertsRecent?.value,
                      'alerts.count'
                    )}
                    description="Alertas"
                    labelProgressLeft={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.cardTotalAlertsRecent?.value,
                        'alerts.count'
                      ),
                      oldValue:
                        getValueInData(
                          data?.cardTotalAlerts7days?.value,
                          'alerts_clustered.count'
                        ) / 7,
                      label: '7 dias',
                      positionArrow: 'left'
                    })}
                    labelProgressRight={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.cardTotalAlertsRecent?.value,
                        'alerts.count'
                      ),
                      oldValue:
                        getValueInData(
                          data?.cardTotalAlerts30days?.value,
                          'alerts_clustered.count'
                        ) / 30,
                      label: '1 mês',
                      positionArrow: 'right'
                    })}
                    labelTime="Hoje"
                    onClickCustom={() =>
                      getValueInData(
                        data?.cardTotalAlertsRecent?.value,
                        'alerts.count'
                      ) > 0 && redirectToLiveAlert(' ')
                    }
                    urlImage="https://cdn-icons-png.flaticon.com/512/3696/3696367.png"
                    live
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CardCustom
                    title={validateTitle(
                      data?.cardTotalAccidentsRecent?.value,
                      'alerts.count'
                    )}
                    description="Acidentes"
                    labelProgressLeft={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.cardTotalAccidentsRecent?.value,
                        'alerts.count'
                      ),
                      oldValue:
                        getValueInData(
                          data?.cardTotalAccidents7days?.value,
                          'alerts_clustered.count'
                        ) / 7,
                      label: '7 dias',
                      positionArrow: 'left'
                    })}
                    labelProgressRight={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.cardTotalAccidentsRecent?.value,
                        'alerts.count'
                      ),
                      oldValue:
                        getValueInData(
                          data?.cardTotalAccidents30days?.value,
                          'alerts_clustered.count'
                        ) / 30,
                      label: '1 mês',
                      positionArrow: 'right'
                    })}
                    labelTime="Hoje"
                    onClickCustom={() =>
                      getValueInData(
                        data?.cardTotalAccidentsRecent?.value,
                        'alerts.count'
                      ) > 0 && redirectToLiveAccidents()
                    }
                    urlImage="https://cdn-icons-png.flaticon.com/512/1576/1576488.png"
                    live
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CardCustom
                    title={validateTitle(
                      data?.tableAlertsToday?.cardHazardOnRoadTrafficLightFault,
                      'alerts.count'
                    )}
                    description="Semáforo com defeito"
                    labelProgressLeft={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday
                          ?.cardHazardOnRoadTrafficLightFault,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts7days
                          ?.cardHazardOnRoadTrafficLightFault7days,
                        'alerts_clustered.count_avg_7_days'
                      ),
                      label: '7 dias',
                      positionArrow: 'left'
                    })}
                    labelProgressRight={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday
                          ?.cardHazardOnRoadTrafficLightFault,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts30days
                          ?.cardHazardOnRoadTrafficLightFault30days,
                        'alerts_clustered.count_avg_30_days'
                      ),
                      label: '1 mês',
                      positionArrow: 'right'
                    })}
                    labelTime="Hoje"
                    onClickCustom={() =>
                      getValueInData(
                        data?.tableAlertsToday
                          ?.cardHazardOnRoadTrafficLightFault,
                        'alerts.count'
                      ) > 0 &&
                      redirectToLiveAlert('HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT')
                    }
                    urlImage="https://cdn-icons-png.flaticon.com/512/6157/6157942.png"
                    live
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CardCustom
                    title={validateTitle(
                      data?.tableAlertsToday?.cardHazardOnRoadCarStopped,
                      'alerts.count'
                    )}
                    description="Veículo parado na via"
                    labelProgressLeft={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday?.cardHazardOnRoadCarStopped,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts7days?.cardHazardOnRoadCarStopped7days,
                        'alerts_clustered.count_avg_7_days'
                      ),
                      label: '7 dias',
                      positionArrow: 'left'
                    })}
                    labelProgressRight={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday?.cardHazardOnRoadCarStopped,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts30days
                          ?.cardHazardOnRoadCarStopped30days,
                        'alerts_clustered.count_avg_30_days'
                      ),
                      label: '1 mês',
                      positionArrow: 'right'
                    })}
                    labelTime="Hoje"
                    onClickCustom={() =>
                      getValueInData(
                        data?.tableAlertsToday?.cardHazardOnRoadCarStopped,
                        'alerts.count'
                      ) > 0 && redirectToLiveAlert('HAZARD_ON_ROAD_CAR_STOPPED')
                    }
                    urlImage="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Warning_icon.svg/2306px-Warning_icon.svg.png"
                    live
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CardCustom
                    title={validateTitle(
                      data?.tableAlertsToday?.cardHazardOnShoulderCarStopped,
                      'alerts.count'
                    )}
                    description="Veículo parado no acostamento"
                    labelProgressLeft={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday?.cardHazardOnShoulderCarStopped,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts7days
                          ?.cardHazardOnShoulderCarStopped7days,
                        'alerts_clustered.count_avg_7_days'
                      ),
                      label: '7 dias',
                      positionArrow: 'left'
                    })}
                    labelProgressRight={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday?.cardHazardOnShoulderCarStopped,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts30days
                          ?.cardHazardOnShoulderCarStopped30days,
                        'alerts_clustered.count_avg_30_days'
                      ),
                      label: '1 mês',
                      positionArrow: 'right'
                    })}
                    labelTime="Hoje"
                    onClickCustom={() =>
                      getValueInData(
                        data?.tableAlertsToday?.cardHazardOnShoulderCarStopped,
                        'alerts.count'
                      ) > 0 &&
                      redirectToLiveAlert('HAZARD_ON_SHOULDER_CAR_STOPPED')
                    }
                    urlImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5IeotK58JfEzu6ugJKvdyQipMHvEwuVwpRwmr-FMG10CCujfgXTMXtX3OIFnM7YVv3Lw&usqp=CAU"
                    live
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CardCustom
                    title={validateTitle(
                      data?.tableAlertsToday?.cardHazardOnRoadObject,
                      'alerts.count'
                    )}
                    description="Objeto na via"
                    labelProgressLeft={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday?.cardHazardOnRoadObject,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts7days?.cardHazardOnRoadObject7days,
                        'alerts_clustered.count_avg_7_days'
                      ),
                      label: '7 dias',
                      positionArrow: 'left'
                    })}
                    labelProgressRight={calculeProgressAlert({
                      currentValue: getValueInData(
                        data?.tableAlertsToday?.cardHazardOnRoadObject,
                        'alerts.count'
                      ),
                      oldValue: getValueInData(
                        data?.tableAlerts30days?.cardHazardOnRoadObject30days,
                        'alerts_clustered.count_avg_30_days'
                      ),
                      label: '1 mês',
                      positionArrow: 'right'
                    })}
                    labelTime="Hoje"
                    onClickCustom={() =>
                      getValueInData(
                        data?.tableAlertsToday?.cardHazardOnRoadObject,
                        'alerts.count'
                      ) > 0 && redirectToLiveAlert('HAZARD_ON_ROAD_OBJECT')
                    }
                    urlImage="https://cdn-icons-png.flaticon.com/512/765/765921.png"
                    live
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4} />
                <DataProvider sdk={lookerSDK}>
                  <Query slug="no0SLCDe2EgQ05kv2KuT3X">
                    <Visualization />
                  </Query>
                </DataProvider>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ borderRadius: '10px' }}>
                  <LiveBox
                    live={
                      !!data?.busiestTrafficScheduleToday &&
                      Object.keys(
                        data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                      )?.length > 0
                    }
                  >
                    <MDBox sx={{ width: '100%' }}>
                      {data?.busiestTrafficScheduleToday &&
                      Object.keys(
                        data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                      )?.length > 0 ? (
                        <MDTypography
                          variant="h6"
                          fontSize="1.3rem"
                          textAlign="center"
                          sx={{
                            borderBottom: '1px solid #e0e0e0'
                          }}
                        >
                          Horário com maior intensidade de tráfego{' '}
                          {[0, 6].includes(labelDayWeek[moment().isoWeekday()])
                            ? 'no'
                            : 'na'}{' '}
                          {labelDayWeek[moment().isoWeekday()]}
                        </MDTypography>
                      ) : (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          my={0.5}
                        >
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              borderRadius: '10px',
                              height: 28,
                              width: '80%'
                            }}
                          />
                        </MDBox>
                      )}
                      <Grid container display="flex">
                        <Grid item xs={12}>
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              borderBottom: '1px solid #e0e0e0'
                            }}
                          >
                            <MDBox width="30%">
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="body1"
                                  fontSize="1rem"
                                  ml={2}
                                >
                                  Horário de pico
                                </MDTypography>
                              ) : (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '80%'
                                    }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox width="25%">
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="h6"
                                  fontSize="1rem"
                                  textAlign="center"
                                >
                                  Hoje
                                </MDTypography>
                              ) : (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '80%'
                                    }}
                                  />
                                </MDBox>
                              )}
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="body1"
                                  fontSize="1rem"
                                  textAlign="center"
                                >
                                  {
                                    data?.busiestTrafficScheduleToday?.[
                                      'jams_today_15min_window.hour_rounded'
                                    ]
                                  }
                                </MDTypography>
                              ) : (
                                <MDBox
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '50%',
                                      mt: 0.5
                                    }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox width="25%">
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="h6"
                                  fontSize="1rem"
                                  textAlign="center"
                                >
                                  Últimos 90 dias
                                </MDTypography>
                              ) : (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '80%'
                                    }}
                                  />
                                </MDBox>
                              )}
                              {Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="body1"
                                  fontSize="1rem"
                                  textAlign="center"
                                >
                                  {
                                    data
                                      ?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek?.[
                                      'jams_comparative_90d_today.hour'
                                    ]
                                  }
                                </MDTypography>
                              ) : (
                                <MDBox
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '50%',
                                      mt: 0.5
                                    }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox
                              width="20%"
                              display="flex"
                              justifyContent="center"
                            >
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <CardMedia
                                  component="img"
                                  image={imgWazeClock}
                                  alt="Waze-clock"
                                  sx={{
                                    width: 35,
                                    height: 35,
                                    mt: 0,
                                    opacity: 0.35
                                  }}
                                />
                              ) : (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    animation="wave"
                                    variant="circular"
                                    sx={{ mr: 2, width: 35, height: 35 }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                          </MDBox>
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <MDBox width="30%">
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="body1"
                                  fontSize="1rem"
                                  ml={2}
                                >
                                  Extensão registrada
                                </MDTypography>
                              ) : (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '80%'
                                    }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox width="25%">
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="body1"
                                  fontSize="1rem"
                                  textAlign="center"
                                >
                                  {data?.busiestTrafficScheduleToday?.[
                                    'jams_today_15min_window.total_length_km'
                                  ]?.toFixed(2)}
                                  km
                                </MDTypography>
                              ) : (
                                <MDBox
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '50%'
                                    }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox width="25%">
                              {Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <MDTypography
                                  variant="body1"
                                  fontSize="1rem"
                                  textAlign="center"
                                >
                                  {data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek?.[
                                    'jams_comparative_90d_today.total_length_km_90'
                                  ]?.toFixed(2)}
                                  km
                                </MDTypography>
                              ) : (
                                <MDBox
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      height: 24,
                                      width: '50%'
                                    }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox
                              width="20%"
                              display="flex"
                              justifyContent="center"
                            >
                              {data?.busiestTrafficScheduleToday &&
                              Object.keys(
                                data?.tableBusiestTrafficTimeInTheLast90DaysOnTheSameDayOfTheCurrentWeek
                              )?.length > 0 ? (
                                <CardMedia
                                  component="img"
                                  image={imgWazeAngry}
                                  alt="Waze-clock"
                                  sx={{
                                    width: 35,
                                    height: 35,
                                    mt: 0.5,
                                    opacity: 0.35
                                  }}
                                />
                              ) : (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    animation="wave"
                                    variant="circular"
                                    sx={{ mr: 2, width: 35, height: 35 }}
                                  />
                                </MDBox>
                              )}
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </LiveBox>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <LiveBox live={!!data?.tableMostCongestedRoads?.value}>
                    <MDBox
                      sx={{
                        backgroundImage: `url("https://pngimg.com/uploads/road/road_PNG12.png")`,
                        backgroundPosition: `center bottom`,
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `96% 80%`,
                        transform: 'scaleX(-1)',
                        borderRadius: '10px',
                        width: '100%'
                      }}
                    >
                      <MDBox
                        sx={{
                          borderRadius: '10px',
                          transform: 'scaleX(-1)',
                          minHeight: '250px',
                          backgroundColor: '#ffffffcc',
                          border: '1px solid #efefef',
                          width: '100%',
                          boxShadow:
                            '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);'
                        }}
                      >
                        <TabContext value={tabJamSelected}>
                          <TabList onChange={handleTabJamChange}>
                            <Tab
                              label={
                                data?.tableMostCongestedRoads?.value ? (
                                  'Congestionamentos'
                                ) : (
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      borderRadius: '10px',
                                      height: 32,
                                      width: '100%'
                                    }}
                                  />
                                )
                              }
                            />
                            <Tab
                              label={
                                data?.tableMostCongestedRoads?.value ? (
                                  'Congestionamento incomum'
                                ) : (
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      borderRadius: '10px',
                                      height: 32,
                                      width: '100%'
                                    }}
                                  />
                                )
                              }
                            />
                            <Tab
                              label={
                                data?.tableMostCongestedRoads?.value ? (
                                  'Rotas monitoradas'
                                ) : (
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                      borderRadius: '10px',
                                      height: 32,
                                      width: '100%'
                                    }}
                                  />
                                )
                              }
                            />
                          </TabList>
                          <TabPanel value={0}>
                            {data?.tableMostCongestedRoads?.value ? (
                              <MDTypography
                                variant="h6"
                                fontSize="1.3rem"
                                textAlign="center"
                                sx={{
                                  borderTop: '1px solid #e0e0e0',
                                  borderBottom: '1px solid #e0e0e0',
                                  mt: -3
                                }}
                              >
                                Vias mais congestionadas agora
                              </MDTypography>
                            ) : (
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                mt={-2}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  sx={{
                                    borderRadius: '10px',
                                    height: 35,
                                    width: '50%'
                                  }}
                                />
                              </MDBox>
                            )}
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              mt={0.5}
                            >
                              <MDBox sx={{ width: '90%', px: 2 }}>
                                {data?.tableMostCongestedRoads?.value ? (
                                  data?.tableMostCongestedRoads?.value?.length >
                                  0 ? (
                                    <Grid container>
                                      {data?.tableMostCongestedRoads?.value?.map(
                                        (road, index) => (
                                          <Grid
                                            item
                                            xs={12}
                                            key={'jam-top-roads-' + index}
                                            onClick={() =>
                                              handleOpen(
                                                road['jam_top_roads.street']
                                              )
                                            }
                                            sx={{ cursor: 'pointer' }}
                                          >
                                            <MDBox
                                              sx={{
                                                backgroundColor:
                                                  colorsTraffic[
                                                    road['jam_top_roads.level']
                                                  ] + '10',
                                                borderRadius: '10px',
                                                width: '100%',
                                                m: 0.5
                                              }}
                                            >
                                              <MDTypography
                                                variant="h6"
                                                fontSize="1rem"
                                                textAlign="left"
                                                pt={0.5}
                                                ml={2}
                                                mb={-0.5}
                                                sx={{
                                                  color:
                                                    colorsTraffic[
                                                      road[
                                                        'jam_top_roads.level'
                                                      ]
                                                    ]
                                                }}
                                              >
                                                {road['jam_top_roads.street']}
                                              </MDTypography>
                                              <MDBox ml={2}>
                                                <MDTypography
                                                  variant="body1"
                                                  fontSize="0.9rem"
                                                  textAlign="left"
                                                  pb={0.5}
                                                  sx={{
                                                    color:
                                                      colorsTraffic[
                                                        road[
                                                          'jam_top_roads.level'
                                                        ]
                                                      ]
                                                  }}
                                                >
                                                  {road[
                                                    'jam_top_roads.delayMin'
                                                  ] + ' minutos de atraso'}
                                                  {'/ Extensão do congestionamento ' +
                                                    road[
                                                      'jam_top_roads.lengthKm'
                                                    ] +
                                                    'km'}
                                                  {'/ Velocidade média ' +
                                                    road[
                                                      'jam_top_roads.speedKmh'
                                                    ] +
                                                    'km/h'}
                                                </MDTypography>
                                              </MDBox>
                                            </MDBox>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  ) : (
                                    <MDTypography
                                      variant="body2"
                                      fontSize="1.3rem"
                                      textAlign="center"
                                      sx={{
                                        mt: 8
                                      }}
                                    >
                                      Não há congestionamento no momento!
                                    </MDTypography>
                                  )
                                ) : (
                                  [1, 2, 3, 4, 5, 6].map((idx) => (
                                    <MDBox
                                      key={'jam-top-roads-' + idx}
                                      ml={-2}
                                      mt={0.25}
                                      pb={1}
                                    >
                                      <Skeleton
                                        variant="rectangular"
                                        width="95%"
                                        height={50}
                                        animation="wave"
                                        sx={{
                                          borderRadius: '10px'
                                        }}
                                      />
                                    </MDBox>
                                  ))
                                )}
                              </MDBox>
                              {data?.tableMostCongestedRoads?.value?.length >
                                0 && (
                                <MDBox
                                  sx={{
                                    backgroundImage: `linear-gradient(
                                                                        ${
                                                                          colorsTraffic[
                                                                            data
                                                                              ?.tableMostCongestedRoads
                                                                              ?.value?.[0]?.[
                                                                              'jam_top_roads.level'
                                                                            ]
                                                                          ] +
                                                                          '30'
                                                                        },
                                                                        ${
                                                                          colorsTraffic[
                                                                            data
                                                                              ?.tableMostCongestedRoads
                                                                              ?.value?.[
                                                                              data
                                                                                ?.tableMostCongestedRoads
                                                                                ?.value
                                                                                ?.length -
                                                                                1
                                                                            ]?.[
                                                                              'jam_top_roads.level'
                                                                            ]
                                                                          ] +
                                                                          '30'
                                                                        }
                                                                    )`,
                                    width: '10%',
                                    borderTopLeftRadius: '1.5ch',
                                    borderBottomLeftRadius: '1.5ch',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    px: 4,
                                    pb: 1,
                                    mb: 1
                                  }}
                                >
                                  {data?.tableMostCongestedRoads?.value?.map(
                                    (road, index) => (
                                      <MDBox
                                        sx={{
                                          opacity: 0.7,
                                          mt: { xs: 3, md: 3, lg: 4, xl: 0.4 }
                                        }}
                                        key={'mdbox-road-table-' + index}
                                      >
                                        <CardMedia
                                          key={'img-road-table-' + index}
                                          alt="img-road-table"
                                          component="img"
                                          height="40"
                                          image={
                                            road['jam_top_roads.level'] === 4
                                              ? imgWazeAngry
                                              : road['jam_top_roads.level'] ===
                                                3
                                              ? imgWazeBoredom
                                              : road['jam_top_roads.level'] ===
                                                2
                                              ? imgWazeTired
                                              : road['jam_top_roads.level'] ===
                                                1
                                              ? imgWazeVeryFast
                                              : imgWazeFast
                                          }
                                        />
                                      </MDBox>
                                    )
                                  )}
                                </MDBox>
                              )}
                            </MDBox>
                          </TabPanel>

                          <TabPanel value={1}>
                            {data?.tableTrafficViewIrregularities ? (
                              <MDTypography
                                variant="h6"
                                fontSize="1.3rem"
                                textAlign="center"
                                sx={{
                                  borderTop: '1px solid #e0e0e0',
                                  borderBottom: '1px solid #e0e0e0',
                                  mt: -3
                                }}
                              >
                                Vias com congestionamento incomum
                              </MDTypography>
                            ) : (
                              <MDBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                mt={-2}
                              >
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  sx={{
                                    borderRadius: '10px',
                                    height: 35,
                                    width: '50%'
                                  }}
                                />
                              </MDBox>
                            )}
                            <MDBox width="100%">
                              {Object.keys(data?.tableTrafficViewIrregularities)
                                .length > 0 ? (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  mt={0.5}
                                >
                                  <MDBox sx={{ width: '90%', px: 2 }}>
                                    <Grid container>
                                      {data?.tableTrafficViewIrregularities ? (
                                        Object.keys(
                                          data?.tableTrafficViewIrregularities
                                        )?.map((key) =>
                                          key ? (
                                            <Grid
                                              item
                                              xs={12}
                                              onClick={() => {
                                                handleOpen(
                                                  data
                                                    ?.tableTrafficViewIrregularities?.[
                                                    key
                                                  ]?.[
                                                    'trafficview_irregularities.name'
                                                  ]
                                                )
                                              }}
                                              sx={{ cursor: 'pointer' }}
                                            >
                                              <MDBox
                                                sx={{
                                                  backgroundColor:
                                                    colorsTraffic[
                                                      data
                                                        ?.tableTrafficViewIrregularities?.[
                                                        key
                                                      ]?.[
                                                        'trafficview_irregularities.level'
                                                      ]
                                                    ] + '10',
                                                  borderRadius: '10px',
                                                  width: '100%',
                                                  m: 0.5
                                                }}
                                              >
                                                <MDBox
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  alignItems="center"
                                                >
                                                  <MDTypography
                                                    variant="h6"
                                                    fontSize="1rem"
                                                    pt={0.5}
                                                    ml={2}
                                                    mb={-0.5}
                                                    sx={{
                                                      color:
                                                        colorsTraffic[
                                                          data
                                                            ?.tableTrafficViewIrregularities?.[
                                                            key
                                                          ]?.[
                                                            'trafficview_irregularities.level'
                                                          ]
                                                        ]
                                                    }}
                                                  >
                                                    {
                                                      data
                                                        ?.tableTrafficViewIrregularities?.[
                                                        key
                                                      ]?.[
                                                        'trafficview_irregularities.name'
                                                      ]
                                                    }
                                                  </MDTypography>
                                                </MDBox>
                                                <MDBox ml={2}>
                                                  <MDTypography
                                                    variant="body1"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    pb={0.5}
                                                    sx={{
                                                      color:
                                                        colorsTraffic[
                                                          data
                                                            ?.tableTrafficViewIrregularities?.[
                                                            key
                                                          ]?.[
                                                            'trafficview_irregularities.level'
                                                          ]
                                                        ]
                                                    }}
                                                  >
                                                    {
                                                      <ConvertedDataTableTrafficViewIrregularities />
                                                    }
                                                  </MDTypography>
                                                </MDBox>
                                              </MDBox>
                                            </Grid>
                                          ) : (
                                            <MDBox
                                              display="flex"
                                              justifyContent="center"
                                              alignItems="center"
                                              mt={0.5}
                                            >
                                              <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                sx={{
                                                  borderRadius: '10px',
                                                  height: 35,
                                                  width: '50%'
                                                }}
                                              />
                                            </MDBox>
                                          )
                                        )
                                      ) : (
                                        <MDTypography
                                          variant="body2"
                                          fontSize="1.3rem"
                                          textAlign="center"
                                          sx={{
                                            mt: 8
                                          }}
                                        >
                                          Não há congestionamento no momento!
                                        </MDTypography>
                                      )}
                                    </Grid>
                                  </MDBox>
                                  {data?.tableTrafficViewIrregularities && (
                                    <MDBox
                                      sx={{
                                        backgroundImage: `linear-gradient(
                                                                                        ${
                                                                                          colorsTraffic[
                                                                                            data
                                                                                              ?.tableTrafficViewIrregularities?.[
                                                                                              Object.keys(
                                                                                                data?.tableTrafficViewIrregularities
                                                                                              )?.[0]
                                                                                            ]?.[
                                                                                              'trafficview_irregularities.level'
                                                                                            ]
                                                                                          ] +
                                                                                          '30'
                                                                                        },
                                                                                        ${
                                                                                          colorsTraffic[
                                                                                            data
                                                                                              ?.tableTrafficViewIrregularities?.[
                                                                                              Object.keys(
                                                                                                data?.tableTrafficViewIrregularities
                                                                                              )?.[
                                                                                                Object.keys(
                                                                                                  data?.tableTrafficViewIrregularities
                                                                                                )
                                                                                                  ?.length -
                                                                                                  1
                                                                                              ]
                                                                                            ]?.[
                                                                                              'trafficview_irregularities.level'
                                                                                            ]
                                                                                          ] +
                                                                                          '30'
                                                                                        }
                                                                                    )`,
                                        width: '10%',
                                        borderTopLeftRadius: '1.5ch',
                                        borderBottomLeftRadius: '1.5ch',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        px: 4,
                                        pb: 1,
                                        mb: 1
                                      }}
                                    >
                                      {Object.keys(
                                        data?.tableTrafficViewIrregularities
                                      )?.map((key, index) => (
                                        <MDBox
                                          sx={{
                                            opacity: 0.7,
                                            mt: { xs: 3, md: 3, lg: 4, xl: 0.4 }
                                          }}
                                          key={'mdbox-road-table-' + index}
                                        >
                                          <CardMedia
                                            alt="img-road-table"
                                            component="img"
                                            height="40"
                                            key={'mdbox-road-table-' + index}
                                            image={
                                              data
                                                ?.tableTrafficViewIrregularities?.[
                                                key
                                              ]?.[
                                                'trafficview_irregularities.level'
                                              ] === 4
                                                ? imgWazeAngry
                                                : data
                                                    ?.tableTrafficViewIrregularities?.[
                                                    key
                                                  ]?.[
                                                    'trafficview_irregularities.level'
                                                  ] === 3
                                                ? imgWazeBoredom
                                                : data
                                                    ?.tableTrafficViewIrregularities?.[
                                                    key
                                                  ]?.[
                                                    'trafficview_irregularities.level'
                                                  ] === 2
                                                ? imgWazeTired
                                                : data
                                                    ?.tableTrafficViewIrregularities?.[
                                                    key
                                                  ]?.[
                                                    'trafficview_irregularities.level'
                                                  ] === 1
                                                ? imgWazeVeryFast
                                                : imgWazeVeryFast
                                            }
                                          />
                                        </MDBox>
                                      ))}
                                    </MDBox>
                                  )}
                                </MDBox>
                              ) : (
                                [1, 2, 3, 4, 5, 6].map((idx) => (
                                  <MDBox
                                    key={
                                      'trafficview_irregularities.name' + idx
                                    }
                                    ml={-2}
                                    mt={0.5}
                                    pb={1}
                                  >
                                    <Skeleton
                                      variant="rectangular"
                                      width="95%"
                                      height={50}
                                      animation="wave"
                                      sx={{
                                        borderRadius: '10px'
                                      }}
                                    />
                                  </MDBox>
                                ))
                              )}
                            </MDBox>
                          </TabPanel>
                          <TabPanel value={2}>
                            <MDTypography
                              variant="h6"
                              fontSize="1.3rem"
                              textAlign="center"
                              sx={{
                                borderTop: '1px solid #e0e0e0',
                                borderBottom: '1px solid #e0e0e0',
                                mt: -3
                              }}
                            >
                              Rotas monitoradas - Waze Partner Hub
                            </MDTypography>
                            <MDBox width="100%">
                              {Object.keys(data?.tableRoadsMonitoredRoutes)
                                .length > 0 ? (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  mt={0.5}
                                >
                                  <MDBox sx={{ width: '90%', px: 2 }}>
                                    <Grid container>
                                      {data?.tableRoadsMonitoredRoutes ? (
                                        <>
                                          {' '}
                                          {Object.keys(
                                            data?.tableRoadsMonitoredRoutes
                                          )?.map((key) => (
                                            <Grid
                                              item
                                              xs={12}
                                              onClick={() =>
                                                handleOpen(
                                                  data
                                                    ?.tableRoadsMonitoredRoutes?.[
                                                    key
                                                  ]?.['medium_point']
                                                )
                                              }
                                              sx={{ cursor: 'pointer' }}
                                            >
                                              <MDBox
                                                sx={{
                                                  backgroundColor:
                                                    colorsTraffic[
                                                      data
                                                        ?.tableRoadsMonitoredRoutes?.[
                                                        key
                                                      ]?.[
                                                        'waze_monitoredroutes_now.jam_level'
                                                      ]
                                                    ] + '10',
                                                  borderRadius: '10px',
                                                  width: '100%',
                                                  m: 0.5
                                                }}
                                              >
                                                <MDBox
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  alignItems="center"
                                                >
                                                  <MDTypography
                                                    variant="h6"
                                                    fontSize="1rem"
                                                    pt={0.5}
                                                    ml={2}
                                                    mb={-0.5}
                                                    sx={{
                                                      color:
                                                        colorsTraffic[
                                                          data
                                                            ?.tableRoadsMonitoredRoutes?.[
                                                            key
                                                          ]?.[
                                                            'waze_monitoredroutes_now.jam_level'
                                                          ]
                                                        ]
                                                    }}
                                                  >
                                                    {
                                                      data
                                                        ?.tableRoadsMonitoredRoutes?.[
                                                        key
                                                      ]?.[
                                                        'waze_monitoredroutes_now.name'
                                                      ]
                                                    }
                                                  </MDTypography>
                                                </MDBox>
                                                <MDBox ml={2}>
                                                  <MDTypography
                                                    variant="body1"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    pb={0.5}
                                                    sx={{
                                                      color:
                                                        colorsTraffic[
                                                          data
                                                            ?.tableRoadsMonitoredRoutes?.[
                                                            key
                                                          ]?.[
                                                            'waze_monitoredroutes_now.jam_level'
                                                          ]
                                                        ]
                                                    }}
                                                  >
                                                    {`Percurso de ${
                                                      data
                                                        ?.tableRoadsMonitoredRoutes?.[
                                                        key
                                                      ]?.[
                                                        'waze_monitoredroutes_now.length_km'
                                                      ]
                                                    } km de extensão  -
                                                                                            Fluxo ${
                                                                                              labelTraffic[
                                                                                                data
                                                                                                  ?.tableRoadsMonitoredRoutes?.[
                                                                                                  key
                                                                                                ]?.[
                                                                                                  'waze_monitoredroutes_now.jam_level'
                                                                                                ]
                                                                                              ]
                                                                                            } - 
                                                                                            Velocidade agora de ${
                                                                                              data
                                                                                                ?.tableRoadsMonitoredRoutes?.[
                                                                                                key
                                                                                              ]?.[
                                                                                                'waze_monitoredroutes_now.speed_KMH'
                                                                                              ]
                                                                                            } km/h, 
                                                                                            velocidade histórica ${
                                                                                              data
                                                                                                ?.tableRoadsMonitoredRoutes?.[
                                                                                                key
                                                                                              ]?.[
                                                                                                'waze_monitoredroutes_now.regular_speed_KMH'
                                                                                              ]
                                                                                            } km/h -
                                                                                             Tempo de percurso agora ${
                                                                                               data
                                                                                                 ?.tableRoadsMonitoredRoutes?.[
                                                                                                 key
                                                                                               ]?.[
                                                                                                 'waze_monitoredroutes_now.time'
                                                                                               ]
                                                                                             } min(s) e 
                                                                                             tempo de percurso histórico ${
                                                                                               data
                                                                                                 ?.tableRoadsMonitoredRoutes?.[
                                                                                                 key
                                                                                               ]?.[
                                                                                                 'waze_monitoredroutes_now.historic_time'
                                                                                               ]
                                                                                             } min(s)`}
                                                  </MDTypography>
                                                </MDBox>
                                              </MDBox>
                                            </Grid>
                                          ))}
                                        </>
                                      ) : (
                                        <>
                                          <MDTypography
                                            variant="body2"
                                            fontSize="1.3rem"
                                            textAlign="center"
                                            sx={{
                                              mt: 8
                                            }}
                                          >
                                            Não há registros!
                                          </MDTypography>
                                        </>
                                      )}
                                    </Grid>
                                  </MDBox>
                                  {data?.tableRoadsMonitoredRoutes && (
                                    <MDBox
                                      sx={{
                                        backgroundImage: `linear-gradient(
                                                                                        ${
                                                                                          colorsTraffic[
                                                                                            data
                                                                                              ?.tableRoadsMonitoredRoutes?.[
                                                                                              Object.keys(
                                                                                                data?.tableRoadsMonitoredRoutes
                                                                                              )?.[0]
                                                                                            ]?.[
                                                                                              'waze_monitoredroutes_now.jam_level'
                                                                                            ]
                                                                                          ] +
                                                                                          '30'
                                                                                        },
                                                                                        ${
                                                                                          colorsTraffic[
                                                                                            data
                                                                                              ?.tableRoadsMonitoredRoutes?.[
                                                                                              Object.keys(
                                                                                                data?.tableRoadsMonitoredRoutes
                                                                                              )?.[
                                                                                                Object.keys(
                                                                                                  data?.tableRoadsMonitoredRoutes
                                                                                                )
                                                                                                  ?.length -
                                                                                                  1
                                                                                              ]
                                                                                            ]?.[
                                                                                              'waze_monitoredroutes_now.jam_level'
                                                                                            ]
                                                                                          ] +
                                                                                          '30'
                                                                                        }
                                                                                    )`,
                                        width: '10%',
                                        borderTopLeftRadius: '1.5ch',
                                        borderBottomLeftRadius: '1.5ch',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        px: 4,
                                        pb: 1,
                                        mb: 1
                                      }}
                                    >
                                      {Object.keys(
                                        data?.tableRoadsMonitoredRoutes
                                      )?.map((key, index) => (
                                        <MDBox
                                          sx={{
                                            opacity: 0.7,
                                            mt: { xs: 3, md: 3, lg: 4, xl: 0.4 }
                                          }}
                                          key={'mdbox-road-table-' + index}
                                        >
                                          <CardMedia
                                            key={'img-road-table-' + index}
                                            alt="img-road-table"
                                            component="img"
                                            height="40"
                                            image={
                                              data?.tableRoadsMonitoredRoutes?.[
                                                key
                                              ]?.[
                                                'waze_monitoredroutes_now.jam_level'
                                              ] === 4
                                                ? imgWazeAngry
                                                : data
                                                    ?.tableRoadsMonitoredRoutes?.[
                                                    key
                                                  ]?.[
                                                    'waze_monitoredroutes_now.jam_level'
                                                  ] === 3
                                                ? imgWazeBoredom
                                                : data
                                                    ?.tableRoadsMonitoredRoutes?.[
                                                    key
                                                  ]?.[
                                                    'waze_monitoredroutes_now.jam_level'
                                                  ] === 2
                                                ? imgWazeTired
                                                : data
                                                    ?.tableRoadsMonitoredRoutes?.[
                                                    key
                                                  ]?.[
                                                    'waze_monitoredroutes_now.jam_level'
                                                  ] === 1
                                                ? imgWazeVeryFast
                                                : imgWazeVeryFast
                                            }
                                          />
                                        </MDBox>
                                      ))}
                                    </MDBox>
                                  )}
                                </MDBox>
                              ) : (
                                [1, 2, 3, 4, 5, 6].map((idx) => (
                                  <MDBox
                                    key={'jam-top-monitored-roads-' + idx}
                                    ml={-2}
                                    mt={0.5}
                                    pb={1}
                                  >
                                    <Skeleton
                                      variant="rectangular"
                                      width="95%"
                                      height={50}
                                      animation="wave"
                                      sx={{
                                        borderRadius: '10px'
                                      }}
                                    />
                                  </MDBox>
                                ))
                              )}
                            </MDBox>
                          </TabPanel>
                        </TabContext>
                      </MDBox>
                    </MDBox>
                  </LiveBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox my={2}>
          <iframe
            id="iframe26"
            width="100%"
            title="Histórico de Alertas"
            src={`https://scsbi.cloud.looker.com/embed/looks/26?allow_login_screen=true`}
            frameBorder="0"
            allowFullScreen
            style={{ height: '29ch', borderRadius: '10px' }}
          />
        </MDBox>
      </MDBox>

      <WazeModalMap
        open_modal={open}
        close_modal={handleClose}
        data={wazeModalStore.data}
      />
    </>
  )
})

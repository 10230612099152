// Libs
import React from 'react'
import { observer } from 'mobx-react'

// Project
import { BoxContent } from 'components'

export const InfosAccidentToday = observer(() => {
  return (
    <BoxContent hasIframe>
      <iframe
        width="100%"
        title='Histórico de Alertas'
        src={`https://scsbi.cloud.looker.com/embed/dashboards/28?allow_login_screen=true`}
        frameBorder="0"
        allowFullScreen
        style={{ height: '95ch' }}
      />
    </BoxContent>
  )
})

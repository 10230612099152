// Libs
import { observer } from 'mobx-react'
import { Modal, Card } from '@mui/material'
// Template
import { MDBox } from '@cloudmatize/cm-core-template/layout/components'

// Project
import { WazePlotStreet } from 'pages/waze'
import { WazePlotLocation } from 'pages/waze/WazePlotLocation'

export const WazeModalMap = observer(({ open_modal, close_modal, data }) => {
  const location_str = Object.values(data.location).join(',')

  return (
    <Modal
      open={open_modal}
      onClose={close_modal}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card sx={{ width: '70%', height: '80%', p: 1, mb: 5, mt: 2 }}>
        <MDBox
          style={{
            height: '100%',
            width: '100%',
            border: '1px solid #f0f0f0',
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow:
              '0 5px 10px rgba(154,160,185,.06), 0 10px 20px rgba(166,173,201,.3);'
          }}
          id="waze_map"
        >
          {data.name ? (
            <WazePlotStreet street_name={data.name} />
          ) : (
            <WazePlotLocation location={location_str} />
          )}
        </MDBox>
      </Card>
    </Modal>
  )
})

// Libs
import React from 'react'
import Tabela from 'pages/maps/MapsComponents/Table/Table'
import './styles/monitoredStyles.css'

// Project
import { BoxContent } from 'components'

export const MonitoredAreas = () => {
  return (
    <BoxContent title="Áreas monitoradas">
      <Tabela />
    </BoxContent>
  )
}

// Libs
import { useState, useCallback, useEffect } from 'react'
import { polygon, explode, area, bbox, centroid } from '@turf/turf'
import 'mapbox-gl/dist/mapbox-gl.css'
import Map from 'react-map-gl'

// Template
import { MDBox } from '@cloudmatize/cm-core-template/layout/components'

// Project
import { areaStore } from 'pages/maps/stores/areaStore.js'
import { monitoredEventsStore } from 'pages/maps/stores/index.js'
import DrawControl from './draw-control.ts'
import './Map.css'

export default function Mapa({ draw_type }) {
  const [, setFeatures] = useState({})
  const [polygonCoordinates, setPolygonCoordinates] = useState([])

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        newFeatures[f.id] = f

        if (f.geometry.type === 'Polygon') {
          // Criando um poligono do turf
          const poly = polygon(f.geometry.coordinates)
          // Cuida das operações relacionadas ao poligono
          polygonOperations(poly)
        }
      }
      return newFeatures
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDelete = useCallback((e) => {
    // Cleaning the geometric field and area of the map
    areaStore.maps.geometric_field = ''
    areaStore.maps.area = null
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        delete newFeatures[f.id]
      }
      return newFeatures
    })
  }, [])

  const polygonOperations = (targetPoly) => {
    //Serve para abrir e pegar os pontos do poligono
    const poly_coordinates = explode(targetPoly)
    // Calcula a área do poligono
    const area_poly = area(targetPoly)
    const centerCoords = centroid(targetPoly).geometry.coordinates
    const polygonBbox = bbox(targetPoly)

    monitoredEventsStore.maps_infos.box_nav = polygonBbox
    monitoredEventsStore.maps_infos.centroid = centerCoords

    // Enviando a informação da área do poligono
    areaStore.maps.area = area_poly

    // Acessando os dados

    const allPolyPoints = []

    poly_coordinates.features.forEach((point, index) => {
      const [longitude, latitude] = point.geometry.coordinates
      allPolyPoints.push({ latitude, longitude })
    })

    // Poligono recebe todos os pontos
    setPolygonCoordinates(allPolyPoints)
  }

  // Envia as coordenadas para a register area
  useEffect(() => {
    areaStore.maps.geometric_field = JSON.stringify(polygonCoordinates)
  }, [polygonCoordinates])

  return (
    <>
      <MDBox
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <Map
          //id="map-container"
          mapboxAccessToken={process.env.REACT_APP_TOKEN_MAP_BOX}
          initialViewState={{
            longitude: -46.5489,
            latitude: -23.6226,
            zoom: 14,
            minZoom: 2,
            maxZoom: 14
          }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          style={{ width: '100%', height: '100%' }}
        >
          {draw_type === 'draw_area' && (
            <DrawControl
              position="top-left"
              displayControlsDefault={false}
              controls={{
                polygon: true,
                trash: true
              }}
              defaultMode="draw_polygon"
              onCreate={onUpdate}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          )}
          {draw_type === 'draw_routes' && (
            <DrawControl
              position="top-left"
              displayControlsDefault={false}
              controls={{
                line_string: true
              }}
              onCreate={onUpdate}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          )}
        </Map>
      </MDBox>
    </>
  )
}

// Libs
import React from 'react'
import { observer } from 'mobx-react'

// Template
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import ListItemIcon from '@mui/material/ListItemIcon'
import {
  CarCrash as CarCrashIcon,
  Cloud as CloudIcon,
  ReportProblem as ReportProblemIcon,
  Warning as WarningIcon,
  Traffic as TrafficIcon,
  DirectionsCar as CarIcon,
  Build as BuildIcon,
  Visibility as VisibilityIcon,
  Flag as FlagIcon,
  AcUnit as SnowIcon,
  Water as WaterIcon,
  Pets as PetsIcon
} from '@mui/icons-material'

export const EventsComplete = observer(
  ({
    selectOptions,
    isSubEvent,
    handleChangeEvent,
    isRead,
    labelText,
    placeholderText,
    defaultSelected
  }) => {
    const eventIcons = {
      'Acidentes de veículos': <CarCrashIcon />,
      Congestionamento: <TrafficIcon />,
      'Ordem Climática': <CloudIcon />,
      'Perigos na via': <ReportProblemIcon />
    }

    const subEventIcons = {
      'Acidentes grave': <CarCrashIcon />,
      'Acidentes leve': <CarCrashIcon />,
      'Congestionamento leve': <TrafficIcon />,
      Moderado: <TrafficIcon />,
      Intenso: <TrafficIcon />,
      Chuva: <CloudIcon />,
      Alagamento: <WaterIcon />,
      Neblina: <VisibilityIcon />,
      Granizo: <SnowIcon />,
      'Carro Parado': <CarIcon />,
      'Objetos na pista': <FlagIcon />,
      'Buracos na pista': <BuildIcon />,
      'Sem sinalização': <FlagIcon />,
      'Construção na pista': <BuildIcon />,
      Óleo: <WarningIcon />,
      'Animais na pista': <PetsIcon />
    }

    return (
      <Stack spacing={3} sx={{ width: '100%' }}>
        <Autocomplete
          multiple
          readOnly={isRead}
          id="tags-standard"
          options={selectOptions}
          getOptionLabel={(option) => option.name}
          defaultValue={defaultSelected}
          onChange={(event, value, reason) => {
            handleChangeEvent(value.map((option) => option.id))
          }}
          noOptionsText="Nenhuma opção disponível"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={labelText}
              placeholder={placeholderText}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {isSubEvent ? (
                <>
                  <ListItemIcon>{subEventIcons[option.name]}</ListItemIcon>
                  {option.name}
                </>
              ) : (
                <>
                  <ListItemIcon>{eventIcons[option.name]}</ListItemIcon>
                  {option.name}
                </>
              )}
            </li>
          )}
        />
      </Stack>
    )
  }
)

// Libs
import { action, observable } from 'mobx'

const lookerStore = observable({
    dataSdk: null,
    currentPage: null,
    lastTimeUpdate: null,
    getLastTimeUpdate: action(function () {
        const date = new Date()
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const seconds = date.getSeconds().toString().padStart(2, '0')
        this.lastTimeUpdate = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
    }),
})

export { lookerStore }
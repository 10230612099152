// Libs
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { Chip, IconButton, Tooltip } from '@mui/material'

// Icons
import { Delete } from '@mui/icons-material'

// Template
import { DataTable } from '@cloudmatize/cm-core-template/layout/demos'
import {
  MDBox,
  MDTypography
} from '@cloudmatize/cm-core-template/layout/components'

// Project
import { mapsMonitoredEventStore } from 'pages/maps/stores'
import { mapsMonitoredStore } from 'pages/maps/stores'
import { useParams } from 'react-router-dom'

const EventTable = observer(({ areadId }) => {
  const { area_id } = useParams()
  // Function related with event delete
  const handleEventDelete = (EventId) => {
    if (EventId) {
      mapsMonitoredStore.delete(EventId)
    }


    // Antes de deletar mandar um alert para o usuário para que ele confirme a operação

    //monitoredEventsStore.delete()
  }

  // Do reload every time that has a change with table
  useEffect(() => {
    mapsMonitoredStore.reload()
  }, [])

  return (
    <MDBox sx={{ mx: { xs: 1, md: 3, lg: 2 } }}>
      <MDBox>
        <MDBox display="flex" justifyContent="space-between" mb={2}>
          <MDTypography
            variant="body1"
            fontSize="1.5rem"
            textTransform="uppercase"
            fontWeight="bold"
          >
            monitoramentos vinculados
          </MDTypography>
        </MDBox>
        <DataTable
          dictPag={mapsMonitoredStore.list}
          funcReload={mapsMonitoredStore.reload}
          canSearch={true}
          table={{
            columns: [
              {
                Header: 'Monitoramento',
                accessor: 'nameAlert',
                width: '20%'
              },
              {
                Header: 'Evento',
                accessor: 'event',
                width: '20%'
              },
              {
                Header: 'Subeventos',
                accessor: 'subEvents',
                width: '20%'
              },
              {
                Header: 'Meio comunicação',
                accessor: 'trigger',
                width: '10%'
              },
              { Header: 'Ações', accessor: 'action', width: '10%' }
            ],
            rows: mapsMonitoredStore.list?.results?.filter((event) => event?.map?.id === area_id)?.map((event, index) => {
              let nameAlert = event?.name
              let events = []
              let subEvents = []
              console.log(event)
              event?.sub_events?.map(
                (areaInt, index) => {
                  console.log(areaInt)
                  // Getting the principal events returns
                  if (!events.includes(areaInt?.sub_event?.event?.name)) {
                    events.push(areaInt?.sub_event?.event?.name)
                  }
                  if (!subEvents.includes(areaInt?.sub_event?.name)) {
                    subEvents.push(areaInt?.sub_event?.name)
                  }
                })
              return {
                key: index,
                nameAlert: nameAlert,
                event: events.map((event) => (<Chip sx={{ marginRight: 1 }} key={event} label={event} />)),
                subEvents: subEvents.map((sub_event) => (<Chip sx={{ marginRight: 1 }} key={sub_event} label={sub_event} />)),
                action: (
                  <MDBox key={index} sx={{ gap: 2 }}>
                    <Tooltip title="Excluir métrica">
                      <IconButton
                        onClick={() => {
                          handleEventDelete(event?.id)
                        }}
                      >
                        <Delete
                          fontSize="small"
                          sx={{ opacity: 0.7, mr: 1 }}
                          color="primary"
                        />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                )
              }
            })
          }}
        />
      </MDBox>
    </MDBox>
  )
})

export default EventTable

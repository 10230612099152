// Libs
import { Rest } from 'application/rest'
import { observable, action } from 'mobx'

const doCreate = async (data) => {
  const rest = new Rest('maps')
  rest.api = 'auth/api'
  const response = await rest.post(data)
  return await response.json()
}
const doUpdate = async (id, data) => {
  const rest = new Rest('maps')
  rest.api = 'auth/api'
  rest.detail = id
  const response = await rest.put(data)
  return await response.json()
}
const doGet = async (id) => {
  const rest = new Rest(`maps/${id}`)
  rest.api = 'auth/api'
  const response = await rest.get()
  return await response.json()
}

const doList = async (page = 1, page_size, filter = null) => {
  const rest = new Rest('maps')
  rest.api = 'auth/api'
  rest.query = {
    page,
    page_size
  }
  if (filter) {
    rest.query['filter'] = filter
  }
  const response = await rest.list()
  return await response.json()
}

const doDelete = async (id) => {
  const rest = new Rest(`maps/${id}`)
  rest.api = 'auth/api'
  const response = await rest.delete()
  return await response.json()
}

export const areaStore = observable({
  _id: null,
  loading: false,
  maps: {
    name: '',
    description: '',
    area: null,
    geometric_field: '',
    last_edited_user: ''
  },
  list: {
    num_pages: null,
    page: null,
    page_size: null,
    total_size: null,
    results: [],
    filter: null,
    loading: false
  },
  set id(id) {
    this.loading = true
    if (id) this._id = id
    const that = this
    doGet(id)
      .then((response) => {
        that.maps = response
      })
      .catch((error) => {
        console.error(error)
        that.message = {
          content:
            error.detail?.toString() || 'Houve um erro ao carregar o poligono',
          severity: 'error'
        }
      })
      .finally(() => {
        that.loading = false
      })
  },
  get id() {
    return this._id
  },
  reset: action(function () {
    this.maps = {
      name: '',
      description: '',
      area: null,
      geometric_field: [],
      last_edited_user: '',
      id: null
    }
    this._id = null
    this.loading = false
    this.message = null
    this.error = null
  }),
  reload: action(async function (page = 1) {
    let that = areaStore
    try {
      that.list.loading = true
      that.list = await doList(page)
    } catch (error) {
      console.error(error)
    } finally {
      that.list.loading = false
    }
  }),
  save: action(async function (area_id = null) {
    this.loading = true
    const create = this.id === null
    let errorMessage = create ? 'Houve algum erro ao registrar área monitorada' : 'Houve algum erro ao atualizar área monitorada'
    this.maps.geometric_field = this.maps.geometric_field.length > 0 ? JSON.stringify(this.maps.geometric_field) : null
    try {
      let response
      if (this.id) {
        response = await doUpdate(this.id, this.maps)
      } else {
        response = await doCreate(this.maps)
        this.id = response.id
      }
      this.maps = response
      this.message = {
        content: create
          ? 'Área registrada com sucesso'
          : 'Área atualizada com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content: error.detail ? error.detail : errorMessage,
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  }),
  getAreaDetails: action(async function (idArea) {
    try {
      const response = await doGet(idArea)

      return response
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content: error.detail?.toString() // create
          ? 'Houve algum erro ao recuperar informações da área'
          : 'Houve algum erro ao atualizar área monitorada',
        severity: 'error'
      }
    }
  }),
  delete: action(async function (area_id = null) {
    this.loading = true
    try {
      await doDelete(area_id || this.id)
      this.message = {
        content: 'Área excluída com sucesso',
        severity: 'success'
      }
      this.error = null
      return true
    } catch (error) {
      console.error(error)
      this.error = error
      this.message = {
        content:
          error.detail?.toString() || 'Houve algum erro ao excluir a área',
        severity: 'error'
      }
      return false
    } finally {
      this.loading = false
    }
  })
})
